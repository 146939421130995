import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import project1 from "../../assets/images/project1.png";
function Singleproject({ data, whitetext, project }) {
  return (
    <Wrapper whitetext={whitetext}>
      <Link
        aria-label={`Read more about ${data.name}`}
        target="_blank"
        rel="noopener noreferrer"
        to={`/projectdetail/${project}/${data.id}/${data.slug}`}
      >
        <div
          style={{
            backgroundImage: `url(${data.cover})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="img"
        >
          {/* <img alt={`${data.name}`} src={data.cover} style={{width:"100%",height:"100%"}} /> */}
          <div className="layer">
            <button>
              <span>Discovery</span>

              <svg
                width="14"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.457 0.991667C14.531 0.991886 14.6043 1.00514 14.6737 1.03083L14.692 1.0375C14.8069 1.08483 14.9052 1.16506 14.9746 1.26811C15.044 1.37116 15.0814 1.49243 15.082 1.61667V14.1167C15.082 14.2824 15.0162 14.4414 14.899 14.5586C14.7818 14.6758 14.6228 14.7417 14.457 14.7417C14.2913 14.7417 14.1323 14.6758 14.0151 14.5586C13.8979 14.4414 13.832 14.2824 13.832 14.1167V3.125L1.64037 15.3167C1.52287 15.435 1.36537 15.5 1.1987 15.5C1.03203 15.5 0.874531 15.435 0.757031 15.3167C0.638698 15.1992 0.573698 15.0417 0.573698 14.875C0.573698 14.7083 0.638698 14.5508 0.757031 14.4333L12.9479 2.24167H1.95703C1.79127 2.24167 1.6323 2.17582 1.51509 2.05861C1.39788 1.9414 1.33203 1.78243 1.33203 1.61667C1.33203 1.45091 1.39788 1.29193 1.51509 1.17472C1.6323 1.05752 1.79127 0.991667 1.95703 0.991667H14.457Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="info">
          <div className="feature">
            {data.categories.map((item, index) => {
              return <p key={index}>{item.name}</p>;
            })}
          </div>
          <h3>{data.name}</h3>
        </div>
      </Link>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 200px;

  & > a {
    width: 100%;
  }
  .img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    position: relative;
    img {
      border-radius: 10px;
    }
    &:hover .layer {
      display: flex;
    }
    .layer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: none;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      button {
        cursor: pointer;
        display: flex;
        align-items: center;
        /* justify-content: center; */
        padding: 8px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 16px;
        color: white;
        gap: 6px;
        font-family: SF-Pro-Display-Medium;
      }
    }
  }
  .info {
    margin-top: 16px;
    .feature {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      margin-bottom: 12px;

      p {
        border: 0.7px solid #c6c6c6;
        padding: 4px;
        font-size: 12px;
        border-radius: 6px;
        margin-bottom: 2px;
        color: ${(props) => {
          if (props.whitetext) {
            return "#C6C6C6";
          } else {
            return "black";
          }
        }};
      }
    }
    h3 {
      font-size: 22px;
      color: black;
      color: ${(props) => {
        if (props.whitetext) {
          return "white";
        } else {
          return "black";
        }
      }};
      font-family: SF-Pro-Display-Bold;
    }
  }

  @media screen and (max-width: 796px) {
    /* margin-top: 30px;
grid-gap: 15px; */
    .info {
      .feature {
        p {
          font-size: 11px;
        }
      }
      h3 {
        font-size: 20px;
      }
    }
  }
  @media screen and (max-width: 596px) {
    /* margin-top: 30px;
grid-gap: 15px; */
    margin-top: 30px;
    .img {
      /* height: auto; */
    }
    .info {
      .feature {
        p {
          font-size: 11px;
        }
      }
      h3 {
        font-size: 20px;
      }
    }
  }
  @media screen and (max-width: 496px) {
    /* margin-top: 30px;
grid-gap: 15px; */
    .info {
      .feature {
        margin-bottom: 10px;
        p {
          font-size: 10px;
        }
      }
      h3 {
        font-size: 18px;
      }
    }
  }
`;

export default Singleproject;
