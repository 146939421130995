import React, { useEffect } from 'react'
import styled from 'styled-components'
import Body from '../components/Gokuproductionservice/Body';
import Intro from '../components/Gokuproductionservice/Intro';
import Nav from '../components/Share/Nav';
import { useTumysContext } from '../context/tumycontext';

function Gokuverseproduction() {

  const {english, nav,  } = useTumysContext();


  useEffect(() => {
    window.scrollTo(0, 0);
   
    
  }, [english])
  return (


<>
{nav?(<Nav />):(
 

 <>
 
 <Intro />
 <Body />
 </>

)}

</>
  
  )
}
const Wrapper = styled.div`

`

export default Gokuverseproduction