import React, { useContext, useEffect, useReducer, useState } from "react";
import reducer from "../reducers/tumyreducer";
import axios from "axios";
import customFetch from "../utils/axios";
import {} from "../utils/actions";
import emailjs from "emailjs-com";
import validator from "validator";

const initialState = {
  guide: false,
  currentscene: null,
  english: false,
  nav: false,
  // Gokuverse
  VerseProject: {
    filterList: [
      "Tất cả",
      "Opening Tour",
      "Home Tour",
      "Pro Location",
      "VR MasterPlan",
      "VR Walking",
      "FloorPlan",
      "VR Model",
      "Plan",
      "Tour 360",
    ],
    dataProject: [],
    currentArray: [],
    currentFilter: "Tất cả",
    isLoading: false,
    total: 0,
    perPage: 9,
    currentPage: 1,
    filterAPI: [],
    searchValue: "",
    searchLoading: false,
  },

  // Gokuproduction
  ProductionProject: {
    filterList: ["Tất cả", "CGI", "Tiến độ", "Dự án", "Cityscape", "Nhà mẫu"],

    dataProject: [],
    currentArray: [],
    currentFilter: "Tất cả",
    total: 0,
    perPage: 9,
    currentPage: 1,
    isLoading: false,
    filterAPI: [],
    searchValue: "",
    searchLoading: false,
  },

  SingleProject: {
    data: {},
    otherProject: [],
    isLoading: false,
  },

  HomeProject: {
    data1: [],
    data2: [],
    isLoading: false,
  },

  //  Blog
  currentpageBlog: [],
  perPage: 10,
  currentCategoryId: 1,
  isBlogLoading: false,
  currentNumberBlog: 1,
  isSingleBlogLoading: false,
  currentSingleBLog: {},
  totalBlogs: 1,
  moreBlog: [],

  //Contact
  contact: {
    name: "",
    email: "",
    message: "",
    isError: false,
    textError: "",
    success: false,
    textSuccess: "",
  },

  //Meta
  thumb: "",
  title: "",
  description: "",
  url: "",
};

const TumysContext = React.createContext();

export const TumysProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  //Meta
  const updateMeta = (title, description, thumb, url) => {
    dispatch({
      type: "UPDATE_META_TAG",
      payload: { title, description, thumb, url },
    });
  };
  //HOme page

  const fetchProjectHome = async (language, limit) => {
    dispatch({ type: "GET_PROJECT_HOME_START" });
    try {
      const res = await customFetch(`/project/product/home/${limit}`, {
        headers: {
          language: `${language}`,
        },
      });
      const data = res.data.products;
      // console.log(data)

      dispatch({ type: "GET_PROJECT_HOME_SUCCESS", payload: { data } });
    } catch {
      dispatch({ type: "GET_PROJECT_HOME_ERROR" });
    }
  };

  //  Blog
  const fetchBlog = async (language, category_id, limit, page) => {
    dispatch({ type: "GET_BLOG_START" });

    try {
      const res = await customFetch(
        `/blog/article_by_category/${category_id}/${limit}?page=${page}`,
        {
          headers: {
            language: `${language}`,
          },
        }
      );

      const blogs = res.data.articles;
      const total = res.data.total;
      const perPage = res.data.per_page;
      // console.log(res.data.articles)

      dispatch({
        type: "GET_BLOG_SUCCESS",
        payload: { blogs, total, perPage },
      });
    } catch (e) {
      dispatch({ type: "GET_BLOG_ERROR" });
    }
  };
  const fetchSingleBLog = async (id, language, categoryId) => {
    dispatch({ type: "GET_SINGLE_BLOG_START" });
    try {
      const res = await customFetch(
        `/blog/article_detail/${categoryId}/${id}`,
        {
          headers: {
            language: `${language}`,
          },
        }
      );
      const singleBlog = res.data;

      dispatch({ type: "GET_SINGLE_BLOG_SUCCESS", payload: singleBlog });
    } catch {
      dispatch({ type: "GET_SINGLE_BLOG_ERROR" });
    }
  };

  const updateCurrentPageBlog = (page) => {
    dispatch({
      type: "UPDATE_PAGE_NUMBER_BLOG",
      payload: {
        page,
      },
    });
  };
  const fetchMoreBlog = async (language, blog_id, limit) => {
    dispatch({ type: "GET_MORE_BLOG_START" });
    try {
      const res = await customFetch(`/blog/article_other/${blog_id}/${limit}`, {
        headers: {
          language: `${language}`,
        },
      });

      const blogs = res.data;

      dispatch({ type: "GET_MORE_BLOG_SUCCESS", payload: { blogs } });
    } catch (e) {
      //  dispatch({type:"GET_BLOG_ERROR"})
      dispatch({ type: "GET_MORE_BLOG_ERROR" });
    }
  };

  //project
  const fetchProject = async (
    language,
    project,
    categoryId = 0,
    limit,
    page = 1
  ) => {
    dispatch({ type: "GET_PROJECT_START", payload: { project } });

    try {
      const res = await customFetch(
        `/project/product/${project}/${categoryId}/${limit}?page=${page}`,
        {
          headers: {
            language: `${language}`,
          },
        }
      );
      const listProjects = res.data.products;
      const total = res.data.total;

      // const blogs = res.data.articles;
      // const total = res.data.total;
      // const perPage = res.data.per_page
      // // console.log(res.data.articles)

      dispatch({
        type: "GET_PROJECT_SUCCESS",
        payload: { listProjects, total, project },
      });
    } catch (e) {
      //  dispatch({type:"GET_PROJECT_ERROR"})
    }
  };

  const fetchSingleProject = async (language, project, productId) => {
    dispatch({ type: "GET_SINGLE_PROJECT_START", payload: { project } });
    try {
      const res = await customFetch(
        `/project/product/detail/${project}/${productId}`,
        {
          headers: {
            language: `${language}`,
          },
        }
      );
      // console.log(res)
      const data = res.data;

      dispatch({
        type: "GET_SINGLE_PROJECT_SUCCESS",
        payload: { data, project },
      });
    } catch (e) {
      //  dispatch({type:"GET_PROJECT_ERROR"})
    }
  };
  const fetchMoreProject = async (language, project, productId, limit) => {
    dispatch({ type: "GET_MORE_PROJECT_START", payload: { project } });
    try {
      const res = await customFetch(
        `/project/product/other/${project}/${productId}/${limit}`,
        {
          headers: {
            language: `${language}`,
          },
        }
      );
      // console.log(res)
      const data = res.data.products;
      console.log(res);
      // console.log(data)

      dispatch({
        type: "GET_MORE_PROJECT_SUCCESS",
        payload: { data, project },
      });
    } catch (e) {
      //  dispatch({type:"GET_PROJECT_ERROR"})
    }
  };

  const fetchFilter = async (language, project) => {
    // dispatch({type:"GET_FILTER_PRODUCTION_START",payload:{project}})
    try {
      const res = await customFetch(`/project/category/${project}`, {
        headers: {
          language: `${language}`,
        },
      });
      // console.log(res)
      const data = res.data.categories;
      // console.log(data)

      dispatch({ type: "GET_FILTER_SUCCESS", payload: { data, project } });
    } catch (e) {
      //  dispatch({type:"GET_PROJECT_ERROR"})
    }
  };

  // console.log(state.ProductionProject.filterAPI)

  // gokuverse
  const updateCurrentfiltergokuverse = async (filter) => {
    dispatch({
      type: "UPDATE_CURRENT_FILTER_GOKUVERSE",
      payload: {
        filter,
      },
    });
  };
  const updateCurrentPageVerse = (item) => {
    dispatch({ type: "UPDATE_CURRENT_PAGE_VERSE", payload: item });
  };

  // gokuproduction

  const updateCurrentfilterproduction = (filter) => {
    dispatch({
      type: "UPDATE_CURRENT_FILTER_GOKUPRODUCTION",
      payload: {
        filter,
      },
    });
  };
  const updateCurrentPageProduction = (item) => {
    dispatch({ type: "UPDATE_CURRENT_PAGE_PRODUCTION", payload: item });
  };
  // Language

  const handleLanguage = () => {
    dispatch({
      type: "HANDLE_LANGUAGE",
    });
  };
  // Menu
  const handleNav = (value) => {
    // console.log("Test")
    dispatch({
      type: "HANDLE_NAV",
      payload: value,
    });
  };

  //Contact
  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.contact.name) {
      if (state.contact.name.length < 3) {
        dispatch({
          type: "UPDATE_ERROR",
          payload: {
            error: true,
            textError: "Vui lòng nhập ít nhất 3 kí tự!",
          },
        });
        return;
      }
    }
    if (state.contact.email) {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.contact.email)
      ) {
        dispatch({
          type: "UPDATE_ERROR",
          payload: {
            error: true,
            textError: "Email không hợp lệ. Vui lòng nhập lại.",
          },
        });
        return;
      }
    }
    if (state.contact.message) {
      if (state.contact.message.length < 20) {
        dispatch({
          type: "UPDATE_ERROR",
          payload: {
            error: true,
            textError: "Vui lòng nhập ít nhất 20 kí tự!",
          },
        });
        return;
      }
    }
    dispatch({
      type: "UPDATE_ERROR",
      payload: {
        error: false,
        textError: "",
      },
    });
    dispatch({ type: "UPDATE_SUCCESS" });

    //  emailjs.sendForm('service_8sc77yh', 'template_agt638s', e.target, 'aLSL9k5Hjz7I4INM2')
    //  .then((result) => {
    //      window.location.reload()
    //  }, (error) => {
    //      console.log(error.text);
    //  });
  };
  const updateInfo = (name, value) => {
    dispatch({ type: "UPDATE_INPUT", payload: { name, value } });
  };
  const handleSearchProduction = (value) => {
    dispatch({ type: "UPDATE_SEARCH_PRODUCTION", payload: { value } });
  };
  const handleSearchVerse = (value) => {
    dispatch({ type: "UPDATE_SEARCH_VERSE", payload: { value } });
  };
  const searchProject = async (keyword, limit, project) => {
    dispatch({ type: "SEARCH_PROJECT_START", payload: { project } });

    try {
      const res = await customFetch(
        `/project/product-search?project=${project}&keyword=${keyword}&limit=${limit}`,
        {
          headers: {
            language: `vi`,
          },
        }
      );
      console.log(res);

      const listProjects = res.data.products;
      // const  total = res.data.total;
      // console.log(listProjects)

      // const blogs = res.data.articles;
      // const total = res.data.total;
      // const perPage = res.data.per_page
      // // console.log(res.data.articles)
      if (listProjects !== undefined) {
        dispatch({
          type: "SEARCH_PROJECT_SUCCESS",
          payload: { listProjects, project },
        });
      }
    } catch (e) {
      dispatch({ type: "SEARCH_PROJECT_ERROR", payload: { project } });
    }
  };

  // Fillter
  // useEffect(() => {
  //       dispatch({type:"UPDATE_PROJECT_GOKUVERSE"})
  // }, [state.currentFiltergokuverse]);
  useEffect(() => {
    dispatch({ type: "UPDATE_PROJECT_GOKUPRODUCTION" });
  }, [state.ProductionProject.currentFilter]);

  useEffect(() => {
    dispatch({ type: "UPDATE_PROJECT_GOKUVERSE" });
    // console.log("test")
  }, [state.VerseProject.currentFilter]);
  // useEffect(()=>{
  //   updateCurrentfiltergokuverse("All")
  // },[])

  // useEffect(()=>{
  //   updateCurrentfilterproduction("All")

  // },[])
  // console.log(state.ProductionProject.currentArray)

  // useEffect(() => {
  //   document.title = state.title;
  //   document.getElementsByTagName("META")[3].content=state.description;
  //   document.getElementsByTagName("META")[5].content=state.title;

  //   document.getElementsByTagName("META")[6].content=state.description;
  //   document.getElementsByTagName("META")[8].content=state.url;
  //   document.getElementsByTagName("META")[10].content=state.thumb;

  // }, [state.title,state.description,state.url,state.thumb]);
  return (
    <TumysContext.Provider
      value={{
        ...state,
        fetchBlog,
        fetchSingleBLog,
        handleNav,
        updateCurrentPageBlog,
        handleLanguage,
        updateCurrentfiltergokuverse,
        updateCurrentfilterproduction,
        fetchProject,
        fetchSingleProject,
        fetchMoreProject,
        fetchFilter,
        updateCurrentPageProduction,
        updateCurrentPageVerse,
        fetchProjectHome,
        fetchMoreBlog,
        updateInfo,
        handleSubmit,
        updateMeta,
        handleSearchProduction,
        handleSearchVerse,
        searchProject,
      }}
    >
      {children}
    </TumysContext.Provider>
  );
};
// make sure use
export const useTumysContext = () => {
  return useContext(TumysContext);
};
