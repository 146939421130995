import React from 'react'
import styled from 'styled-components'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import img1 from "../../assets/images/new1.png";
import img2 from "../../assets/images/new2.png";
import img3 from "../../assets/images/new3.png";
import img4 from "../../assets/images/new4.png";
import img5 from "../../assets/images/new5.png";
import img6 from "../../assets/images/new6.png";
import img7 from "../../assets/images/new7.png";
import img8 from "../../assets/images/new8.png";
import project100 from "../../assets/images/project100.png"
import { Link } from 'react-router-dom';
import Singleblog from './Singleblog';
import { useTumysContext } from '../../context/tumycontext';

function Blog() {

  const { english, handleLanguage, nav, handleNav ,fetchBlog,currentpageBlog} = useTumysContext();
   
    
    
    
      if(currentpageBlog.length > 0){
        return (
          <Wrapper>
           
             { currentpageBlog.map((item,index)=>{
            
             return(
      
            <Singleblog key={index} item={item} />
           
             )
             })}
      
            
             
            
              
            
          </Wrapper>
        )
      }else{
        return <h1 style={{textAlign:"center",margin:"200px 0"}}>Hiện tại chưa có bài đăng nào. Vui lòng quay lại sau!</h1>
      }

 
}
const Wrapper = styled.div`
width: 100%;
background-color: white;
padding: 80px 200px;
min-height: 600px;


display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 20px;

@media screen and (max-width: 1024px) {
grid-template-columns: 1fr;
padding: 80px 120px;
  
}
@media screen and (max-width: 768px) {
grid-template-columns: 1fr;
padding: 60px 80px;
  
}
@media screen and (max-width: 596px) {
grid-template-columns: 1fr;
padding: 40px 60px;
  
}
@media screen and (max-width: 424px) {
grid-template-columns: 1fr;
padding: 40px 20px;
  
}
@media screen and (max-width: 324px) {
grid-template-columns: 1fr;
padding: 40px 20px;
  
}

/* &>div{
    height: 100%;
width: 100%;


    &>div{
        height: 100%;
width: 100%;

    }
} */


 

 
`

export default Blog