import React, { useEffect } from "react";
import { useTumysContext } from "../context/tumycontext";
import Nav from "../components/Share/Nav";

import Intro from "../components/Landing/Intro";
import Body from "../components/Landing/Body";
import { Helmet } from "react-helmet-async";

function Landing() {
  const { nav } = useTumysContext();
  useEffect(() => {
    // updateMeta("Dịch vụ Chụp ảnh Khách sạn và Resort","Kame sẽ mang lại cho Quý khách những hình ảnh chuyên nghiệp nhất với các gói dịch vụ chụp ảnh Khách sạn và Resort phù hợp cho từng nhu cầu riêng.","https://goku.agency/img/thumb-min.png","https://goku.agency/service/chup-anh-khach-san-resort")
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="https://goku.agency/img/favicon.png" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content="#000000" />

        <meta
          data-rh="true"
          name="description"
          content="Goku sẽ mang lại cho Quý Chủ đầu tư những hình ảnh tiến độ dự án chuyên nghiệp nhất với các gói dịch vụ phù hợp với từng nhu cầu riêng."
        />

        <title>Chụp Ảnh & Quay Phim Tiến Độ Dự Án Bất Động Sản</title>
        <meta data-rh="true" name="robots" content="all" />

        <meta
          data-rh="true"
          property="og:title"
          content="Chụp Ảnh & Quay Phim Tiến Độ Dự Án Bất Động Sản"
        />
        <meta
          data-rh="true"
          property="og:description"
          content="Goku sẽ mang lại cho Quý Chủ đầu tư những hình ảnh tiến độ dự án chuyên nghiệp nhất với các gói dịch vụ phù hợp với từng nhu cầu riêng."
        />
        <meta data-rh="true" property="og:type" content="website" />
        <meta
          data-rh="true"
          property="og:url"
          content="https://goku.agency/chup-anh-quay-phim-tien-do-du-an-bat-dong-san"
        />
        <meta
          data-rh="true"
          property="og:image"
          content="https://goku.agency/img/covergoku.jpg"
        />
        <meta data-rh="true" property="og:image:alt" content="Goku" />
        <meta data-rh="true" property="og:image:width" content="1200" />
        <meta data-rh="true" property="og:image:height" content="628" />
        <link
          rel="canonical"
          href="https://goku.agency/chup-anh-quay-phim-tien-do-du-an-bat-dong-san"
        />
      </Helmet>
      {nav ? (
        <Nav />
      ) : (
        <>
          <Intro />
          <Body />
        </>
      )}
    </>
  );
}

export default Landing;
