import React from "react";
import styled from "styled-components";
import Goiitem from "./Goiitem";
// import { useMediaQuery } from "usehooks-ts";
import Button from "./Button";

function Tableprice() {
  // const matches = useMediaQuery("(max-width: 896px)");

  const data1 = [
    {
      id: 1,
      name: "Gói 6 Tháng",
      timechup: "1 Bộ Ảnh tiến độ/1 tháng",
      talent: "1 Video tiến độ/Quý",
      soluonganh: "Giá: 9,900,000 đ/Tháng",
      // flycam: "Flycam : Không",
      // goithietbi: "Gói thiết bị : Cơ bản",
      // phuhop: "2",
      backgroundtop: "#10505B",
    },
    {
      id: 2,
      name: "Gói 1 Năm",
      timechup: "1 Bộ Ảnh tiến độ/1 tháng",
      talent: "1 Video tiến độ/Quý",
      soluonganh: "Giá: 8,000,000 đ/Tháng",
      flycam: "Tiết kiệm 24%",
      goithietbi: "1 Tháng chụp tiến độ",
      // phuhop: "3",
      backgroundtop: "#03A10E",
    },
    {
      id: 3,
      name: "Gói 2 Năm",
      timechup: "1 Bộ Ảnh tiến độ/1 tháng",
      talent: "1 Video tiến độ/Quý",
      soluonganh: "Giá: 6,800,000 đ/Tháng",
      flycam: "Tiết kiệm 35%",
      goithietbi: "1 Phim toàn bộ quá trình thi công",
      // phuhop: "4",
      backgroundtop: "#D48207",
    },
  ];
  return (
    <Wrapper>
      <div className="info">
        <h2>3 gói chụp ảnh & quay phim tiến độ dự án.</h2>
        <p>
          Goku cung cấp 3 gói chụp ảnh & quay phim tiến độ dự án bất động sản
        </p>
      </div>

      <div className="container">
        {data1.map((item) => {
          return <Goiitem text={`${item.id === 0 && "black"}`} item={item} />;
        })}
        {/* <Goiitem /> */}
      </div>

      {/* <button onClick={()=>{
                document.querySelector(".fb_customer_chat_bounce_out_v2").classList.remove("fb_customer_chat_bounce_in_from_left");
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.maxHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.minHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.height="300px";


            }} className="button-30" role="button">
               <span>
                Báo giá ngay 

                </span>
                <svg fill='#06c' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
            </button> */}
      <Button text="Báo giá ngay" />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  background-color: white;
  text-align: center;
  padding: 80px 10px;
  border-radius: 12px;
  .button-30 {
    border: none;
    background-color: transparent;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    color: #06c;
    font-size: 24px;
    line-height: 1.16667;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
    span {
      margin-right: 4px;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .button-30:hover {
    text-decoration: underline;
  }
  .info {
    width: 80%;
    margin: 0 auto;
    h2 {
      margin-bottom: 30px;
      font-family: SF-Pro-Display-Bold;
    }
    p {
      font-size: 22px;
      font-family: SF-Pro-Display-Light;
      text-align: center;
    }
  }
  .container {
    display: grid;
    margin-top: 40px;
    padding: 10px 40px;
    grid-template-columns: 1fr 1fr 1fr;
    .item {
    }
  }
  @media screen and (max-width: 1596px) {
    .container {
      padding: 10px 40px;
    }
  }
  @media screen and (max-width: 1268px) {
    .container {
      padding: 10px 40px;
    }
    .info {
      h2 {
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .container {
      padding: 10px 40px;
    }
    .info {
      h2 {
        font-size: 36px;
      }
      p {
        font-size: 18px;
        font-family: SF-Pro-Display-Light;
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 896px) {
    .container {
      grid-template-columns: 1fr;
    }
    .info {
      h2 {
        font-size: 34px;
      }
      p {
        /* color: black; */
        font-size: 22px;
      }
    }

    .container {
      grid-template-columns: 1 1 1 1;
    }
  }
  @media screen and (max-width: 796px) {
    .container {
      /* padding: 10px 60px; */
    }
    .info {
      h2 {
        font-size: 34px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 596px) {
    .container {
      padding: 10px 40px;
    }
    .info {
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 424px) {
    .container {
      padding: 10px 0px;
    }
    .info {
      h2 {
        font-size: 28px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 360px) {
    .container {
      margin-top: 16px;
    }
    .info {
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 10px;
      }
    }
  }
`;

export default Tableprice;
