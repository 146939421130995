import React, { useEffect } from "react";
// import Masonry from '@bakunya/react-masonry'
// import Masonry from 'react-masonry-css'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import styled from "styled-components";
import img1 from "../../assets/images/work1.png";
import img2 from "../../assets/images/work2.png";
import img3 from "../../assets/images/work3.png";
import img4 from "../../assets/images/work4.png";
import img5 from "../../assets/images/work5.png";
import img6 from "../../assets/images/work6.png";
import img7 from "../../assets/images/work7.png";
import img8 from "../../assets/images/work8.png";
import khangdien from "../../assets/images/khangdien1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { EffectCube, Pagination } from "swiper";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
// import "swiper/css/navigation";

// import quote from "../../assets/images/quote.png";
// import thanglong from "../../assets/images/thanglong.png";
// import vin from "../../assets/images/vin.jpg";
// import kusto from "../../assets/images/kusto.jpg";
// import khangdien from "../../assets/images/khangdien.png";
// import bamboo from "../../assets/images/bamboo.png";
// import eco from "../../assets/images/eco.jpg";
// import encity from "../../assets/images/encity.png";
// // import r from "../../assets/images/bamboo.png"
// import danhkhoi from "../../assets/images/danhkhoi.png";
// import datxanh from "../../assets/images/datxanh.jpg";
// import angia from "../../assets/images/angia.png";
// import pixos from "../../assets/images/pixos.png";

import project100 from "../../assets/images/project100.png";
import project100english from "../../assets/images/project100english.png";

import { Link } from "react-router-dom";
import { useTumysContext } from "../../context/tumycontext";
import Heading from "../Share/Heading";
import Loadingproject from "../Share/Loadingproject";

function Homework() {
  const { HomeProject, english, fetchProjectHome } = useTumysContext();

  const data = [
    {
      id: 1,
      img: img1,
      height: "50%",
      layer: true,
    },

    {
      id: 2,
      img: img2,
      height: "25%",
      layer: true,
    },

    {
      id: 3,
      img: img3,
      height: "30%",
      layer: true,
    },

    {
      id: 4,
      img: img4,
      height: "20%",
      // mb : "0",
      layer: true,
    },
    {
      id: 6,
      textTitle: `${english ? "OUR WORK" : "Dự án"}`,
      height: "20%",
      button: true,
    },

    {
      id: 5,
      img: img5,
      height: "20%",
      layer: true,
    },
    {
      id: 7,
      img: img6,
      height: "30%",
      layer: true,
    },
    {
      id: 8,
      img: img7,
      height: "55%",
      layer: true,
    },
    {
      id: 9,
      img: img8,
      height: "50%",
      layer: true,
    },
  ];
  const data1 = [
    {
      id: 1,
      img: img1,
      height: "50%",
      layer: true,
    },

    {
      id: 2,
      img: img2,
      height: "25%",
      layer: true,
    },

    {
      id: 3,
      img: img3,
      height: "30%",
      layer: true,
    },

    {
      id: 4,
      img: img4,
      height: "20%",
      // mb : "0",
      layer: true,
    },
    {
      id: 6,
      img: img4,
      height: "15%",
      // mb : "0",
      layer: true,
    },

    {
      id: 5,
      img: img5,
      height: "20%",
      layer: true,
    },
    {
      id: 7,
      img: img6,
      height: "30%",
      layer: true,
    },
    {
      id: 8,
      img: img7,
      height: "60%",
      layer: true,
    },
    {
      id: 9,
      img: img8,
      height: "50%",
      layer: true,
    },
  ];
  useEffect(() => {
    fetchProjectHome(`${english ? "en" : "vi"}`, 17);
  }, []);

  return (
    <Wrapper>
      {HomeProject.isLoading ? (
        <Loadingproject />
      ) : (
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 3, 750: 3, 900: 3 }}
            >
              <Masonry gutter="20px">
                {HomeProject.data1.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        height: `${item.height1}`,
                        marginBottom: `${item.mb && item.mb}`,
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      className="item"
                    >
                      <Link
                        aria-label="Read more about project"
                        to={
                          item.status === 1 && !item.button
                            ? `/projectdetail/${item.project}/${item.id}/${item.slug}`
                            : "/work/gokuproduction"
                        }
                      >
                        {/* {item.image &&  <img   src={item.image} /> } */}
                        {item.status === 1 && (
                          <div className="layer">
                            {/* <div className="logo">
                      <img style={{width: "100%",height: "100%"}} src={khangdien} />
                   </div> */}

                            <div className="layer-info">
                              <div className="left">
                                <h1>{item.name}</h1>
                                <div className="button layer-button">
                                  Discovery
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 18 18"
                                    fill="white"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="right">
                                <svg
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M27.7065 23.293L16.7275 12.313L26.4145 7.91C26.8035 7.734 27.0365 7.329 26.9955 6.905C26.9555 6.48 26.6485 6.128 26.2335 6.028L1.23346 0.0280005C0.896459 -0.0539995 0.539459 0.0460003 0.293459 0.293C0.0474591 0.539 -0.0535407 0.895 0.0274593 1.233L6.02746 26.233C6.12746 26.648 6.47946 26.954 6.90446 26.995C7.33846 27.03 7.73346 26.801 7.91046 26.413L12.3135 16.727L23.2925 27.706C23.4875 27.902 23.7435 28 23.9995 28C24.2555 28 24.5115 27.902 24.7065 27.707L27.7065 24.707C28.0975 24.316 28.0975 23.684 27.7065 23.293Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        )}

                        {item.button && (
                          <div className="title">
                            <div className="title-img">
                              <img
                                alt="Our Project"
                                src={english ? project100 : project100english}
                              />
                            </div>

                            <div className="title-text">
                              <Heading text={item.textTitle} color="black" />
                              {/* <h1>{item.textTitle}</h1> */}
                              {/* <div className='button'>
               {english ? "More Discovery": "Tìm hiểu thêm"}  
               <svg
                 width="14"
                 height="14"
                 viewBox="0 0 18 18"
                 fill="black"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
                   fill="black"
                 />
               </svg>
             </div> */}
                            </div>
                          </div>
                        )}
                      </Link>
                    </div>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </SwiperSlide>

          <SwiperSlide>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 3, 750: 3, 900: 3 }}
            >
              <Masonry gutter="20px">
                {HomeProject.data2.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        height: `${item.height1}`,
                        marginBottom: `${item.mb && item.mb}`,
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      className="item"
                    >
                      <Link
                        aria-label="Read more about project"
                        to={
                          item.status === 1 &&
                          `/projectdetail/${item.project}/${item.id}/${item.slug}`
                        }
                      >
                        {/* {item.image &&  <img   src={item.image} /> } */}
                        {item.status === 1 && (
                          <div className="layer">
                            {/* <div className="logo">
                      <img style={{width: "100%",height: "100%"}} src={khangdien} />
                   </div> */}

                            <div className="layer-info">
                              <div className="left">
                                <h1>{item.name}</h1>
                                <div className="button layer-button">
                                  Discovery
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 18 18"
                                    fill="white"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="right">
                                <svg
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M27.7065 23.293L16.7275 12.313L26.4145 7.91C26.8035 7.734 27.0365 7.329 26.9955 6.905C26.9555 6.48 26.6485 6.128 26.2335 6.028L1.23346 0.0280005C0.896459 -0.0539995 0.539459 0.0460003 0.293459 0.293C0.0474591 0.539 -0.0535407 0.895 0.0274593 1.233L6.02746 26.233C6.12746 26.648 6.47946 26.954 6.90446 26.995C7.33846 27.03 7.73346 26.801 7.91046 26.413L12.3135 16.727L23.2925 27.706C23.4875 27.902 23.7435 28 23.9995 28C24.2555 28 24.5115 27.902 24.7065 27.707L27.7065 24.707C28.0975 24.316 28.0975 23.684 27.7065 23.293Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        )}

                        {item.button && (
                          <div className="title">
                            <div className="title-img">
                              <img
                                src={english ? project100 : project100english}
                              />
                            </div>

                            <div className="title-text">
                              <Heading text={item.textTitle} color="black" />
                              {/* <h1>{item.textTitle}</h1> */}
                              {/* <div className='button'>
               {english ? "More Discovery": "Tìm hiểu thêm"}  
               <svg
                 width="14"
                 height="14"
                 viewBox="0 0 18 18"
                 fill="black"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
                   fill="black"
                 />
               </svg>
             </div> */}
                            </div>
                          </div>
                        )}
                      </Link>
                    </div>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>

            {/* <ResponsiveMasonry  columnsCountBreakPoints={{ 350: 3, 750: 3, 900: 3 }}>
           <Masonry gutter="20px">
             {HomeProject.data2.map((item) => {
               return (
                 <div style={{height : `${item.height1}`,marginBottom: `${item.mb && item.mb}`}} className="item">
                 <Link>
                 {item.img &&  <img   src={item.img} /> }
            {item.status===1 && (
   
                <div className="layer">
                 <div className="logo">
                    <img style={{width: "100%",height: "100%"}} src={khangdien} />
                 </div>
                     
                <div className="layer-info">
                 <div className="left">
                   <h1>{item.name}</h1>
                   <div className='button layer-button'>
             Discovery 
             <svg
               width="14"
               height="14"
               viewBox="0 0 18 18"
               fill="white"
               xmlns="http://www.w3.org/2000/svg"
             >
               <path
                 d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
                 fill="white"
               />
             </svg>
           </div>
                 </div>
                 <div className="right">
   
   <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M27.7065 23.293L16.7275 12.313L26.4145 7.91C26.8035 7.734 27.0365 7.329 26.9955 6.905C26.9555 6.48 26.6485 6.128 26.2335 6.028L1.23346 0.0280005C0.896459 -0.0539995 0.539459 0.0460003 0.293459 0.293C0.0474591 0.539 -0.0535407 0.895 0.0274593 1.233L6.02746 26.233C6.12746 26.648 6.47946 26.954 6.90446 26.995C7.33846 27.03 7.73346 26.801 7.91046 26.413L12.3135 16.727L23.2925 27.706C23.4875 27.902 23.7435 28 23.9995 28C24.2555 28 24.5115 27.902 24.7065 27.707L27.7065 24.707C28.0975 24.316 28.0975 23.684 27.7065 23.293Z" fill="white"/>
   </svg>
   
                 </div>
                </div>
                </div>
            )}
   
                 {item.button && 
                 <div className="title">
                   <img src={project100} />
   
                   <div className="title-text">
   
                  <h1>Our Work</h1>
                 <div className='button'>
             More Discovery  
             <svg
               width="14"
               height="14"
               viewBox="0 0 18 18"
               fill="black"
               xmlns="http://www.w3.org/2000/svg"
             >
               <path
                 d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
                 fill="black"
               />
             </svg>
           </div>
                   </div>
                   
                 </div>
           }
                 </Link>
   
   
   
                
               </div>
                
               );
             })}
           </Masonry>
        
         </ResponsiveMasonry> */}
          </SwiperSlide>
        </Swiper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  /* height : 100vh; */
  /* width: 100vw; */
  /* overflow-x: auto; */
  padding: 50px 0px;
  /* height: 800px; */
  /* display: flex; */
  height: 1200px;

  /* display: grid;
  grid-template-columns: repeat( auto-fit, minmax(400px, 1fr) ); */
  /* overflow-y: auto !important; */

  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-pagination {
    /* bottom: -30px; */
    z-index: 999 !important;
    /* right: 20px; */
    /* right: 0!important;
  top: 50%!important;
  transform: translateY(-50%)!important; */
  }
  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    margin-right: 14px !important;
    background-color: white;
    opacity: 1;
    display: none;
  }
  .swiper-pagination-bullet-active {
    background-color: saddlebrown;
  }
  .swiper-slide:first-child {
    padding-left: 10px !important;
  }
  .swiper-slide:last-child {
    padding-right: 10px !important;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .swiper-slide > div {
    height: 100%;
    & > div {
      height: 100%;
    }
  }
  .swiper-slide > div {
    width: 98.5%;
  }

  /* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

  .swiper-slide {
    width: 85%;
  }

  .swiper-slide:nth-child(2n) {
    width: 80%;
  }

  .swiper-slide:nth-child(3n) {
    width: 50%;
  }

  .item {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    position: relative;
    a {
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .item:hover .layer {
    display: flex;
  }
  .layer {
    position: absolute;
    width: 100%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    height: 100%;
    /* display: flex; */
    flex-direction: column;
    text-align: left;
    justify-content: flex-end;
    padding: 20px;
    display: none;
    /* align-items: ; */
    .logo {
      width: 50px;
    }
    .layer-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .left {
        width: 100%;
      }
      h1 {
        font-size: 32px;
        font-family: SF-Pro-Display-Bold;
        color: white;
      }
      .layer-button {
        border: 1px solid white;
        color: white;
        width: 40%;
      }
    }

    img {
      width: 100px;
    }
  }

  .button {
    border: 1px solid black;
    border-radius: 20px;
    padding: 4px 10px;
    width: 60%;
    display: flex;
    align-items: center;
    color: black;
    margin-right: auto;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    svg {
      margin-left: 8px;
    }
  }
  .title {
    display: grid;
    width: 100%;
    height: 100%;
    align-items: center;
    /* justify-content: space-around; */
    grid-template-columns: 35% 65%;
    /* grid-gap: 30px; */
    .title-text {
      /* flex-basis: 70%; */
    }
    h1 {
      /* text-align: left;
      font-family: SF-Pro-Display-Bold;
      color: black;
      margin-right: auto;
      font-size: 68px; */
    }
    .title-img {
      width: 100%;
      height: 100%;
      /* background-color: red; */
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      /* width: 100%; */
      /* display: ; */
      /* margin-right: auto; */
      /* flex-basis: 30%; */
      height: 80%;
      /* margin: auto 0; */
      /* display: block; */
    }
  }
  @media screen and (max-width: 1024px) {
    height: 800px;
    .layer {
      padding: 12px 10px 20px;
      /* display: flex; */
      .logo {
        width: 20px;
        height: 20px;
      }
      .layer-info {
        .left {
          width: 100%;
          h1 {
            font-size: 18px;
            margin-bottom: 2px;
          }
          .layer-button {
            width: 55%;
            font-size: 12px;
            border-radius: 8px;
            height: auto;
            margin-top: 5px;
            svg {
              width: 8px;
              height: 8px;
              margin-left: 4px;
            }
          }
          .button {
            padding: 4px 8px;
          }
        }
        .right {
          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    height: 550px;
    padding: 20px 0;
    .item {
      a {
        img {
          border-radius: 6px !important;
        }
      }
      border-radius: 4px !important;
    }
    .swiper {
      .swiper-wrapper {
        & > div {
          & > div {
            & > div {
              gap: 8px !important;
              & > div {
                gap: 8px !important;
              }
            }
          }
        }
      }
    }
    .title {
      gap: 4px;
      grid-template-columns: 40% 60%;
      .title-img {
        /* flex-basis: 70%; */
        width: 100%;
        /* margin-right: auto; */
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title-text {
        /* flex-basis: 30%!important; */

        h1 {
          font-size: 24px;
          margin-bottom: 0;
          /* text-align: left; */
        }
      }
    }
    .layer {
      padding: 4px 4px 8px;
      /* display: flex; */
      .logo {
        width: 12px;
        height: 12px;
      }
      .layer-info {
        .left {
          h1 {
            font-size: 12px;
            margin-bottom: 2px;
          }
          .layer-button {
            width: 60%;
            font-size: 6px;
            border-radius: 4px;
            height: auto;
            margin-top: 5px;
            svg {
              width: 6px;
              height: 6px;
              margin-left: 2px;
            }
          }
          .button {
            padding: 1px 2px;
          }
        }
        .right {
          svg {
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 424px) {
    height: 350px;
    padding: 20px 0;
    .item {
      a {
        img {
          border-radius: 6px !important;
        }
      }
      border-radius: 6px !important;
    }
    .swiper {
      .swiper-wrapper {
        & > div {
          & > div {
            & > div {
              gap: 5px !important;
              & > div {
                gap: 5px !important;
              }
            }
          }
        }
      }
    }
    .title {
      gap: 4px;
      grid-template-columns: 1fr;
      .title-img {
        /* flex-basis: 70%; */
        width: 45%;
        /* margin-right: auto; */
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title-text {
        /* flex-basis: 30%!important; */

        h1 {
          font-size: 20px;
          margin-bottom: 0;
          line-height: 24px !important;
          /* text-align: left; */
        }
      }
    }
    .layer {
      padding: 4px 4px 8px;
      /* display: flex; */
      .logo {
        width: 12px;
        height: 12px;
      }
      .layer-info {
        .left {
          h1 {
            font-size: 7px;
            margin-bottom: 2px;
          }
          .layer-button {
            width: 60%;
            font-size: 6px;
            border-radius: 4px;
            height: auto;
            margin-top: 5px;
            svg {
              width: 6px;
              height: 6px;
              margin-left: 2px;
            }
          }
          .button {
            padding: 1px 2px;
          }
        }
        .right {
          svg {
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 324px) {
    height: 300px;
    padding: 20px 0;
    .item {
      a {
        img {
          border-radius: 6px !important;
        }
      }
      border-radius: 4px !important;
    }
    .swiper {
      .swiper-wrapper {
        & > div {
          & > div {
            & > div {
              gap: 5px !important;
              & > div {
                gap: 5px !important;
              }
            }
          }
        }
      }
    }
    .title {
      gap: 4px;
      grid-template-columns: 1fr;
      .title-img {
        /* flex-basis: 70%; */
        width: 40%;
        /* margin-right: auto; */
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title-text {
        /* flex-basis: 30%!important; */

        h1 {
          font-size: 18px;
          margin-bottom: 0;
          /* text-align: left; */
        }
      }
    }
    .layer {
      padding: 4px 4px 8px;
      /* display: flex; */
      .logo {
        width: 12px;
        height: 12px;
      }
      .layer-info {
        .left {
          h1 {
            font-size: 5px;
            margin-bottom: 2px;
          }
          .layer-button {
            width: 80%;
            font-size: 6px;
            border-radius: 4px;
            height: auto;
            margin-top: 5px;
            svg {
              width: 6px;
              height: 6px;
              margin-left: 2px;
            }
          }
          .button {
            padding: 1px 2px;
          }
        }
        .right {
          svg {
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }
`;

export default Homework;
