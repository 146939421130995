import React from "react";
import styled from "styled-components";
// import imgstar from "../../assets/images/start.png";

function Goiitem({ item, text }) {
  return (
    <Wrapper backgroundtop={item.backgroundtop} text={text}>
      <div className="top">
        <h2>{item.name}</h2>
        <div className="arrow-down"></div>
      </div>
      <div className="bottom">
        <p>{item.timechup}</p>
        <div className="line"></div>

        <p>{item.talent}</p>
        {item.talent !== undefined && <div className="line"></div>}

        <p>{item.soluonganh}</p>
        {item.soluonganh !== undefined && <div className="line"></div>}

        <p>{item.flycam}</p>
        {item.flycam !== undefined && <div className="line"></div>}

        <p>{item.goithietbi}</p>
        {item.goithietbi !== undefined && <div className="line"></div>}

        {/* <p className="star-container">Phù hợp với khách sạn :</p>
        {item.phuhop === "5" && (
            <p > 
                <img alt="Star" className="star" src={imgstar} />
                <img alt="Star" className="star" src={imgstar} />
                <img alt="Star" className="star" src={imgstar} />
                <img alt="Star" className="star" src={imgstar} />
                <img alt="Star" className="star" src={imgstar} />
            
            </p>
        )} */}
        {/* {item.phuhop === "4" && (
          <p>
            <img alt="Star" className="star" src={imgstar} />
            <img alt="Star" className="star" src={imgstar} />
            <img alt="Star" className="star" src={imgstar} />

            <img alt="Star" className="star" src={imgstar} />
          </p>
        )} */}
        {/* {item.phuhop === "3" && (
          <p>
            <img alt="Star" className="star" src={imgstar} />
            <img alt="Star" className="star" src={imgstar} />
            <img alt="Star" className="star" src={imgstar} />
          </p>
        )} */}
        {/* {item.phuhop === "2" && (
          <p>
            <img alt="Star" className="star" src={imgstar} />
            <img alt="Star" className="star" src={imgstar} />
          </p>
        )} */}
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  border-radius: 12px;
  width: 85%;
  margin: 20px auto;
  justify-content: space-evenly;

  /* text-align: center; */

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  &:hover {
    scale: 1.02;
    transition: all linear 0.1s;
  }
  .top {
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border: none;

    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: ${(props) => props.backgroundtop};
    position: relative;
    /* border: 1px solid black; */
    /* background-color: red; */

    padding: 24px 12px;

    .arrow-down {
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);

      border-top: ${(props) => {
        return `20px solid ${props.backgroundtop}`;
      }};
    }
    h2 {
      font-size: 26px;
      margin: 0;
      color: white;
      font-family: SF-Pro-Display-Bold;
    }
  }
  .bottom {
    /* border: 1px solid black; */
    border: none;
    text-align: center;
    padding: 48px 24px 48px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    .line {
      width: 60%;
      height: 1px;
      margin: 0 auto;
      background-color: #ccc;
      margin-bottom: 30px;
    }
    .star-container {
      margin-bottom: 8px;
    }
    p {
      /* font-size: 12px; */
      .star {
        height: 12px;
        width: 12px;
        margin-right: 4px;
      }

      font-family: ${(props) => {
        if (props.text === "black") {
          return "SVN-Gotham Bold";
        }
      }};
      font-weight: ${(props) => {
        if (props.text === "black") {
          return "Bold";
        }
      }};
    }
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
    .bottom {
      padding: 40px 8px 20px;

      /* height: 300px; */
      p {
        font-size: 12px;
      }
    }
    .top {
      padding: 16px 12px;
      .arrow-down {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        display: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 596px) {
    .top {
      h2 {
        font-size: 16px;
      }
    }

    .bottom {
      /* padding: 8px 4px; */
      padding: 20px 4px;
      p {
        font-size: 11px;
      }
    }
  }
  @media screen and (max-width: 424px) {
    .bottom {
      /* height: 250px; */
      /* padding: 8px 6px; */
      .line {
        margin-bottom: 8px;
      }
      p {
        font-size: 10px;
        margin-bottom: 8px;
      }
    }
  }
  @media screen and (max-width: 324px) {
    .top {
      h2 {
        font-size: 16px;
      }
    }
    .bottom {
      /* height: 250px; */

      p {
        font-size: 9px;
      }
    }
  }
`;

export default Goiitem;
