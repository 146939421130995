import React from "react";
import styled from "styled-components";
// import img1 from "../../assets/images/cgi.JPG"
import img1 from "../../assets/images/landingchuyennghiep.jpg";

import img2 from "../../assets/images/test2.png";
import img3 from "../../assets/images/test3.jpeg";
import { useTumysContext } from "../../context/tumycontext";

function Toanquoc() {
  const { english, handleLanguage, nav, handleNav } = useTumysContext();

  return (
    <Wrapper>
      <div className="left">
        <div className="text">
          <h2>
            {english ? "CGI Photography & Videography" : "Chuyên nghiệp."}
          </h2>
          {/* <h1> & Videography</h1> */}
        </div>

        <div className="left-img">
          <div className="img-box">
            <img alt="CGI Image" src={img1} style={{ width: "100%" }} />
          </div>

          <div className="shadow"></div>
        </div>
      </div>
      <div className="right">
        <div className="text">
          {/* <p>
                {english?" To bring investor's idea to life, Goku provides high-quality videos and/or photos of the areas surrounding the real-estate project, which are subsequently layered into the project's 3D videos or photos. We give home-buyers the most realistic and immersive view of the project in its embryonic stage. ":"Kame có sẵn đội ngũ nhân sự tại Đà Nẵng, Hồ Chí Minh, Nha Trang và Hà Nội giúp giảm chi phí đi lại và giá thành các gói chụp ảnh Khách sạn và Resort."}
           
            </p> */}
          <p>
            Goku là một Công ty chuyên nghiệp nên có các phòng ban để phục vụ
            khách hàng trên từng bước triển khai, giúp cho việc triển khai sản
            xuất hình ảnh diễn ra hiệu quả nhất.
          </p>
          {/* {!english?<p><span>*Giai đoạn thực hiện: </span>Giai đoạn diễn hoạ hình ảnh và làm phim dự án</p>:<p>This service is provided during the 3D modeling phase.</p>} */}
          {/* <p>Từ  <span>6,900,000 đ</span></p> */}
          {/* <Button text /> */}
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 65% 35%;
  /* height: 550px; */
  background-color: white;
  padding: 40px 40px 70px;
  border-radius: 16px;
  /* margin: 100px 0; */

  /* grid-gap: 30px; */
  margin-bottom: 220px;

  .left {
    .text {
      width: 100%;
      text-align: right;
      margin-bottom: 40px;
      /* height: 20%; */

      h2 {
        color: black;
        font-family: SF-Pro-Display-Bold;
        font-size: 42px;
        margin-bottom: 20px;
      }
    }
    .left-img {
      /* height: 80%; */
      height: 280px;
      border-radius: 16px;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
        rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
        rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

      .img-box {
        width: 100%;
        /* height: 100%; */
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 99;
        img {
          border-radius: 16px;
        }
      }
    }
    .shadow {
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;
      position: absolute;
      top: -5%;
      right: -3%;
      border-radius: 16px;
    }
  }
  .right {
    .text {
      margin-left: 60px;
      p {
        font-size: 22px;
        font-family: SF-Pro-Display-Light;
        span {
          color: #e36942;
          font-family: SF-Pro-Display-Bold;
        }
      }
      p:nth-child(1) {
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        span {
          color: black;
          font-family: SF-Pro-Display-Bold;
        }
      }
    }
    .btn-price {
      align-items: center;
      appearance: none;
      background-color: #fcfcfd;
      border-radius: 4px;
      border-width: 0;
      box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
      box-sizing: border-box;
      color: #36395a;
      cursor: pointer;
      display: inline-flex;
      font-family: "JetBrains Mono", monospace;
      height: 48px;
      justify-content: center;
      line-height: 1;
      list-style: none;
      overflow: hidden;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;
      text-align: left;
      text-decoration: none;
      transition: box-shadow 0.15s, transform 0.15s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: nowrap;
      will-change: box-shadow, transform;
      font-size: 18px;
    }

    .btn-price:focus {
      box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    }

    .btn-price:hover {
      box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
      transform: translateY(-2px);
    }

    .btn-price:active {
      box-shadow: #d6d6e7 0 3px 7px inset;
      transform: translateY(2px);
    }
  }
  @media screen and (max-width: 1396px) {
    .left {
    }
  }
  @media screen and (max-width: 1200px) {
    .left {
      .text {
        h2 {
          font-size: 36px;
        }
      }
      .left-img {
        /* height: 65%; */
        height: 170px;
      }
    }
    .right {
      .text {
        p {
          font-size: 18px;
        }
      }
    }
  }
  @media screen and (max-width: 796px) {
    /* height: 460px; */
    .left {
      .text {
        h2 {
          font-size: 34px;
        }
      }
      .left-img {
        height: 55%;
        height: 170px;
      }
    }
    .right {
      .text {
        p {
          font-size: 16px;
          text-align: justify;
        }
      }
    }
  }
  @media screen and (max-width: 596px) {
    padding: 30px 30px 10px;
    height: 460px;
    .left {
      .text {
        h2 {
          font-size: 30px;
        }
      }
    }
    .right {
      .text {
        margin-left: 20px;
        p {
          font-size: 14px;
        }
      }
    }
  }
  @media screen and (max-width: 456px) {
    padding: 30px 30px 10px;
    height: 430px;
    .left {
      .text {
        margin-bottom: 20px;
        h2 {
          font-size: 26px;
        }
      }
    }
    .right {
      .text {
        margin-left: 20px;
        p {
          font-size: 11px;
        }
        p:nth-child(1) {
          margin-bottom: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 360px) {
    padding: 30px 30px 10px;
    height: 300px;
    .left {
      .text {
        margin-bottom: 10px;
        h2 {
          font-size: 22px;
        }
      }
    }
    .right {
      .text {
        margin-left: 12px;
        p {
          font-size: 9px;
          line-height: 12px;
        }
        p:nth-child(1) {
          margin-bottom: 12px;
        }
      }
    }
  }
  @media screen and (max-width: 290px) {
    padding: 20px 10px 10px;
    height: 280px;
    .left {
      .text {
        margin-bottom: 8px;
        h2 {
          font-size: 18px;
        }
      }
    }
    .right {
      .text {
        margin-left: 8px;
        p {
          font-size: 8px;
          line-height: 12px;
        }
        p:nth-child(1) {
          margin-bottom: 8px;
        }
      }
    }
  }
`;

export default Toanquoc;
