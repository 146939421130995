import React, { useState } from "react";
import styled from "styled-components";
import img1 from "../../assets/images/actual-facility.jpg";
import img2 from "../../assets/images/actual-showunit.jpg";
import img3 from "../../assets/images/actual-whole.jpg";
import { useTumysContext } from "../../context/tumycontext";
import Button from "../Landing/Button";

function Chupanhcongtrinhmobile() {
  const { english, handleLanguage, nav, handleNav } = useTumysContext();
  const [active, setActive] = useState(1);

  const data = [
    {
      id: 1,
      src: img2,
      type: "image",
      text: "Show-unit",
    },
    {
      id: 2,
      src: img1,
      type: "image",
      text: "Facilities",
    },
    {
      id: 3,
      src: img3,
      type: "image",
      text: "Whole Project",
    },
  ];
  return (
    <Wrapper>
      <div className="text_container">
        <div className="text">
          <h2>
            {english
              ? "CGI Photography & Videography"
              : "Chụp Ảnh/Quay Phim Tổng Thể Dự Án"}
          </h2>
          {/* <h1> & Videography</h1> */}

          <p>
            {english
              ? " To bring investor's idea to life, Goku provides high-quality videos and/or photos of the areas surrounding the real-estate project, which are subsequently layered into the project's 3D videos or photos. We give home-buyers the most realistic and immersive view of the project in its embryonic stage. "
              : "Kame với kinh nghiệm chụp trên 100 công trình toàn quốc, chúng tôi khẳng định sẽ mang lại một bộ ảnh công trình đẹp nhất cho Quý khách."}
          </p>
          {!english ? (
            <p>
              <span>*Giai đoạn thực hiện: </span>Giai đoạn diễn hoạ hình ảnh và
              làm phim dự án
            </p>
          ) : (
            <p>This service is provided during the 3D modeling phase.</p>
          )}
          {/* <p>Từ  <span>6,900,000 đ</span></p> */}
          {/* <button onClick={()=>{
               document.querySelector(".fb_customer_chat_bounce_out_v2").classList.remove("fb_customer_chat_bounce_in_from_left");
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.maxHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.minHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.height="300px";


            }} role="button" className='btn-price'>Xem Báo giá</button> */}
          {/* <Button text="Chat ngay" margin="0 auto 20px" /> */}
        </div>

        <ul className="control">
          {data.map((item, index) => {
            return (
              <li
                key={index}
                className={item.id === active && "active"}
                onClick={() => setActive(item.id)}
              >
                {item.text}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="img-container">
        {/* <div className='img-box'>

                <img alt="Project Image" src={item.src} />
                </div> */}
        {data.map((item, index) => {
          return (
            <>
              {item.id === active && (
                <>
                  {item.type === "image" ? (
                    <div key={index} className="img-box">
                      <img
                        style={{ width: "100%" }}
                        alt="Project Image"
                        src={item.src}
                      />
                    </div>
                  ) : (
                    <div key={index} className="video-box">
                      <div className="video">
                        <video autoPlay muted loop src={item.src} />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          );
        })}
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  /* height: 460px; */
  background-color: white;
  padding: 40px 40px 80px;
  border-radius: 16px;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;

  /* grid-gap: 30px; */
  /* margin-bottom: 60px; */
  .text_container {
    .text {
      h2 {
        font-family: SF-Pro-Display-Bold;
        width: 85%;
        font-size: 28px;
        margin: 0 auto 16px;
      }
      p {
        font-size: 16px;
        font-family: SF-Pro-Display-Regular;
      }
      p:nth-child(3) {
        margin-bottom: 20px;
        span {
          font-family: SF-Pro-Display-Bold;
          background: -webkit-linear-gradient(to right, #c21500, #ffc500);
          background: linear-gradient(to right, #c21500, #ffc500);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .btn-price {
        align-items: center;
        appearance: none;
        background-color: #fcfcfd;
        border-radius: 4px;
        border-width: 0;
        box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
          rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
        box-sizing: border-box;
        color: #36395a;
        cursor: pointer;
        display: inline-flex;
        font-family: "JetBrains Mono", monospace;
        height: 48px;
        justify-content: center;
        line-height: 1;
        list-style: none;
        overflow: hidden;
        padding-left: 16px;
        padding-right: 16px;
        position: relative;
        text-align: left;
        text-decoration: none;
        transition: box-shadow 0.15s, transform 0.15s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        will-change: box-shadow, transform;
        font-size: 18px;
        margin-bottom: 20px;
      }

      .btn-price:focus {
        box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
          rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
      }

      .btn-price:hover {
        box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
          rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
        transform: translateY(-2px);
      }

      .btn-price:active {
        box-shadow: #d6d6e7 0 3px 7px inset;
        transform: translateY(2px);
      }
    }

    .control {
      width: 100%;
      /* height: 100%; */
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 20px;
      padding-left: 0;
      li {
        font-family: SF-Pro-Display-Bold;
        color: #888888;
        font-size: 14px;
        cursor: pointer;
      }
      li.active {
        background: linear-gradient(90.07deg, #2eee86 3.12%, #26beee 92.02%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .img-container {
    /* height: 75%; */
    /* height: 300px; */
    width: 100%;
    border-radius: 16px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
    .shadow {
      width: 100%;
      /* height: 100%; */
      background-color: #f2f2f2;
      /* position: absolute; */
      top: -5%;
      right: -3%;
      border-radius: 16px;
    }
    .img-box {
      width: 100%;
      /* height: 100%; */
      /* position: absolute; */
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 99;
      img {
        border-radius: 16px;
      }
    }
  }

  @media screen and (max-width: 596px) {
    /* padding: 30px 30px 30px; */
  }
  @media screen and (max-width: 456px) {
    /* padding: 30px 30px 30px; */
  }
  @media screen and (max-width: 360px) {
    /* padding: 30px 30px 30px; */
  }
  @media screen and (max-width: 290px) {
    padding: 20px 10px 10px;
    /* height: 280px; */
    .text {
      h2 {
        font-family: SF-Pro-Display-Bold;
        width: 85%;
        font-size: 20px;
        margin: 0 auto 12px;
      }
      p {
        font-size: 10px;
        font-family: SF-Pro-Display-Regular;
      }
      p:nth-child(3) {
        margin-bottom: 12px;
        span {
          font-family: SF-Pro-Display-Bold;
        }
      }
    }
  }
`;

export default Chupanhcongtrinhmobile;
