import React from "react";
import styled from "styled-components";
import ipad from "../../assets/images/ipad.png";

function SingleFeature({ data, active, side, layout, setActive }) {
  return (
    <Wrapper
      gradient1={data.gradientColor1}
      gradient2={data.gradientColor2}
      side={side}
      layout={layout}
    >
      {side ? (
        <>
          <div className="demo">
            <div className="img-box">
              <img alt="IPAD" src={ipad} />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${data.src ? data.src : data.submenu[active - 1].src}"
          controls
        />,
      `,
              }}
              className="video"
            ></div>
            {/* <div className="video">
         
          <video src={data.src ? data.src : data.submenu[active-1].src} autoPlay loop muted/>
        </div> */}
          </div>

          <div className="info side">
            <h1>{data.header}</h1>
            <p>{data.info}</p>

            {data.submenu && (
              <div className="option">
                {data.submenu.map((itemsubmenu, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setActive(itemsubmenu.id)}
                      className={`${
                        active === itemsubmenu.id
                          ? "single-option active"
                          : "single-option"
                      }`}
                    >
                      {data.iconactive}
                      <p>{itemsubmenu.text}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="info">
            <h1>{data.header}</h1>
            <p>{data.info}</p>

            {data.submenu && (
              <div className="option">
                {data.submenu.map((itemsubmenu, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setActive(itemsubmenu.id)}
                      className={`${
                        active === itemsubmenu.id
                          ? "single-option active"
                          : "single-option"
                      }`}
                    >
                      <p>{itemsubmenu.text}</p>
                      {data.iconactive}
                      {/* {active===itemsubmenu.id && data.iconactive } */}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="demo">
            <div className="img-box">
              <img alt="IPAD" src={ipad} />
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${data.src ? data.src : data.submenu[active - 1].src}"
          controls
        />,
      `,
              }}
              className="video"
            ></div>
          </div>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => {
    if (props.layout) {
      return "62% 38%";
    } else {
      return "38% 62%";
    }
  }};
  height: 700px;
  padding: 100px;

  /* grid-gap: 50px; */

  .info {
    padding: 0 40px;
    text-align: right;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h1 {
      font-family: SF-Pro-Display-Bold;
      color: white;

      width: 95%;

      font-size: 40px;
      margin-bottom: 30px;
    }
    & > p {
      font-family: SF-Pro-Display-Light;
      width: 100%;

      color: #f2f2f2;
      /* margin-right: 40px; */
      width: 100%;
      /* margin-left: auto; */
      font-size: 18px;
      margin-bottom: 30px;
      /* font-weight: 300; */
      /* font-family: SF-Pro-Display-Bold; */
    }
    .option {
      /* margin-right: 40px; */
      width: 100%;
      /* margin-left: auto; */
      text-align: ${(props) => {
        if (props.layout) {
          return "right";
        } else {
          return "left";
        }
      }};
      .single-option {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        justify-content: ${(props) => {
          if (props.layout) {
            return "flex-start";
          } else {
            return "flex-end";
          }
        }};
        /* margin-left: auto; */
        /* margin-right: 40px; */
        cursor: pointer;

        p {
          color: white;
          margin: 0;
          font-size: 20px;

          font-family: SF-Pro-Display-Medium;
        }
        svg {
          /* display: none; */
          visibility: hidden;
          /* margin-bottom: ; */
          margin-right: ${(props) => {
            if (props.layout) {
              return "12px";
            } else {
              return "0";
            }
          }};

          margin-left: ${(props) => {
            if (props.layout) {
              return "0px";
            } else {
              return "12px";
            }
          }};
        }
      }
      .single-option.active {
        p {
          background-color: ${(props) => props.gradient1};
          background-image: ${(props) => {
            return `
      linear-gradient(
            180deg,
            ${props.gradient1},
            ${props.gradient2}
          )
      `;
          }};

          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
        }
        svg {
          visibility: visible;
        }
      }
    }
  }
  .info.side {
    align-items: flex-start;
    text-align: left;
    p {
      text-align: left;
    }
  }

  .demo {
    position: relative;
    /* width: 100%; */
    /* padding: 40px; */
    height: 100%;
    width: 100%;
    .img-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      /* bottom: 0; */
      right: 0;
      /* height: 100%; */
    }
    img {
      width: 100%;
      height: 100%;
    }
    .video {
      position: absolute;
      padding: 30px;

      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      right: 0;
      /* transform: translateX(50%);
      transform: translateY(50%); */
      z-index: 100;

      div {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
      }

      video {
        /* position: relative;
  left: 0;
  top: 0;
  opacity: 1; */
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }
  @media screen and (max-width: 1396px) {
    height: 600px;
    padding: 80px;
  }
  @media screen and (max-width: 1024px) {
    height: 450px;
    padding: 40px 10px;
    .info {
      padding: 0 25px;
      h1 {
        width: 100%;
        font-size: 36px;
        margin-bottom: 24px;
      }
      p {
        font-size: 17px;
      }
      .option {
        .single-option {
          p {
            font-size: 18px;
          }
        }
      }
    }
    .demo {
      .video {
        padding: 20px;
      }
    }
  }
  @media screen and (max-width: 796px) {
    height: 430px;
    padding: 40px 10px;
    .info {
      padding: 0 20px;
      h1 {
        width: 100%;
        font-size: 34px;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        margin-bottom: 22px;
      }
      .option {
        .single-option {
          p {
            font-size: 17px;
          }
        }
      }
    }
    .demo {
      height: 90%;
      .video {
        padding: 15px;
      }
    }
  }
  @media screen and (max-width: 650px) {
    /* grid-template-columns: 1fr; */

    /* height: auto; */
    height: auto;
    text-align: center;
    /* height: 800px; */
    /* gap: 40px; */
    display: flex;
    justify-content: center;

    flex-direction: ${(props) => {
      if (props.layout) {
        return "column-reverse";
      } else {
        return "column";
      }
    }};
    .info {
      text-align: center !important;
      min-height: 55%;
      .option {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        margin-bottom: 20px;
        .single-option {
          justify-content: center;
          align-items: center;
          svg {
            display: none;
            visibility: hidden;
          }
        }
        .single-option.active {
          /* position: relative; */
          display: flex;

          justify-content: center;
          /* align-items: center; */
          flex-direction: ${(props) => {
            if (props.layout) {
              return "column-reverse";
            } else {
              return "column";
            }
          }};
          svg {
            /* position: absolute; */
            margin: 0 auto;
            /* display: inline; */
            display: block;
            visibility: visible;

            /* transform: rotate(-90deg) translateX(-50%); */
            transform: ${(props) => {
              if (props.layout) {
                return "rotate(-90deg)";
              } else {
                return "rotate(90deg)";
              }
            }} !important;
          }
        }
      }
    }
    .demo {
      min-height: 270px;
      /* padding: 20px; */
      .video {
        video {
          border-radius: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 496px) {
    padding: 30px 10px;
    .info {
      padding: 0 10px;
      h1 {
        font-size: 30px;
        margin-bottom: 16px;
      }
      & > p {
        font-size: 14px;
        text-align: center !important;
      }
      .option {
        margin-bottom: 10px;
        .single-option {
          p {
            margin: 0 auto;
            display: block;
            font-size: 12px;
          }
          svg {
            width: 10px;
            height: 12px;
          }
        }
      }
    }
    .demo {
      min-height: 210px;
      .video {
        video {
          border-radius: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 324px) {
    padding: 20px 10px;

    .info {
      padding: 0 8px;
      h1 {
        font-size: 28px;
        margin-bottom: 12px;
      }
      & > p {
        font-size: 12px;
        text-align: center !important;
      }
      .option {
        margin-bottom: 8px;
        .single-option {
          p {
            margin: 0 auto;
            display: block;
            font-size: 10px;
          }
          svg {
            width: 8px;
            height: 10px;
          }
        }
      }
    }
    .demo {
      min-height: 180px;

      .video {
        video {
          border-radius: 8px;
        }
      }
    }
  }
`;

export default SingleFeature;
