import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import background from "../../assets/images/backgroundintrowork.png";
import sologanblack from "../../assets/images/gokulogoblack.png";

import sologan from "../../assets/images/gokulogoblack.png";
import iconheader from "../../assets/images/iconheaderhomeintro.png";
import { useTumysContext } from "../../context/tumycontext";
import Header from "../Share/Header";
import { useMediaQuery } from "usehooks-ts";

function Intro() {
  const { english, handleLanguage, nav, handleNav } = useTumysContext();
  const matches = useMediaQuery("(max-width: 768px)");

  return (
      <Wrapper>
        <Header
          logoBlack={sologanblack}
          iconBlack={
            matches ? (
              <svg
                width="28"
                height="20"
                viewBox="0 0 36 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                  fill="#484848"
                />
              </svg>
            ) : (
              <svg
                width="36"
                height="24"
                viewBox="0 0 36 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                  fill="#484848"
                />
              </svg>
            )
          }
          fixed
          icon={
            <svg
              width="36"
              height="24"
              viewBox="0 0 36 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                fill="#484848"
              />
            </svg>
          }
          logo={sologan}
        />
      </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  /* min-height: 400px; */
  /* height: 40%; */
  /* min-height: 500px; */
  height: 100%;
  display: block;
  position: relative;
  top: 0;

  .info {
    position: absolute;
    bottom: 14%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: black;
    width: 42%;
    h1 {
      color: black;

      font-size: 60px;
      font-weight: 600;
      font-family: SF-Pro-Display-Bold;
    }
    p {
      color: black;
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 26px;
      cursor: pointer;
      a {
        color: black;
      }
    }
    button {
      width: 30%;
      height: 40px;
      padding: 8px;
      background-color: transparent;
      color: white;
      border: 0.7px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      cursor: pointer;
      margin-left: 50%;
      transform: translateX(-50%);
      gap: 8px;
      span {
        font-weight: 700;
      }
      svg {
        width: 15px;
      }
    }
  }
`;

export default Intro;
