import React from "react";
import styled from "styled-components";
function Buttonchatngay() {
  return (
    <Wrapper>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://zalo.me/0915444235"
      >
        <button
        // onClick= {()=>{
        //              document.querySelector(".fb_customer_chat_bounce_out_v2").classList.remove("fb_customer_chat_bounce_in_from_left");
        //              document.querySelector(".fb_customer_chat_bounce_out_v2").style.maxHeight="calc(100% - 84px)";
        //              document.querySelector(".fb_customer_chat_bounce_out_v2").style.minHeight="calc(100% - 84px)";
        //              document.querySelector(".fb_customer_chat_bounce_out_v2").style.height="300px";
        //         }
        //         }
        >
          Chat ngay
        </button>
      </a>
    </Wrapper>
  );
}
const Wrapper = styled.p`
  width: 100%;
  button {
    cursor: pointer;
    /* display: inline-block; */
    display: block;
    text-align: center;
    white-space: nowrap;
    font-size: 17px;
    line-height: 1.17648;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-family: SF-Pro-Display-Bold;
    min-width: 28px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 980px;
    background: #0071e3;
    color: #fff;
    border: none;
    margin: 0 auto !important;
    &:hover {
      opacity: 0.9;
    }
  }
`;

export default Buttonchatngay;
