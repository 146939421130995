import React from 'react'
import styled from "styled-components";
import background from "../../assets/images/backgroundintrowork.png";
import sologanblack from "../../assets/images/gokulogoblack.png";

import sologan from "../../assets/images/sologanintro.png";
import iconheader from "../../assets/images/iconheaderhomeintro.png";
import Header from '../Share/Header';
import Body from './Body';

function Intro({data}) {
  console.log(data)
  return (
    <div
    style={{
      backgroundImage: `url(${data.image})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center"
    }}
    className="introproductionwork show"
  >
   

    <Wrapper>
      <Header logoBlack={sologanblack} iconBlack={
<svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z" fill="#484848"/>
</svg>} fixed icon={<svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z" fill="white"/>
</svg>} logo={sologan} />

{/* <Body /> */}

     
    </Wrapper>
   

  
   


  </div>
  )
}

const Wrapper=styled.div`
 width: 100vw;
  /* min-height: 400px; */
  /* height: 40%; */
  /* min-height: 500px; */
  height: 40vh;


  display: block;


  position: relative;
 .info {
    position: absolute;
    bottom: 18%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    width: 42%;
    h1 {
      color: white;
      font-size: 60px;
      font-weight: 600;
      font-family: SF-Pro-Display-Bold;
    }
    p {
      color: white;
      font-size: 20px;
      line-height: 26px;
     
      
    }
    button{
        width: 30%;
        height: 40px;
        padding: 8px;
        background-color: transparent;
        color: white;
        border: 0.7px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        cursor: pointer;
        margin-left: 50%;
        transform: translateX(-50%);
        gap: 8px;
        span{
        font-weight: 700;
      }
        svg{
            width: 15px;
        }
    }
  }
`


export default Intro