import axios from 'axios';
// import { getUserToLocalStorage } from './localStorage';
// import https from 'https-browserify';
// import https from 'https'
const customFetch = axios.create({
 
  baseURL: 'https://api.goku.agency/api',

});

// customFetch.interceptors.request.use((config)=>{
  
  
//   config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
   

//   return config
// })

export default customFetch;