import React, { useEffect, useState } from 'react'
import Footer from '../components/Share/Footer'
import Body from '../components/Gokuverseservice/Body'
import Intro from '../components/Gokuverseservice/Intro'
import { useTumysContext } from '../context/tumycontext';
import Nav from '../components/Share/Nav';





function Gokuverseservice() {
  const { updateMeta,english, handleLanguage, nav, handleNav } = useTumysContext();

 
// useEffect(()=>{
//   handleNav()
// },[])
useEffect(()=>{
  // updateMeta(`KAME360`,`Explore our production page to see our innovative products and exceptional services. Our team of experts is dedicated to delivering high-quality solutions that meet your needs and exceed your expectations. Learn more about our state-of-the-art production process and why we are the best choice for your production needs.`,"https://goku.agency/img/thumb-min.png",`https://goku.agency/service/kame360`)
},[])
  
  return (
    <>
    {nav?(
      <Nav />
    ):(

    <div className='page-goku'>
        <Intro />

        

        <Body />
       

        {/* <Footer /> */}
    </div>
    )}
    </>
  )
}

export default Gokuverseservice