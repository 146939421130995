import React from 'react'
import styled from 'styled-components'
import actual1 from "../../assets/images/actual1.png"
import img2 from "../../assets/images/test2.png"
import img3 from "../../assets/images/test3.jpeg"
import scape1 from "../../assets/images/cityscape1.jpg"
import scape2 from "../../assets/images/cityscape1.png"

import scape3 from "../../assets/images/cityscape2.jpg"
import { useTumysContext } from '../../context/tumycontext'





function Cityscape() {
  const { english,handleLanguage,nav,handleNav} = useTumysContext();

  return (
    <Wrapper>
        <div className='left'>
          

            <div className='left-img'>
                <div className='img-box'>

                <img alt="Cityscape Image" src={scape2} style={{width: "100%",height: "100%"}} />
                </div>

                <div className='shadow'>
                <img alt="City Scape" src={scape1} style={{width: "100%",height: "100%"}} />

                </div>
            </div>


        </div>
        <div className='right'>
            <div className='text'>
            {english ? (
                <>
                <h1>Cityscape</h1>
            <h1>Photography</h1>

            <p>
            To show the beauty of the city, Goku provides cityscape photography services, bringing comprehensive and realistic views to the customers.
            </p>
                </>
            ):
            <>
             <h1>Chụp ảnh/quay phim Cityscape</h1>
            

            <p>
            Goku chụp ảnh hoặc quay phim Cityscape để thấy được vẻ đẹp của các thành phố.
            </p>
            </>
            }
          
            </div>

            <div className='img'>
              


            <img alt="Cityscape Image" src={scape3} style={{width: "100%",height: "100%"}} />
                
            

            </div>
        </div>
        
    </Wrapper>
  )
}
const Wrapper = styled.div`
width: 100%;
display: grid;
grid-template-columns: 55% 45%;
height: 750px;
background-color: white;
padding: 30px 30px 30px;
border-radius: 16px ;
margin-top: 100px;
margin-bottom: 300px;

/* grid-gap: 30px; */
/* margin-bottom: 60px; */

.left{
    
   
    .left-img{
        height: 100%;
        border-radius: 16px ;
        position: relative;
        /* box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
        
        .img-box{
            width: 110%;
            height: 55%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        text-align: right;
        /* margin-bottom: 20px; */
        img{
            border-radius: 16px ;

        }
        }
    
       
    
    }
    .shadow{
        width: 100%;
        height: 40%;
        /* background-color: #F2F2F2; */
        position: absolute;
        /* top: -5%; */
        /* right: -3%; */
        bottom: 0;
        border-radius: 16px ;
        z-index: 99;
        img{
            border-radius: 16px ;

        }

    


    }
}
.right{
    /* width: 100%; */
    position: relative;
    height: 100%;
    width: 100%;
.text{
    margin-left: 30px;
    height: 50%;
   
    h1{
            color: black;
            font-family: SF-Pro-Display-Bold;
            font-size: 42px;
            line-height: 50px;
        }
    p{
        font-size: 22px;
        font-family: SF-Pro-Display-Light;
    }
    p:nth-child(1){
        margin-bottom: 40px;
    }
}
.img{
    height: 50%;
    width: calc(100% - 30px);
    /* margin-right: auto; */
    margin-left: 30px;
    /* background-color: transparent; */
    position: absolute;
    bottom: -10%;
        
        /* left: 5%; */
    
        /* top: 12%; */
        img{
            border-radius: 16px;
        }
   
}
}

@media screen and (max-width: 1024px) {
    height: 570px;
    padding: 20px;
  .right{
    .text{
        margin-left: 20px;
        h1{
            font-size: 36px;
                line-height: 40px;
                /* width: 100%; */
        }
        p{
            font-size: 18px;
        }
    }
    .img{
        width: calc(100% - 20px);
    /* margin-right: auto; */
    margin-left: 20px;
    }
  }
  .left{
    .left-img{
        .img-box{
            width: 115%;
        }
        .shadow{

        }
    }
  }
    
}
@media screen and (max-width: 796px) {
    height: 500px;

    .right{
    .text{
        h1{
            font-size: 34px;
                line-height: 38px;
                /* width: 100%; */
        }
        p{
            font-size: 16px;
        }
    }
  }
  .left{
    .left-img{
        .img-box{
            width: 112%;
        }
    }
  }
    
}
@media screen and (max-width: 650px) {
    .right{
    .text{
        h1{
                font-family: SF-Pro-Display-Bold;
        /* width: 85%; */
        font-size: 28px;
        /* margin: 0 auto 16px; */
        line-height: 34px;
            }
    }
  }
    
}
@media screen and (max-width: 496px) {
    margin-top: 60px;
    
}
@media screen and (max-width: 300px) {
    .right{
    .text{
        h1{
                font-family: SF-Pro-Display-Bold;
        /* width: 85%; */
        font-size: 20px;
        /* margin: 0 auto 16px; */
        line-height: 24px;
            }
            p{
                font-size: 10px;
            }
    }
  }
    
}

`

export default Cityscape