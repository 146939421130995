import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
function Info({data}) {
    let {project} = useParams()

    // console.log(data.position)
    if(data.position){
        return (
    
            <Wrapper>
                   <p className='category'>WORKS / {project==="goku-product"?"Goku Production":"Gokuverse"}</p>
                   <div className='container'>
        
                <div className='right'>
                   <h1>{data.name}</h1>
                    <p>{data.description}</p>
        
                </div>
                <div className='left'>
                  <div className='info'><p>CLIENT</p>
                      <p>{data.position.client == undefined ? "": data.position.client}</p>
                  </div>
                  <div className='info'><p>LOCATION</p>
                      <p>{data.position.location == undefined ? "": data.position.location}</p>
                  </div>
                  <div className='info'><p>EXCUTION TIME</p>
                      <p>{data.position.execution_time == undefined ? "" : data.position.execution_time}</p>
                  </div>
        
                  
        
                </div>
                <div className='leftmobile'>
                    <div className='name-info'>
                    <p>CLIENT</p>
                    <p>LOCATION</p>
                    <p>EXCUTION TIME</p>
                    </div>
                    <div className='dot'>
                        <p>:</p>
                        <p>:</p>
                        <p>:</p>
        
                    </div>
                    <div className='info'>
                    <p>{data.position.client == undefined ? "": data.position.client}</p>
                    <p>{data.position.location == undefined ? "": data.position.location}</p>
                    <p>{data.position.execution_time == undefined ? "" : data.position.execution_time}</p>
                  
        
        
                   
                    </div>
                 
        
                  
        
                </div>
                   </div>
            </Wrapper>
          )
    }
  
}
const Wrapper = styled.div`

text-align: center;
.category{
        color: #484848;
        text-transform: capitalize;
    }
.container{
/* display: grid; */
/* grid-template-columns: 50% 50%; */
width: 100%;


}
.left{
    /* text-align: left; */
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin-top: 30px;
     width: 80%;
     margin: 30px auto 0;
    .info{

        p{
            margin-bottom: 12px;
        }
        p:nth-child(2){
            color: black;
            font-family: SF-Pro-Display-Bold;
        }
    }

    
   
   
   
 
}

.right{
    p{  color: #484848;
        font-size: 18px;
        line-height: 36px;
        width: 65%;
        margin: 0 auto;
    }
    h1{
        font-family: SF-Pro-Display-Bold;
        color: black;
        font-size: 48px;
        line-height: 52px;
        margin-bottom: 30px;
    }
}
.leftmobile{
    display: none;
}

@media screen and (max-width: 1024px) {
/* padding: 40px 100px; */
  .right{

  }
}
@media screen and (max-width: 796px) {
/* padding: 40px 60px; */
  
}
@media screen and (max-width: 596px) {
/* padding: 40px 40px; */
.left{
    grid-template-columns: 1fr;
    text-align: center;
    display: none;
    .info{
        /* display: flex;
        margin: 0 auto; */
       
    }
}
.leftmobile{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.1fr 1fr;
    grid-gap: 8px;
    margin-top: 20px;
    .name-info{
        text-align: right;
        p{
            color: black;
            font-family: SF-Pro-Display-Bold;
        }
    }
    .dot{
        text-align: center;
    }
    .info{
        text-align: left;
    }
}
  
}
@media screen and (max-width: 424px) {
/* padding: 40px 20px; */
.right{
    h1{
        font-size: 32px;
        line-height: 36px;

    }
    p{
        font-size: 16px;
        width: 90%;
    }
}


  
}
@media screen and (max-width: 324px) {
    .right{
    h1{
        font-size: 28px;
        line-height: 32px;

    }
    p{
        font-size: 14px;
        width: 100%;
    }
}}
`

export default Info