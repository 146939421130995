import React, { useEffect } from 'react'
import Body from '../components/Gokuversework/Body';
import Intro from '../components/Gokuversework/Intro'
import Nav from '../components/Share/Nav';
import { useTumysContext } from '../context/tumycontext';

function Gokuworkverse() {
  
    const { english,updateMeta, handleLanguage, nav, handleNav,fetchProject,fetchFilter,VerseProject } = useTumysContext();

  const {filterAPI,currentFilter,currentPage,currentArray,perPage} = VerseProject;



  useEffect(()=>{
    fetchFilter(`${english?"en":"vi"}`,"goku-verse")

    if(currentFilter === "Tất cả"){
      fetchProject(`${english?"en":"vi"}`,"goku-verse",0,perPage,currentPage);
      return;
    }

    filterAPI.forEach((item) => {
      // console.log(item.name)
        //  console.log(item.name)
         if(item.name === currentFilter){
    
        
          fetchProject(`${english?"en":"vi"}`,"goku-verse",item.id,perPage,currentPage);
          
        

         }
         
         
    });
    

    
  },[currentPage,english,currentFilter])

  useEffect(() => {
    // updateMeta(`KAME360`,`Explore our production page to see our innovative products and exceptional services. Our team of experts is dedicated to delivering high-quality solutions that meet your needs and exceed your expectations. Learn more about our state-of-the-art production process and why we are the best choice for your production needs.`,"https://goku.agency/img/thumb-min.png",`https://goku.agency/work/gokuverse`)
 }, []);

  // console.log(currentFilter)
  return (
 
    <>
     {nav?(<Nav />):(
      

      <>
      
      <Intro />
      <Body />
      </>

     )}
    
    </>
  )
}

export default Gokuworkverse