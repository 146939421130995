import React, { useEffect } from "react";
import styled from "styled-components";
import docu1 from "../../assets/images/docu1.png";
import docu2 from "../../assets/images/docu2.png";
import docu3 from "../../assets/images/documentation.jpeg";
import { useTumysContext } from "../../context/tumycontext";
import img from "../../assets/images/haukylanding.png";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import after_1 from "../../assets/images/01_After.jpg";
import before_1 from "../../assets/images/01_Before.jpg";
import after_2 from "../../assets/images/02_After.jpg";
import before_2 from "../../assets/images/02_Before.jpg";
import after_3 from "../../assets/images/03_After.jpg";
import before_3 from "../../assets/images/03_Before.jpg";
import after_4 from "../../assets/images/04_After.jpg";
import before_4 from "../../assets/images/04_Before.jpg";

function Haukynew() {
  const { english, handleLanguage, nav, handleNav } = useTumysContext();
  const FIRST_IMAGE_1 = {
    imageUrl: after_1,
  };
  const SECOND_IMAGE_1 = {
    imageUrl: before_1,
  };
  const FIRST_IMAGE_2 = {
    imageUrl: after_2,
  };
  const SECOND_IMAGE_2 = {
    imageUrl: before_2,
  };
  const FIRST_IMAGE_3 = {
    imageUrl: after_3,
  };
  const SECOND_IMAGE_3 = {
    imageUrl: before_3,
  };
  const FIRST_IMAGE_4 = {
    imageUrl: after_4,
  };
  const SECOND_IMAGE_4 = {
    imageUrl: before_4,
  };

  useEffect(() => {
    const beforeImage = document.querySelector(
      ".image-container .before-after-slider__first-photo-container img"
    );
    const afterImage = document.querySelector(
      ".image-container .before-after-slider__second-photo-container img"
    );
    if (beforeImage !== null && afterImage !== null) {
      beforeImage.setAttribute("alt", "Hình ảnh trước hậu kì");
      afterImage.setAttribute("alt", "Hình ảnh sau hậu kì");
    }
    // console.log(beforeImage)
    // document.querySelector(".image-container .before-after-slider__first-photo-container img").setAttribute("alt","Hình ảnh trước hậu kì");
    // document.querySelector(".image-container .before-after-slider__second-photo-container img").setAttribute("alt","Hình ảnh sau hậu kì");
  }, []);
  return (
    <Wrapper>
      <div className="info">
        <img alt="Photoshop" src={img} />
        <h2>Hậu kỳ</h2>
        <p>
          Với tiền thân có trên 10 năm kinh nghiệm trong lĩnh vực hậu kỳ hình
          ảnh Bất động sản, Goku đảm bảo xử lý được mọi vấn đề về hình ảnh của
          Quý khách.
        </p>
      </div>
      <div className="image-container-wrapper">
        <div className="image-container">
          <ReactBeforeSliderComponent
            firstImage={FIRST_IMAGE_1}
            secondImage={SECOND_IMAGE_1}
          />
        </div>
        <div className="image-container">
          <ReactBeforeSliderComponent
            firstImage={FIRST_IMAGE_2}
            secondImage={SECOND_IMAGE_2}
          />
        </div>
        <div className="image-container">
          <ReactBeforeSliderComponent
            firstImage={FIRST_IMAGE_3}
            secondImage={SECOND_IMAGE_3}
          />
        </div>
        <div className="image-container">
          <ReactBeforeSliderComponent
            firstImage={FIRST_IMAGE_4}
            secondImage={SECOND_IMAGE_4}
          />
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  /* display: grid;
grid-template-columns: 65% 35%; */
  /* height: 550px; */
  /* margin-top: 40px; */
  /* margin-bottom: 150px; */

  .info {
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    img {
      height: 100px;
      margin-bottom: 20px;
    }
    h2 {
      color: black;
      font-family: SF-Pro-Display-Bold;
      font-size: 42px;
      margin-bottom: 30px;
    }
    p {
      font-size: 22px;
      font-family: SF-Pro-Display-Light;
      text-align: center;
      span {
        color: #10505b;
        font-family: SF-Pro-Display-Bold;
      }
    }
  }

  .image-container-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    margin-top: 60px;
  }

  .image-container {
    /* border-radius: 12px; */
    margin: 30px;
  }

  @media screen and (max-width: 1024px) {
    .info {
      h2 {
        font-size: 36px;
      }
      p {
        p {
          font-size: 18px;
          font-family: SF-Pro-Display-Light;
          text-align: center;
        }
      }
    }
  }
  @media screen and (max-width: 896px) {
    .info {
      h2 {
        font-size: 34px;
      }
      p {
        /* color: black; */
        font-size: 22px;
      }
    }

    .container {
      grid-template-columns: 1 1 1 1;
    }

    .image-container-wrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: space-between;
      margin-top: 60px;
    }
  }
  @media screen and (max-width: 796px) {
    .info {
      h2 {
        font-size: 34px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 660px) {
  }
  @media screen and (max-width: 596px) {
    margin-top: 220px;
    .info {
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 456px) {
    .info {
      h2 {
        font-size: 26px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 360px) {
    .info {
      h2 {
        font-size: 22px;
        margin-bottom: 18px;
      }
      p {
        font-size: 10px;
        font-family: SF-Pro-Display-Regular;
        span {
          color: #e36942;
          font-family: SF-Pro-Display-Bold;
        }
      }
    }
    .image-container {
      margin-top: 20px;
    }
  }
`;

export default Haukynew;
