import React, { useEffect } from 'react'
import styled from 'styled-components'
import sologan from "../assets/images/sologanintro.png";
import backgroundnav from "../assets/images/backgroundnav.png";
import Nav from '../components/Share/Nav';
import { Helmet } from 'react-helmet-async';
import sologanblack from "../assets/images/gokulogoblack.png";


import { useTumysContext } from '../context/tumycontext';
import Header from '../components/Share/Header';
import Body from '../components/Contact/Body';



function Contactpage() {


  const { nav} = useTumysContext();


useEffect(() => {


// updateMeta(`CONTACT`,`Get in touch with us today through our contact page. Whether you have a question, comment, or suggestion, our team is here to help. We value your feedback and strive to provide excellent customer service. Contact us now for prompt and efficient assistance.`,"https://goku.agency/img/thumb-min.png",`https://goku.agency/contact`)





}, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="https://goku.agency/img/favicon.png" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content="#000000" />

        <meta
          data-rh="true"
          name="description"
          content="Get in touch with us today through our contact page. Whether you have a question, comment, or suggestion, our team is here to help. We value your feedback and strive to provide excellent customer service. Contact us now for prompt and efficient assistance."
        />

        <title>CONTACT</title>
        <meta data-rh="true" name="robots" content="all" />

        <meta data-rh="true" property="og:title" content="CONTACT" />
        <meta
          data-rh="true"
          property="og:description"
          content="Get in touch with us today through our contact page. Whether you have a question, comment, or suggestion, our team is here to help. We value your feedback and strive to provide excellent customer service. Contact us now for prompt and efficient assistance."
        />
        <meta data-rh="true" property="og:type" content="website" />
        <meta
          data-rh="true"
          property="og:url"
          content="https://goku.agency/contact"
        />
        <meta
          data-rh="true"
          property="og:image"
          content="https://goku.agency/img/rsz_goku.png"
        />
        <meta data-rh="true" property="og:image:alt" content="Goku" />
        <meta data-rh="true" property="og:image:width" content="1200" />
        <meta data-rh="true" property="og:image:height" content="628" />
        <link rel="canonical" href="https://goku.agency/contact" />
      </Helmet>
      {nav ? (
        <Nav />
      ) : (
        <div
          style={{
            backgroundImage: `url(${backgroundnav})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="contactpage show"
        >
          <Header
            logoBlack={sologanblack}
            iconBlack={
              <svg
                width="36"
                height="24"
                viewBox="0 0 36 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                  fill="#484848"
                />
              </svg>
            }
            fixed
            icon={
              <svg
                width="36"
                height="24"
                viewBox="0 0 36 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                  fill="white"
                />
              </svg>
            }
            logo={sologan}
          />

          <Body />
        </div>
      )}
    </>
  );
}


export default Contactpage