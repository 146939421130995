import React from "react";
import styled from "styled-components";
import img1 from "../../assets/images/landingchuyennghiep.jpg";
import img2 from "../../assets/images/test2.png";
import img3 from "../../assets/images/test3.jpeg";
import { useTumysContext } from "../../context/tumycontext";

function Chuyennghiepmobile() {
  const { english, handleLanguage, nav, handleNav } = useTumysContext();

  return (
    <Wrapper>
      <div className="text">
        <div className="text">
          <h2>
            {english ? "CGI Photography & Videography" : "Chuyên nghiệp."}
          </h2>
          {/* <h1> & Videography</h1> */}

          <p>
            Goku là một Công ty chuyên nghiệp nên có các phòng ban để phục vụ
            khách hàng trên từng bước triển khai, giúp cho việc triển khai sản
            xuất hình ảnh diễn ra hiệu quả nhất.
          </p>
        </div>
      </div>

      <div className="img-container">
        <div className="img-box">
          <img alt="CGI Image" src={img1} style={{ width: "100%" }} />
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  /* height: 460px; */
  background-color: white;
  padding: 40px 40px 80px;
  border-radius: 16px;
  text-align: center;
  margin-top: 150px;

  /* grid-gap: 30px; */
  /* margin-bottom: 60px; */
  .text {
    h2 {
      font-family: SF-Pro-Display-Bold;
      width: 85%;
      font-size: 28px;
      margin: 0 auto 16px;
    }
    p {
      font-size: 16px;
      font-family: SF-Pro-Display-Regular;
      span {
        color: #e36942;
        font-family: SF-Pro-Display-Bold;
      }
    }
    p:nth-child(3) {
      margin-bottom: 20px;
      span {
        font-family: SF-Pro-Display-Bold;
      }
    }
    .btn-price {
      align-items: center;
      appearance: none;
      background-color: #fcfcfd;
      border-radius: 4px;
      border-width: 0;
      box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
      box-sizing: border-box;
      color: #36395a;
      cursor: pointer;
      display: inline-flex;
      font-family: "JetBrains Mono", monospace;
      height: 48px;
      justify-content: center;
      line-height: 1;
      list-style: none;
      overflow: hidden;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;
      text-align: left;
      text-decoration: none;
      transition: box-shadow 0.15s, transform 0.15s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: nowrap;
      will-change: box-shadow, transform;
      font-size: 18px;
      margin-bottom: 20px;
    }

    .btn-price:focus {
      box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    }

    .btn-price:hover {
      box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
      transform: translateY(-2px);
    }

    .btn-price:active {
      box-shadow: #d6d6e7 0 3px 7px inset;
      transform: translateY(2px);
    }
  }

  .img-container {
    /* height: 75%; */

    /* height: 100px; */
    height: 100px;
    width: 100%;
    border-radius: 16px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
    .shadow {
      width: 100%;
      height: 100%;
      display: none;
      background-color: #f2f2f2;
      /* position: absolute; */
      top: -5%;
      right: -3%;
      border-radius: 16px;
    }
    .img-box {
      width: 100%;
      /* height: 100%; */
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 99;
      img {
        border-radius: 16px;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 596px) {
    /* padding: 30px 30px 80px; */
  }
  @media screen and (max-width: 456px) {
    /* padding: 30px 30px 30px; */
    .img-container {
      height: 100px;
    }
  }
  @media screen and (max-width: 360px) {
    /* padding: 30px 30px 30px; */
  }
  @media screen and (max-width: 290px) {
    padding: 20px 10px 10px;
    /* height: 280px; */
    .text {
      h2 {
        font-family: SF-Pro-Display-Bold;
        width: 85%;
        font-size: 20px;
        margin: 0 auto 12px;
      }
      p {
        font-size: 10px;
        font-family: SF-Pro-Display-Regular;
      }
      p:nth-child(3) {
        margin-bottom: 12px;
        span {
          font-family: SF-Pro-Display-Bold;
        }
      }
    }
  }
`;

export default Chuyennghiepmobile;
