import React, { useEffect } from 'react'
import styled from 'styled-components'
import Homeintro from '../components/Home/Homeintro'
import Homework from '../components/Home/Homework'
import Homeservice from '../components/Home/Homeservice'
import {Helmet} from "react-helmet";

import Homepartner from '../components/Home/Homepartner'
import { useTumysContext } from '../context/tumycontext'
import Nav from '../components/Share/Nav'


function Home() {

const { nav} = useTumysContext();
// document.getElementsByTagName("META")[4].content="Test 1";
//   document.getElementsByTagName("META")[7].content="Test 2";
  useEffect(() => {
  //  updateMeta("KAME","Welcome to KAME Agency, your one-stop-shop for products relating to media production for architecture, furniture and real estate. Discover our innovative solutions, exceptional quality, and outstanding customer service. Explore our website to learn more about our company, our mission, and what sets us apart. Choose Kame Agebct for all your needs.","https://goku.agency/img/thumb-min.png","https://goku.agency/home")
}, []);


  return (
    <>
     <Helmet>
     <meta charset="utf-8" />
    <link rel="icon" href="https://goku.agency/img/favicon.png" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    
    <meta data-rh="true" name="description" content="Công ty Cổ phần Goku Việt Nam - là đơn vị tiên phong trong lĩnh vực sản xuất media cho hạng mục kiến trúc, nội thất và bất động sản" />

    <title>Goku - More than words</title>
    <meta data-rh="true" name="robots" content="all" />
  
    <meta data-rh="true" property="og:title" content="Goku - More than words" />
    <meta data-rh="true" property="og:description" content="Công ty Cổ phần Goku Việt Nam - là đơn vị tiên phong trong lĩnh vực sản xuất media cho hạng mục kiến trúc, nội thất và bất động sản" />
    <meta  data-rh="true" property="og:type" content="website" />
    <meta data-rh="true" property="og:url"content="https://goku.agency/home" />
    <meta data-rh="true" property="og:image" content="https://goku.agency/img/rsz_goku.png" />
    <meta data-rh="true" property="og:image:alt" content="Goku"/>
    <meta data-rh="true" property="og:image:width" content="1200" />
    <meta data-rh="true" property="og:image:height" content="628" />
    <link rel='canonical' href='https://goku.agency/home' />


    </Helmet>
   
    {nav ? (<Nav />): (


    <Wrapper>
        <Homeintro/>
        <Homework />
        <Homeservice />
        <Homepartner />
    </Wrapper>
    )}
    </>
  )
}

const Wrapper = styled.div`

/* background-color: antiquewhite; */
/* min-height: 700px; */


`

export default Home