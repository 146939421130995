import React from 'react'
import styled from 'styled-components'
import { useTumysContext } from '../../context/tumycontext';

function Filtermobile() {
  const { english, handleLanguage, nav, handleNav,filtergokuverse, updateCurrentfiltergokuverse,currentFiltergokuverse,VerseProject,updateCurrentPageVerse } = useTumysContext();

  return (
    <Wrapper>
        {/* <h3>Filter</h3> */}
     <div className='content'>
        <h3>Filter</h3>
       <div className='container'>
       {VerseProject.filterList.map((item,index)=>{
      return (
        <p key={index} className={`${VerseProject.currentFilter===item && "active"}`} onClick={()=>{
          updateCurrentPageVerse(1)

          updateCurrentfiltergokuverse(item)
        }}>{item}</p>
      )
        })}
       </div>

       
     </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
width: 100%;
/* background-color: black; */
/* height: 100px; */
.content{
    width: 90%;
    height: 100%;
    margin: 0 auto;
    background-color: white;
    
   
    /* align-items: center; */
    /* justify-content: space-around; */
    padding: 8px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    h3{
        font-size: 18px;
        font-family: SF-Pro-Display-Bold ;
        letter-spacing: 1px;
        margin-bottom: 12px;
    }
    .container{
        display: grid;
        width: 100%;
        height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;

    }
    p{
        display: block;
        margin-bottom: 0;
        cursor: pointer;
        &:not(.active):hover{
            /* opacity: 0.8; */
        /* font-family:SF-Pro-Display-Bold ; */
        color: #131313;
        font-family:SF-Pro-Display-Medium ;



        }
        
    }
    .active{
        color: black;
        font-family:SF-Pro-Display-Bold ;

    }
}



@media screen and (max-width: 424px) {
  .content{
    width: 98%;
    margin: 0;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    p{
      font-size: 10px;
      /* width: 200px; */
      display: block;
      flex: 0 0 40%;
    }

  }
}
`

export default Filtermobile