import React from "react";
import styled from "styled-components";
import sologan from "../../assets/images/sologanintro.png";
import sologanblack from "../../assets/images/gokulogoblack.png";
import background from "../../assets/images/gokuproductionserviceintro.png";
import { useTumysContext } from "../../context/tumycontext";
import iconheader from "../../assets/images/iconheaderhomeintro.png";
import Header from "../Share/Header";

function Intro() {
  const { english, handleLanguage, nav, handleNav } = useTumysContext();

  return (
    <Wrapper>
      <Header
        logoBlack={sologanblack}
        iconBlack={
          <svg
            width="36"
            height="24"
            viewBox="0 0 36 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
              fill="#484848"
            />
          </svg>
        }
        fixed
        icon={
          <svg
            width="36"
            height="24"
            viewBox="0 0 36 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
              fill="#484848"
            />
          </svg>
        }
        logo={sologanblack}
      />

      <div className="content">
        <div className="img">
          <img alt="Background" src={background} />
        </div>
        <div className="info">
          <h1>
            Goku Pr
            <svg
              width="40"
              height="40"
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="35"
                cy="35"
                r="29"
                fill="#F4F5F6"
                stroke="url(#paint0_linear_209_688)"
                stroke-width="12"
              />
              <path
                d="M22.2734 38.2422H32V47.2422H37.9766V38.2422H47.7266V32.2656H37.9766V23.2422H32V32.2656H22.2734V38.2422Z"
                fill="url(#paint1_linear_209_688)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_209_688"
                  x1="13.4218"
                  y1="23.2326"
                  x2="54.3629"
                  y2="23.2795"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2EEE86" />
                  <stop offset="1" stop-color="#26BEEE" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_209_688"
                  x1="19.9891"
                  y1="16.9186"
                  x2="48.4699"
                  y2="16.937"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2EEE86" />
                  <stop offset="1" stop-color="#26BEEE" />
                </linearGradient>
              </defs>
            </svg>
            duction
          </h1>
          {!english ? (
            <p>
              Goku Production là dịch vụ sản xuất hình ảnh chất lượng hàng đầu
              đạt tiêu chuẩn Quốc tế cho các dự án Bất động sản.
            </p>
          ) : (
            <p>
              World-class photography and videography services for Real Estate
              Projects.
            </p>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: #f4f5f6;
  width: 100vw;
  /* min-height: 900px; */
  height: 100%;
  display: block;
  padding-bottom: 200px;
  /* padding-top: 100px; */

  position: relative;

  .content {
    width: 60%;
    margin: 0 auto;
    min-height: 500px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* margin-bottom: 100px; */
    .info {
      /* width: 100%; */
      text-align: center;
      width: 45%;

      h1 {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        /* margin: 0 auto; */
        text-align: center;
        font-family: SF-Pro-Display-Bold;
      }
      p {
        font-family: SF-Pro-Display-Light;
        /* width: 60%; */
        font-size: 20px;
      }
    }

    .img {
      position: absolute;
      width: 100%;
      left: 0;
      height: 100%;
      top: 0;
      background-color: transparent;
      /* z-index: -1; */
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 1496px) {
    .content {
      .info {
        width: 80%;
        h1 {
          /* font-size: 42; */
        }
      }
    }
  }
  @media screen and (max-width: 1396px) {
    .content {
      .info {
        width: 70%;
        h1 {
          /* font-size: 42; */
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .content {
      width: 70%;
      .info {
        width: 80%;
        h1 {
          /* font-size: 42; */
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .content {
      width: 70%;
      .info {
        width: 80%;
        h1 {
          /* font-size: 42; */
          font-size: 36px;
        }
        p {
          font-size: 18px;
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }

  @media screen and (max-width: 596px) {
    .content {
      width: 80%;
      .info {
        width: 90%;
        h1 {
          /* font-size: 42; */
          font-size: 30px;
        }
        p {
          font-size: 14px;
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }
  @media screen and (max-width: 424px) {
    .content {
      width: 90%;
      .info {
        width: 100%;
        h1 {
          /* font-size: 42; */
          font-size: 30px;
        }
        p {
          font-size: 14px;
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }
  @media screen and (max-width: 324px) {
    padding-bottom: 30px;
    .content {
      width: 100%;
      .img {
        width: 85%;
        height: 85%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
      }
      .info {
        width: 100%;
        h1 {
          /* font-size: 42; */
          font-size: 22px;
          width: 90%;
          margin: 0 auto;
          svg {
            width: 24px;
            height: 24px;
          }
        }
        p {
          font-size: 10px;
          width: 70%;
          margin: 0 auto;
        }
      }
    }
  }
`;

export default Intro;
