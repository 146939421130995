import React from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components'
import backgroundfooter from "../../assets/images/backgroundfooter.png"
import logofooter from "../../assets/images/sologanintro.png"
import { useTumysContext } from '../../context/tumycontext';
import InfoContact from './InfoContact';

function Footer() {
  const location = useLocation();
  const { english,handleLanguage,nav,handleNav} = useTumysContext();

  if(location.pathname==="/contact"){
    return(
      <></>
    )
  }

  if(location.pathname==="/profile"){
    return(
      <></>
    )
  }
  
  return (
    <div style={{ backgroundImage: `url(${backgroundfooter})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPositionY:"center",backgroundPositionX:"center" }} className='footer'>

    <Wrapper>

        <div className='title'>
            <img  alt="Logo Goku" src={logofooter} />
        </div>


  {/* <div className='body'>

    <div className='contact'>
        <div className='phone'>
        <svg width="23" height="22" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_138_1851)">
<path d="M17.6671 15.23L13.0088 13.23C12.8559 13.1644 12.6869 13.1462 12.5235 13.1775C12.3601 13.2088 12.2099 13.2884 12.0921 13.4058L10.1471 15.3225C9.13139 14.7117 8.18767 13.9885 7.33375 13.1666C6.51292 12.3134 5.78958 11.3715 5.17708 10.3583L7.09375 8.41664C7.21078 8.29857 7.28982 8.14819 7.32071 7.98484C7.3516 7.82149 7.33292 7.65263 7.26708 7.49997L5.26708 2.84164C5.18844 2.65868 5.04686 2.50993 4.86801 2.42234C4.68916 2.33474 4.48485 2.3141 4.29208 2.36414L0.625417 3.33331C0.442067 3.38055 0.28027 3.48882 0.166667 3.64029C0.0530642 3.79176 -0.00557076 3.9774 0.000417083 4.16664C0.233815 8.42809 2.00605 12.46 4.98792 15.5133C8.0421 18.4963 12.0758 20.2684 16.3388 20.5C16.5279 20.506 16.7134 20.4475 16.8649 20.3341C17.0163 20.2206 17.1247 20.059 17.1721 19.8758L18.1404 16.2091C18.1919 16.0163 18.1724 15.8114 18.0855 15.6317C17.9986 15.452 17.8502 15.3095 17.6671 15.23Z" fill="#F2F2F2"/>
<path d="M15.0007 8.83325C15.0007 9.05427 15.0884 9.26623 15.2447 9.42251C15.401 9.57879 15.613 9.66659 15.834 9.66659C16.055 9.66659 16.267 9.57879 16.4232 9.42251C16.5795 9.26623 16.6673 9.05427 16.6673 8.83325C16.666 7.50758 16.1388 6.23658 15.2014 5.29918C14.264 4.36179 12.993 3.83458 11.6673 3.83325C11.4463 3.83325 11.2343 3.92105 11.0781 4.07733C10.9218 4.23361 10.834 4.44557 10.834 4.66659C10.834 4.8876 10.9218 5.09956 11.0781 5.25584C11.2343 5.41212 11.4463 5.49992 11.6673 5.49992C12.5514 5.49992 13.3992 5.85111 14.0243 6.47623C14.6495 7.10135 15.0007 7.9492 15.0007 8.83325Z" fill="#F2F2F2"/>
<path d="M11.6673 0.5C11.4463 0.5 11.2343 0.587797 11.0781 0.744078C10.9218 0.900358 10.834 1.11232 10.834 1.33333C10.834 1.55435 10.9218 1.76631 11.0781 1.92259C11.2343 2.07887 11.4463 2.16667 11.6673 2.16667C13.4348 2.16865 15.1294 2.87167 16.3792 4.12148C17.629 5.37129 18.332 7.06583 18.334 8.83333C18.334 9.05435 18.4218 9.26631 18.5781 9.42259C18.7343 9.57887 18.9463 9.66667 19.1673 9.66667C19.3883 9.66667 19.6003 9.57887 19.7566 9.42259C19.9129 9.26631 20.0007 9.05435 20.0007 8.83333C19.9982 6.62394 19.1195 4.50573 17.5572 2.94346C15.9949 1.38118 13.8767 0.502426 11.6673 0.5V0.5Z" fill="#F2F2F2"/>
</g>
<defs>
<clipPath id="clip0_138_1851">
<rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
0915 444 235
        </div>
        <div className='mail'>
        <svg width="21" height="20" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.75 0H2.25C1.65326 0 1.08097 0.237053 0.65901 0.65901C0.237053 1.08097 0 1.65326 0 2.25L0 12.75C0 13.3467 0.237053 13.919 0.65901 14.341C1.08097 14.7629 1.65326 15 2.25 15H15.75C16.3467 15 16.919 14.7629 17.341 14.341C17.7629 13.919 18 13.3467 18 12.75V2.25C18 1.65326 17.7629 1.08097 17.341 0.65901C16.919 0.237053 16.3467 0 15.75 0ZM15.525 3.6L9.525 8.85C9.36163 8.9342 9.18319 8.98519 9 9C8.81681 8.98519 8.63837 8.9342 8.475 8.85L2.475 3.6C2.32582 3.47071 2.2341 3.28745 2.22004 3.09053C2.20597 2.89362 2.27071 2.69918 2.4 2.55C2.52929 2.40082 2.71255 2.3091 2.90947 2.29504C3.10638 2.28097 3.30082 2.34571 3.45 2.475L8.925 7.275L14.4 2.475C14.4729 2.40902 14.5585 2.35848 14.6514 2.32643C14.7444 2.29437 14.8429 2.28145 14.941 2.28846C15.0391 2.29547 15.1348 2.32225 15.2222 2.3672C15.3097 2.41214 15.3872 2.47433 15.45 2.55C15.5331 2.60748 15.6022 2.68287 15.6523 2.77062C15.7023 2.85838 15.7321 2.95625 15.7393 3.05702C15.7465 3.15779 15.7309 3.25889 15.6939 3.35287C15.6568 3.44684 15.5991 3.5313 15.525 3.6Z" fill="#F2F2F2"/>
</svg>
thang@goku.agency
        </div>
        <a target="_blank" rel="noopener noreferrer" href='https://goku.agency/'>
         
        <div className='website'>
          
        <svg width="22" height="23" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99935 18.8334C5.39685 18.8334 1.66602 15.1026 1.66602 10.5001C1.66602 5.89758 5.39685 2.16675 9.99935 2.16675C14.6018 2.16675 18.3327 5.89758 18.3327 10.5001C18.3327 15.1026 14.6018 18.8334 9.99935 18.8334ZM15.2952 13.7934V13.7059C15.2952 12.9376 15.2952 12.5867 14.7518 12.2759C14.5772 12.1769 14.3978 12.0865 14.2143 12.0051C13.9085 11.8659 13.706 11.7751 13.4327 11.3751C13.4 11.3276 13.3678 11.2798 13.336 11.2317C13.0485 10.7942 12.841 10.4792 12.051 10.6042C10.4968 10.8509 10.3818 11.1242 10.3135 11.5859L10.3027 11.6617C10.2018 12.3367 10.1835 12.5634 10.4652 12.8592C11.5193 13.9651 12.151 14.7626 12.3427 15.2292C12.436 15.4567 12.676 16.1459 12.511 16.8276C13.5225 16.4247 14.4229 15.7855 15.1368 14.9634C15.2285 14.6517 15.2952 14.2634 15.2952 13.7934ZM9.99935 3.69425C8.06852 3.69425 6.32435 4.49925 5.08602 5.79091C5.23352 5.89341 5.36185 6.03758 5.45018 6.23591C5.62018 6.61675 5.62018 7.00925 5.62018 7.35675C5.62018 7.63008 5.62018 7.89008 5.70768 8.07758C5.82768 8.33425 6.34602 8.44425 6.80352 8.53925C6.96768 8.57425 7.13602 8.60925 7.28935 8.65175C7.71102 8.76841 8.03768 9.14758 8.29852 9.45175C8.40685 9.57758 8.56768 9.76341 8.64852 9.81008C8.69018 9.78008 8.82435 9.63425 8.89018 9.39508C8.94185 9.21175 8.92685 9.05008 8.85268 8.96175C8.38601 8.41175 8.41185 7.35341 8.55602 6.96258C8.78268 6.34675 9.49102 6.39258 10.0093 6.42591C10.2027 6.43841 10.3843 6.45091 10.521 6.43341C11.0393 6.36841 11.1993 5.57925 11.3118 5.42508C11.5552 5.09175 12.3002 4.58925 12.7618 4.27925C11.8923 3.89258 10.951 3.69326 9.99935 3.69425Z" fill="#F2F2F2"/>
</svg>
www.kame.asia
        </div>
        </a>
        <div className='social'>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2C6.477 2 2 6.477 2 12C2 16.991 5.657 21.128 10.438 21.879V14.89H7.898V12H10.438V9.797C10.438 7.291 11.93 5.907 14.215 5.907C15.309 5.907 16.453 6.102 16.453 6.102V8.562H15.193C13.95 8.562 13.563 9.333 13.563 10.124V12H16.336L15.893 14.89H13.563V21.879C18.343 21.129 22 16.99 22 12C22 6.477 17.523 2 12 2Z" fill="white"/>
</svg>

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2C14.717 2 15.056 2.01 16.122 2.06C17.187 2.11 17.912 2.277 18.55 2.525C19.21 2.779 19.766 3.123 20.322 3.678C20.8305 4.1779 21.224 4.78259 21.475 5.45C21.722 6.087 21.89 6.813 21.94 7.878C21.987 8.944 22 9.283 22 12C22 14.717 21.99 15.056 21.94 16.122C21.89 17.187 21.722 17.912 21.475 18.55C21.2247 19.2178 20.8311 19.8226 20.322 20.322C19.822 20.8303 19.2173 21.2238 18.55 21.475C17.913 21.722 17.187 21.89 16.122 21.94C15.056 21.987 14.717 22 12 22C9.283 22 8.944 21.99 7.878 21.94C6.813 21.89 6.088 21.722 5.45 21.475C4.78233 21.2245 4.17753 20.8309 3.678 20.322C3.16941 19.8222 2.77593 19.2175 2.525 18.55C2.277 17.913 2.11 17.187 2.06 16.122C2.013 15.056 2 14.717 2 12C2 9.283 2.01 8.944 2.06 7.878C2.11 6.812 2.277 6.088 2.525 5.45C2.77524 4.78218 3.1688 4.17732 3.678 3.678C4.17767 3.16923 4.78243 2.77573 5.45 2.525C6.088 2.277 6.812 2.11 7.878 2.06C8.944 2.013 9.283 2 12 2ZM12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7ZM18.5 6.75C18.5 6.41848 18.3683 6.10054 18.1339 5.86612C17.8995 5.6317 17.5815 5.5 17.25 5.5C16.9185 5.5 16.6005 5.6317 16.3661 5.86612C16.1317 6.10054 16 6.41848 16 6.75C16 7.08152 16.1317 7.39946 16.3661 7.63388C16.6005 7.8683 16.9185 8 17.25 8C17.5815 8 17.8995 7.8683 18.1339 7.63388C18.3683 7.39946 18.5 7.08152 18.5 6.75ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9Z" fill="white"/>
</svg>

<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9991 5.70998C23.0485 4.27864 22.7355 2.858 22.0891 1.57998C21.6505 1.05558 21.0418 0.701694 20.3691 0.579984C17.5866 0.327509 14.7926 0.224028 11.9991 0.269984C9.2157 0.221942 6.43176 0.322084 3.65905 0.569984C3.11087 0.669701 2.60357 0.926826 2.19905 1.30998C1.29905 2.13998 1.19905 3.55998 1.09905 4.75998C0.953962 6.91755 0.953962 9.08242 1.09905 11.24C1.12798 11.9154 1.22854 12.5858 1.39905 13.24C1.51963 13.745 1.76357 14.2123 2.10905 14.6C2.51632 15.0034 3.03543 15.2752 3.59905 15.38C5.75497 15.6461 7.92727 15.7564 10.0991 15.71C13.5991 15.76 16.669 15.71 20.299 15.43C20.8765 15.3316 21.4102 15.0595 21.8291 14.65C22.109 14.3699 22.3181 14.0271 22.4391 13.65C22.7967 12.5526 22.9723 11.4041 22.959 10.25C22.9991 9.68998 22.9991 6.30998 22.9991 5.70998ZM9.73905 10.85V4.65998L15.659 7.76998C13.9991 8.68998 11.809 9.72998 9.73905 10.85Z" fill="white"/>
</svg>
        </div>
    </div>


    <div className='placehanoi place'>
        <h3>{english ? <>Ha Noi Office</>:<>Văn phòng Hà Nội</>}</h3>
        <p>
        {english ? <>2nd floor, Tay Ha Building, 19 To Huu, Trung Van Ward, Nam Tu Liem District, Ha Noi City</>
        :
        <>Tầng 2, Toà nhà Tây Hà, 19 Tố Hữu, Phường Trung Văn, Quân Nam Từ Liêm, TP Hà Nội</>}
          
          </p>
    </div>

    <div className='placehcm place'>
    <h3>{english ? <>Ho Chi Minh Office</>:<>Văn phòng Hồ Chí Minh</>}</h3>
        
       
        <p>


        {english ? <>9th floor DMC Building, 341-343 Đien Bien Phu, 15 Ward, Bình Thạnh District, Ho Chí Minh City</>
        :
        <>Tầng 9, Toà nhà Tây Hà, 341-343 Điện Biên Phủ, Phường 15, Quân Bình Thạnh, TP Hồ Chí Minh</>}
        </p>
    </div>



  </div> */}
  <InfoContact />

  <div className='copyright'>
    <p>Copyright © 2010-2022 Goku Agency. All rights reserved.</p>
  </div>

    </Wrapper>
    </div>
  )
}
const Wrapper = styled.section`
  width: 100%;
  /* min-height: 580px; */
  padding:90px 120px 30px;
  display: flex;
  flex-direction: column;
  p{
    margin:0
  }
  

  .title{
    width: 25%;
    margin: 0 auto;
    img{
        width: 100%;
        height: 100%;
    }
  }
  .body{
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin-top:70px;
    .contact{
      svg{
        margin-right: 10px;
      }
      div{
        margin-bottom: 8px;
        display: flex;
            align-items: center;
            color: white;
      }
        .phone{
          
        }
        .social{
           
            svg{
                margin-right: 16px;
            }
        }
        .mail{

        }
    }

    .place{
        h3{
            color:white;
            font-size: 20px;
            font-weight: 500;
        }
        p{
            color:white;
            width: 80%;
        }
    }

  }

  .copyright{
    text-align: center;
    margin-top : 120px;
    /* justify-content: space-between; */
    p{
        color: #C6C6C6;
        font-size: 14px;
    }
  }

  @media screen and (max-width:1926px) {
  /* height: 900px; */

  
}
@media screen and (max-width:1566px) {
  /* height: 800px; */

  
}
@media screen and (max-width:1366px) {
  /* height: 750px; */

  
}
@media screen and (max-width: 1024px) {
  padding:90px 70px 30px;


}
@media screen and (max-width: 769px) {
  /* padding:90px 20px 30px; */
  /* flex-direction: column; */
  .title{
    width: 35%;
  }
  .body{
      grid-template-columns: 1fr;
      margin-top: 32px;
      .contact{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;
      }
    }
    .place{
      text-align: center;
      margin-bottom: 32px;
      p{
        width: 60%!important;
        margin: 0 auto;
      }
      h3{
        margin-bottom: 12px;
      }
    }
      

    .copyright{
      margin-top: 20px;
    }
  
}
@media screen and (max-width: 596px) {
    .body{
      grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 424px) {
    .body{
      grid-template-columns: 1fr;
    }
    .title{
      width: 55%;
    }
}

@media screen and (max-width: 324px) {
  padding: 50px 30px 30px;
    
    .title{
      width: 85%;
    }
}
`

export default Footer