import React from 'react'
import styled from 'styled-components'
import img from "../../assets/images/singleproject1.png"
import video from "../../assets/video/singleproject.mp4"
function SingleFeature({data}) {
  return (
    <Wrapper>
       
        {data.type==="image" ? (
             <div className='demo-img'>
             <img style={{width: "100%",height: "100%"}} src={data.src} />
         </div>
        ) : (
 <div className='demo-video'>
 <video src={data.src} controls autoPlay loop muted/>
 </div>
        )}
       

        <div className='info'>
            <h3>{data.text}</h3>
            {/* <p>{data.info}</p> */}
        </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
width: 100%;
/* height: 350px; */
margin-top: 70px;
.demo-img{
    height: 550px;
    img{
        border-radius: 16px;
    }
}
.demo-video{
    height: 550px;
    width: 100%;
    video {
        /* position: relative;
  left: 0;
  top: 0;
  opacity: 1; */
  width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;

      }
}
.info{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    h3{
        flex-basis: 50%;
        /* text-align: left; */
        text-align: center;
        display: block;
        color: #484848;
        font-size: 22px;
        font-family: SF-Pro-Display-Light;
    }
    p{
        flex-basis: 50%;
        text-align: left;
        display: block;
        color: #C6C6C6;
        font-size: 18px;


    }
}
`

export default SingleFeature