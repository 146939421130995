import React, { useState } from "react";
import styled from "styled-components";
import SingleFeature from "./SingleFeature";
import test from "../../assets/video/test.mp4";
import test1 from "../../assets/video/test1.mp4";
import test2 from "../../assets/video/test2.mp4";
import opening from "../../assets/video/OPENING-ROTATION.mp4";
import proLocation from "../../assets/video/Pro-Location-MPEG-4.mp4";
import floorPlan from "../../assets/video/Floor-plan-MPEG-4.mp4";
// import floorplan from "../../assets/video/Floor-plan-MPEG-4.mp4"
import saban from "../../assets/video/sa-ban-MPEG-4.mp4";
import showunit1 from "../../assets/video/show-uni-MPEG-4.mp4";
import showunit2 from "../../assets/video/show-unit-2-MPEG-4.mp4";
import walk from "../../assets/video/Walk-MPEG-4.mp4";
import walkthrough from "../../assets/video/walk-through-MPEG-4.mp4";

import Contact from "./Contact";
import { useTumysContext } from "../../context/tumycontext";

function Body() {
  const [active, setActive] = useState({
    hometour: 1,
    prolocation: 1,
    vrmasterplan: 1,
    vrwalking: 1,
    floorplan: 1,
  });
  const { english, handleLanguage, nav, handleNav } = useTumysContext();

  const [hometour, setHometour] = useState(1);
  const [prolocation, setProlocation] = useState(1);
  const [vrmasterplan, setVrmasterplan] = useState(1);
  const [vrwalking, setVrwalking] = useState(1);
  const [floorplan, setFloorplan] = useState(1);

  const dataOpening = {
    id: 1,
    header: `Opening Tour`,
    info: `${
      !english
        ? "Bắt đầu tour với một video mang lại những trải nghiệm dưới những góc nhìn tổng thể đẹp nhất của dự án."
        : "We start the experience tour with an opening tour with an overview video that features the best angles of the entire project."
    }`,
    src: opening,
  };
  const dataHomeTour = {
    id: 2,
    header: "Home Tour",
    info: `${
      english
        ? "The home tour allows home buyers, wherever they are, to visit and experience the inside of the model unit."
        : "Dù bạn ở bất kì đâu, Tour tham quan nhà mẫu sẽ mang lại cho bạn những trải nghiệm chân thật nhất bên trong một ngôi nhà."
    }`,
    video: "",
    iconactive: (
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7L0 13.9282V0.0717969L12 7Z"
          fill="url(#paint0_linear_141_347)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_141_347"
            x1="8.09303"
            y1="-0.505455"
            x2="7.95043"
            y2="8.21324"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D34829" />
            <stop offset="1" stop-color="#FCFF6B" />
          </linearGradient>
        </defs>
      </svg>
    ),
    gradientColor1: "rgba(211, 72, 41, 1)",
    gradientColor2: "rgba(252, 255, 107, 1)",

    submenu: [
      {
        id: 1,
        text: "Walk",
        src: walk,
      },
      {
        id: 2,
        text: "Open/close door or curtain",
        src: test1,
      },
      {
        id: 3,
        text: "Floorplan",
        src: floorPlan,
      },
    ],
  };
  const dataMasterPlan = {
    id: 4,
    header: "VR MasterPlan",
    info: `${
      english
        ? "A seamless experience with a variety of functions."
        : "MasterPlan với đây đủ các chức năng mang lại trải nghiệm tuyệt vời."
    }`,
    video: "",
    iconactive: (
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7L0 13.9282V0.0717969L12 7Z"
          fill="url(#paint0_linear_141_417)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_141_417"
            x1="8.09304"
            y1="-0.505454"
            x2="8.00702"
            y2="9.49097"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2E92EE" />
            <stop offset="1" stop-color="#BE6BFF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    gradientColor1: "rgba(46, 146, 238, 1)",
    gradientColor2: "rgba(190, 107, 255, 1)",
    submenu: [
      {
        id: 1,
        text: "Project Rotation",
        src: test,
      },
      {
        id: 2,
        text: "Time Slider",
        src: test2,
      },
      {
        id: 3,
        text: "Product Info",
        src: test1,
      },
    ],
  };
  const dataProLocation = {
    id: 3,
    header: "Pro Location",
    info: `${
      english
        ? "Our professional location map links to surrounding utilities and areas."
        : "Bản đồ chuyên nghiệp với những vị trí chính xác của các tiện ích và khu vực xung quanh."
    }`,
    video: "",
    iconactive: (
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 7L12 0.0717964L12 13.9282L0 7Z"
          fill="url(#paint0_linear_141_357)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_141_357"
            x1="3.90699"
            y1="14.5055"
            x2="3.92331"
            y2="0.265084"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2EEE86" />
            <stop offset="1" stop-color="#26BEEE" />
          </linearGradient>
        </defs>
      </svg>
    ),
    gradientColor1: "rgba(46, 238, 134, 1)",
    gradientColor2: "rgba(38, 190, 238, 1)",
    submenu: [
      {
        id: 1,
        text: "Physical model",
        src: saban,
      },
      {
        id: 2,
        text: "Dynamic Location",
        src: proLocation,
      },
    ],
  };
  const dataVrWalking = {
    id: 5,
    header: "VR Walking",
    info: `${
      english
        ? "Experience the facilities and the entire space within the apartment unit."
        : "Một tính năng độc đáo giúp bạn có cái nhìn tổng quan về những cơ sở vật chất cũng như toàn bộ không gian sống xung quanh dự án."
    }`,
    video: "",
    iconactive: (
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 7L12 0.0717964L12 13.9282L0 7Z"
          fill="url(#paint0_linear_147_185)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_147_185"
            x1="3.90697"
            y1="14.5055"
            x2="4.04957"
            y2="5.78676"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D34829" />
            <stop offset="1" stop-color="#FCFF6B" />
          </linearGradient>
        </defs>
      </svg>
    ),
    gradientColor1: "#D34829",
    gradientColor2: "#FCFF6B",
    submenu: [
      {
        id: 1,
        text: "Interior",
        src: test1,
      },
      {
        id: 2,
        text: "Landscape",
        src: walkthrough,
      },
    ],
  };
  const dataFloorPlan = {
    id: 6,
    header: "FloorPlan",
    info: `${
      english
        ? "An easy and efficient way to find product information."
        : "Một tính năng mang lại sự dễ dàng và tiện lợi trong việc tìm kiếm thông tin sản phẩm."
    }`,
    video: "",
    iconactive: (
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7L0 13.9282V0.0717969L12 7Z"
          fill="url(#paint0_linear_147_218)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_147_218"
            x1="8.09301"
            y1="-0.505454"
            x2="8.07669"
            y2="13.7349"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2EEE86" />
            <stop offset="1" stop-color="#26BEEE" />
          </linearGradient>
        </defs>
      </svg>
    ),
    gradientColor1: "rgba(46, 238, 134, 1)",
    gradientColor2: "rgba(38, 190, 238, 1)",
    submenu: [
      {
        id: 1,
        text: "Unit Distribution 1",
        src: showunit2,
      },
      {
        id: 2,
        text: "Unit Distribution 2",
        src: showunit1,
      },
    ],
  };
  const dataVrModel = {
    id: 7,
    header: "VR Model",
    info: `${
      english
        ? "The most comprehensive way for a 3D experience with fully integrated utilities."
        : "Một giải pháp với sự toàn diện mang lại trải nghiệm 3D với đầy đủ các tính năng được tích hợp."
    }`,
    src: test1,
  };

  return (
    <Wrapper>
      <SingleFeature data={dataOpening} />
      <SingleFeature
        data={dataHomeTour}
        side="left"
        layout
        active={hometour}
        setActive={setHometour}
      />
      <SingleFeature
        data={dataProLocation}
        active={prolocation}
        setActive={setProlocation}
      />
      <SingleFeature
        data={dataMasterPlan}
        side="left"
        layout
        active={vrmasterplan}
        setActive={setVrmasterplan}
      />
      <SingleFeature
        data={dataVrWalking}
        active={vrwalking}
        setActive={setVrwalking}
      />
      <SingleFeature
        data={dataFloorPlan}
        side="left"
        layout
        active={floorplan}
        setActive={setFloorplan}
      />
      <SingleFeature data={dataVrModel} />

      <Contact />
    </Wrapper>
  );
}
const Wrapper = styled.section`
  background-color: #141313;
  min-height: 1000px;
  padding: 60px 30px;
  width: 100%;
  @media screen and (max-width: 496px) {
    padding: 50px 20px;
  }
  @media screen and (max-width: 324px) {
    padding: 30px 10px;
  }
`;
export default Body;
