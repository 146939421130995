import React from "react";
import styled from "styled-components";
import docu1 from "../../assets/images/docu1.png";
import docu2 from "../../assets/images/docu2.png";
import docu3 from "../../assets/images/landingtaptrung_2.jpg";
import { useTumysContext } from "../../context/tumycontext";
import img from "../../assets/images/kamenhansulogo.jpeg";

function Taptrung() {
  const { english, handleLanguage, nav, handleNav } = useTumysContext();

  return (
    <Wrapper>
      <div className="text">
        <div>
          {!english ? (
            <>
              <h2>Tập trung.</h2>
              <p>
                Goku hiện chỉ cung cấp dịch vụ sản xuất hình ảnh Bất động sản
                nên chúng tôi có đội ngũ nhân sự chuyên nghiệp và giàu kinh
                nghiệm, cũng như một hệ thống thiết bị chụp ảnh tối ưu cho ngành
                Bất động sản.
              </p>
              {/* <p><span>*Giai đoạn thực hiện:</span> Khi công trình đang xây dựng.</p> */}
              {/* <p>Từ  <span>3,900,000 đ</span></p>
            <button onClick={()=>{
              document.querySelector(".fb_customer_chat_bounce_out_v2").classList.remove("fb_customer_chat_bounce_in_from_left");
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.maxHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.minHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.height="300px";


            }} role="button" className='btn-price'>Xem Báo giá</button> */}
            </>
          ) : (
            <>
              <h2>Project Progress Documentation</h2>
              <p>
                To help investors in keeping their clients updated, Goku
                documents monthly or quarterly progress of the projects through
                multi-angle, cleanly retouched photos and video collections. We
                add excitement and take away the "mess" of the construction
                site.
              </p>

              <p>
                This service is provided during the construction phase of the
                project.
              </p>
            </>
          )}
        </div>
      </div>

      <div className="img">
        <div className="imgbox">
          <img alt="Documentation Image" src={docu3} />
          {/* <img alt="Documentation Image" src={docu2} />
                <img alt="Documentation Image" src={docu1} /> */}
        </div>
        {/* <p>Đội ngũ nhân viên</p> */}
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  height: 550px;
  margin-top: 220px;
  margin-bottom: 150px;
  /* grid-auto-flow: dense; */
  /* background-color: white */
  /* margin-top: 100px; */
  .btn-price {
    align-items: center;
    appearance: none;
    background-color: #fcfcfd;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395a;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .btn-price:focus {
    box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  }

  .btn-price:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .btn-price:active {
    box-shadow: #d6d6e7 0 3px 7px inset;
    transform: translateY(2px);
  }

  .text {
    width: 100%;

    /* height: 100%; */
    /* margin-left: 40px; */
    div {
      width: 80%;

      h2 {
        color: black;
        font-family: SF-Pro-Display-Bold;
        font-size: 42px;
        margin-bottom: 20px;
      }

      p {
        font-size: 22px;
        font-family: SF-Pro-Display-Light;
      }
      p:nth-child(3) {
        span {
          color: black;
          font-family: SF-Pro-Display-Bold;
        }
      }
      p:nth-child(2) {
        margin-bottom: 40px;
      }
    }
  }

  .img {
    /* height: 100%; */
    width: 100%;
    /* grid-column-start: 1;
    grid-row-start: 1; */

    .imgbox {
      display: flex;
      width: 100%;
      /* height: 80%; */
      /* height: 100%; */

      img {
        display: block;
        width: 100%;
        /* height: 100%; */
        /* height: 100%; */
        border-radius: 16px;
      }
    }
    p {
      color: #c6c6c6;
      font-family: SF-Pro-Display-Light;
      margin-top: 16px;
      text-align: center;
      margin-left: 98px;
    }
  }
  @media screen and (max-width: 1756px) {
    .img {
      /* height: 75%; */
      .img-box {
        /* width: 90%; */
        /* height: ; */
      }
      p {
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: 1524px) {
    .img {
      /* height: 75%; */
      .img-box {
        /* width: 90%; */
        /* height: ; */
      }
      p {
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: 1396px) {
    .img {
      /* height: 65%; */
      .img-box {
        /* width: 90%; */
        /* height: ; */
      }
      p {
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    height: 400px;
    margin-bottom: 50px;

    .text {
      div {
        h2 {
          font-size: 36px;
          line-height: 40px;
          width: 100%;
        }
        p {
          font-size: 18px;
        }
      }
    }
    .img {
      /* height: 70%; */
      .img-box {
        /* width: 90%; */
        /* height: 100%; */
      }
      p {
        margin-left: 40px;
      }
    }
  }
  @media screen and (max-width: 796px) {
    height: 400px;
    .text {
      padding: 0 40px;

      div {
        width: 90%;

        h2 {
          font-size: 34px;
          line-height: 38px;
          width: 100%;
        }
        p {
          font-size: 16px;
        }
        p:nth-child(2) {
          margin-bottom: 16px;
        }
      }
    }
    .img {
      padding: 0 40px;

      /* height: 75%; */
      .imgbox {
        /* width: 90%; */
      }
      p {
        margin-left: 0px;
      }
    }
  }
  @media screen and (max-width: 660px) {
    height: 400px;

    .img {
      /* height: 50%; */

      .imgbox {
        /* width: 90%; */
      }
      p {
        /* margin-left: 30px; */
      }
    }
  }
  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    height: 350px;
    margin-bottom: 70px;

    text-align: center;
    .text {
      text-align: center;
      margin-left: 0;
      div {
        width: 100%;
        h2 {
          font-family: SF-Pro-Display-Bold;
          width: 85%;
          font-size: 28px;
          margin: 0 auto 16px;
          line-height: 34px;
        }
      }
    }
    .img {
      width: 100%;
      grid-column-start: 1;
      grid-row-start: 2;

      .imgbox {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 496px) {
    /* height: auto; */
    /* display:  none; */
    height: 350px;
    margin-top: 150px;

    .img {
      width: 100%;
      /* height: 80%; */
      .imgbox {
        img {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 300px) {
    height: 250px;
    /* margin-top: 60px; */

    .text {
      div {
        h2 {
          font-family: SF-Pro-Display-Bold;
          width: 85%;
          font-size: 20px;
          line-height: 24px;
          margin: 0 auto 12px;
        }
        p {
          font-size: 10px;
          font-family: SF-Pro-Display-Regular;
        }
      }
    }
    .img {
      width: 100%;
      /* height: 90%; */

      .imgbox {
        img {
          width: 100%;
        }
      }
      p {
        /* margin-left: 0; */
        /* font-size: 10px; */
        margin: 12px 0 0;
        font-size: 10px;
      }
    }
  }
`;

export default Taptrung;
