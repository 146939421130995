import React, { useEffect } from 'react'

import { Routes, Route, useParams } from 'react-router-dom';
import Nav from '../components/Share/Nav';
import Body from '../components/SingleProjectPage/Body';
import Intro from '../components/SingleProjectPage/Intro';

// import {Helmet} from "react-helmet";
import { Helmet } from 'react-helmet-async';


import { useTumysContext } from '../context/tumycontext';
import Loadingproject from '../components/Share/Loadingproject';
function SingleProjectPage() {
  const { english, nav, fetchSingleProject,SingleProject,fetchMoreProject, } = useTumysContext();
  let { id ,project} = useParams();
  useEffect(()=>{
    fetchSingleProject(`${english ? "en": "vi"}`,project,id)
    // console.log(id,project)
    fetchMoreProject(`${english?"en":"vi"}`,project,id,3)
  },[english,id])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [english,id,project])
  
//   useEffect(() => {
//     if(SingleProject.data.categories !== undefined){
//  updateMeta(`Dự án ${SingleProject.data.name} (${SingleProject.data.categories.length >= 1 ? SingleProject.data.categories[0].name :""}${SingleProject.data.categories.length >= 2 ? `/${SingleProject.data.categories[1].name}` : "" }${SingleProject.data.categories.length >= 3 ? `/${SingleProject.data.categories[2].name}` :"" })`,`${SingleProject.data.description}`,"https://goku.agency/img/thumb-min.png",`https://goku.agency/projectdetail/goku-product/${SingleProject.data.id}/${SingleProject.data.slug}`)
//     }
   
     
//       // if(SingleProject.data.categories !== undefined){

//       //   console.log(SingleProject.data.description)
//       // }

//       // console.log(SingleProject.data.categories[0].name)
   
    
   
   
//  }, [SingleProject.data.name]);


 

    // console.log(SingleProject.otherProject)
    // console.log(SingleProject.data)
    if(SingleProject.data){
      return (
        <>
        {/* {SingleProject.data.categories !== undefined && (
 <Helmet>
                


 <meta charset="utf-8" />
<link rel="icon" href="https://goku.agency/img/favicon.png" />
<meta name="viewport" content="width=device-width,initial-scale=1" />
<meta name="theme-color" content="#000000" />
<meta name="description" content={`${SingleProject.data.description}`} />

<title>{`Dự án ${SingleProject.data.name} (${SingleProject.data.categories.length >= 1 ? SingleProject.data.categories[0].name :""}${SingleProject.data.categories.length >= 2 ? `/${SingleProject.data.categories[1].name}` : "" }${SingleProject.data.categories.length >= 3 ? `/${SingleProject.data.categories[2].name}` :"" })`}</title>
<meta name="robots" content="noindex,nofollow" />
<meta name="googlebot" content="notranslate" />

<meta property="og:title" content={`Dự án ${SingleProject.data.name} (${SingleProject.data.categories.length >= 1 ? SingleProject.data.categories[0].name :""}${SingleProject.data.categories.length >= 2 ? `/${SingleProject.data.categories[1].name}` : "" }${SingleProject.data.categories.length >= 3 ? `/${SingleProject.data.categories[2].name}` :"" })`} />
<meta property="og:description" content={`${SingleProject.data.description}`} />
<meta property="og:type" content="website" />
<meta property="og:url" content={`${window.location.href}`} />
<meta property="og:image" content={`https://goku.agency/img/rsz_goku.png`} />
<meta property="og:image:alt" content="Kame Project" />
<meta property="og:image:width" content="1200" />
<meta property="og:image:height" content="628" />
    </Helmet>
        )} */}
        {SingleProject.data.categories !== undefined && (
          <Helmet>
    <meta charset="utf-8" />
    <link rel="icon" href="https://goku.agency/img/favicon.png" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    
    <meta data-rh="true" name="description" content={`${SingleProject.data.description}`} />

    <title>{`Dự án ${SingleProject.data.name} (${SingleProject.data.categories.length >= 1 ? SingleProject.data.categories[0].name :""}${SingleProject.data.categories.length >= 2 ? `/${SingleProject.data.categories[1].name}` : "" }${SingleProject.data.categories.length >= 3 ? `/${SingleProject.data.categories[2].name}` :"" })`}</title>
    <meta data-rh="true" name="robots" content="all" />
  
    <meta data-rh="true" property="og:title" content={`${`Dự án ${SingleProject.data.name} (${SingleProject.data.categories.length >= 1 ? SingleProject.data.categories[0].name :""}${SingleProject.data.categories.length >= 2 ? `/${SingleProject.data.categories[1].name}` : "" }${SingleProject.data.categories.length >= 3 ? `/${SingleProject.data.categories[2].name}` :"" })`}`} />
    <meta data-rh="true" property="og:description" content={`${SingleProject.data.description}`} />
    <meta  data-rh="true" property="og:type" content="website" />
    <meta data-rh="true" property="og:url"content={`https://goku.agency/projectdetail/goku-product/${SingleProject.data.id}/${SingleProject.data.slug}`} />
    <meta data-rh="true" property="og:image" content="https://goku.agency/img/rsz_goku.png" />
    <meta data-rh="true" property="og:image:alt" content="Goku"/>
    <meta data-rh="true" property="og:image:width" content="1200" />
    <meta data-rh="true" property="og:image:height" content="628" />
    <link rel='canonical' href={`https://goku.agency/projectdetail/goku-product/${SingleProject.data.id}/${SingleProject.data.slug}`} />

    </Helmet>
        )}
           
        
        {nav?(<Nav />):(
             
        
         <>
          <Intro data={SingleProject.data} />
        
        <Body data={SingleProject.data}/>
       
        
         
        
         </>
        
        )}
        
        </>
          )
    }
 
}



export default SingleProjectPage



