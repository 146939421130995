import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
function Singleblog({item}) {

  return (
    <Link target="_blank" rel="noopener noreferrer" to={`/blog/${item.id}/2/${item.slug}`}>

        <Wrapper>
        <img alt="Blog Image" src={item.thumb}   />
             
             <div className='title'>
              <h1>{item.name
}</h1>
             </div>

            <div className='layer'>
                <h1>{item.name
}</h1>

                <div className='button'>
                    <p>Detail</p>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.457 0.991667C14.531 0.991886 14.6043 1.00514 14.6737 1.03083L14.692 1.0375C14.8069 1.08483 14.9052 1.16506 14.9746 1.26811C15.044 1.37116 15.0814 1.49243 15.082 1.61667V14.1167C15.082 14.2824 15.0162 14.4414 14.899 14.5586C14.7818 14.6758 14.6228 14.7417 14.457 14.7417C14.2913 14.7417 14.1323 14.6758 14.0151 14.5586C13.8979 14.4414 13.832 14.2824 13.832 14.1167V3.125L1.64037 15.3167C1.52287 15.435 1.36537 15.5 1.1987 15.5C1.03203 15.5 0.874531 15.435 0.757031 15.3167C0.638698 15.1992 0.573698 15.0417 0.573698 14.875C0.573698 14.7083 0.638698 14.5508 0.757031 14.4333L12.9479 2.24167H1.95703C1.79127 2.24167 1.6323 2.17582 1.51509 2.05861C1.39788 1.9414 1.33203 1.78243 1.33203 1.61667C1.33203 1.45091 1.39788 1.29193 1.51509 1.17472C1.6323 1.05752 1.79127 0.991667 1.95703 0.991667H14.457Z" fill="white"/>
</svg>

                </div>
            </div>
        </Wrapper>
    </Link>
  )
}

const Wrapper =styled.div`
 border-radius: 10px;
    position: relative;
    width: 100%;
    height: 100%;
    height: 400px;
    /* padding: 20px; */

    .title{
      position: absolute;
      bottom: 20px;
      padding-right: 20px;
      left: 20px;
      text-align: left!important;
      /* display: none; */
      h1{
        font-size: 20px;
        font-family: SF-Pro-Display-Bold;
        color: white;
        line-height: 24px;
      
      }
    }

    img{
      width: 100%;
      height: 100%;
    border-radius: 10px;

    }
    &:hover .layer{
        display: flex;
    }
    &:hover .title{
     display: none;
    }

    .layer{
    position: absolute;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    background-color: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 25px;
    padding-bottom: 25px;
    animation: show 0.6s linear;
    h1{
      font-size: 20px;
        font-family: SF-Pro-Display-Bold;
        color: white;
        line-height: 24px;
        text-align: left;
    }
    .button{
        display: flex;
        border: 1px solid white;
        width: 80px;
        align-items: center;
        padding: 4px;
        border-radius: 10px;
        justify-content: space-around;
        p{
            display: block;
            margin-bottom: 0;
            color: white;
        }
    }

    img{
      width: 100px;
    }


  }

  @media screen and (max-width: 1024px) {
    
  }
  @media screen and (max-width: 768px) {
    height: 350px;

    .title{
      h1{
        font-size: 16px;
        line-height: 18px;
      }
     
    }
    .layer{
      .title{
        h1{
          font-size: 20px;
        line-height: 22px;
      }
      }
     
    }
  }
  @media screen and (max-width: 596px) {
    height: 300px;

    .title{
      h1{
        font-size: 18px;
        line-height: 20px;
      }
     
    }
  }
  @media screen and (max-width: 424px) {
    height: 250px;
    .title{
      h1{
        font-size: 16px;
        line-height: 18px;
      }
     
    }
  }

`

export default Singleblog