import React from 'react'
import styled from 'styled-components'
import Actualproject from './Actualproject'
import Cgi from './Cgi'
import Cgimobile from './Cgimobile'

import Cityscape from './Cityscape'
import Contact from './Contact'
import Documentation from './Documentation'
import Photography360 from './Photography360'
import { useMediaQuery } from 'usehooks-ts'
import Cityscapemobile from './Cityscapemobile'
import Chupanhcongtrinh from './Chupanhcongtrinh'
import Quaydungphimcongtrinh from './Quaydungphimcongtrinh'
import Chupanhcongtrinhmobile from './Chupanhcongtrinhmobile'
function Body() {
  const matches = useMediaQuery('(max-width: 650px)')
  return (
    <Wrapper>
        {matches ? <Cgimobile /> : <Cgi />}
        {/* <Cgi /> */}
        <Documentation />

        {/* <Actualproject /> */}
        {matches ? <Chupanhcongtrinhmobile /> :  <Chupanhcongtrinh />}
        <Photography360 />

       
        {/* <Cityscape /> */}
        {/* <Quaydungphimcongtrinh /> */}
        {!matches ? <Cityscape /> : <Cityscapemobile />}

        <Contact />
    </Wrapper>
  )
}
const Wrapper = styled.section`
background-color: #f4f5f6 ;
/* min-height: 500px; */
/* min-height: 1000px; */
padding: 0 280px 80px;
@media screen and (max-width: 1524px){
  padding: 0 100px 80px;

}
@media screen and (max-width: 1396px){
  padding: 0 80px 80px;

}
@media screen and (max-width: 1024px){
  padding: 0 50px 80px;

}
@media screen and (max-width: 796px){
  padding: 0 40px 80px;
  
}
@media screen and (max-width: 596px){
  padding: 0 30px 50px;
  
}
@media screen and (max-width: 424px){
  padding: 0 30px 30px;
  
}
@media screen and (max-width: 300px){
  padding: 0 8px 30px;
  
}
`

export default Body