import { Image } from "antd";
import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import styled from "styled-components";

// image
import nhatrang_ from "../../assets/images/imageMasonry/nhatrang_.jpg";
import Citadines_Tay_Ho from "../../assets/images/imageMasonry/Citadines_Tay_Ho.jpg";
import Delasol from "../../assets/images/imageMasonry/Delasol.jpg";
import GrandMarina_1 from "../../assets/images/imageMasonry/GrandMarina_1.jpg";
import GrandMarina_2 from "../../assets/images/imageMasonry/GrandMarina_2.jpg";
import GrandMarina_3 from "../../assets/images/imageMasonry/GrandMarina_3.jpg";
import GrandMarina_4 from "../../assets/images/imageMasonry/GrandMarina_4.jpg";
import GrandMarina_5 from "../../assets/images/imageMasonry/GrandMarina_5.jpg";
import GrandMarina_6 from "../../assets/images/imageMasonry/GrandMarina_6.jpg";
import Hanhomes from "../../assets/images/imageMasonry/Hanhomes.jpg";
import Lotte_Mall from "../../assets/images/imageMasonry/Lotte_Mall.jpg";
import Metropole_Thu_Thiem_01 from "../../assets/images/imageMasonry/Metropole_Thu_Thiem_01.jpg";
import Metropole_Thu_Thiem_02 from "../../assets/images/imageMasonry/Metropole_Thu_Thiem_02.jpg";
import Metropole_Thu_Thiem_03 from "../../assets/images/imageMasonry/Metropole_Thu_Thiem_03.jpg";
import Metropole_Thu_Thiem_04 from "../../assets/images/imageMasonry/Metropole_Thu_Thiem_04.jpg";
import Metropole_Thu_Thiem_05 from "../../assets/images/imageMasonry/Metropole_Thu_Thiem_05.jpg";
import Metropole_Thu_Thiem_06 from "../../assets/images/imageMasonry/Metropole_Thu_Thiem_06.jpg";
import Metropole_Thu_Thiem_07 from "../../assets/images/imageMasonry/Metropole_Thu_Thiem_07.jpg";
import Metropole_Thu_Thiem_08 from "../../assets/images/imageMasonry/Metropole_Thu_Thiem_08.jpg";
import Metropole_Thu_Thiem_09 from "../../assets/images/imageMasonry/Metropole_Thu_Thiem_09.jpg";
import Metropole_Thu_Thiem_10 from "../../assets/images/imageMasonry/Metropole_Thu_Thiem_10.jpg";
import The_Arena_Cam_Ranh_01 from "../../assets/images/imageMasonry/The_Arena_Cam_Ranh_01.jpg";
import The_Classia_01 from "../../assets/images/imageMasonry/The_Classia_01.jpg";
import The_Song_01 from "../../assets/images/imageMasonry/The_Song_01.jpg";
import The_Song_02 from "../../assets/images/imageMasonry/The_Song_02.jpg";
import Topview_batch from "../../assets/images/imageMasonry/Topview_batch.jpg";
import Vinhomes_Grand_Park_01 from "../../assets/images/imageMasonry/Vinhomes_Grand_Park_01.jpg";
import Vinhomes_Grand_Park_02 from "../../assets/images/imageMasonry/Vinhomes_Grand_Park_02.jpg";
import Vinhomes_Grand_Park_03 from "../../assets/images/imageMasonry/Vinhomes_Grand_Park_03.jpg";
import Zeit_River_Thu_Thiem_01 from "../../assets/images/imageMasonry/Zeit_River_Thu_Thiem_01.jpg";
import Zeit_River_Thu_Thiem_02 from "../../assets/images/imageMasonry/Zeit_River_Thu_Thiem_02.jpg";

const imagesMasonry = [
  {
    image: nhatrang_,
  },
  {
    image: Citadines_Tay_Ho,
  },
  {
    image: Delasol,
  },
  {
    image: GrandMarina_1,
  },
  {
    image: GrandMarina_2,
  },
  {
    image: GrandMarina_3,
  },
  {
    image: GrandMarina_4,
  },
  {
    image: GrandMarina_5,
  },
  {
    image: GrandMarina_6,
  },
  {
    image: Hanhomes,
  },
  {
    image: Lotte_Mall,
  },
  {
    image: Metropole_Thu_Thiem_01,
  },
  {
    image: Metropole_Thu_Thiem_02,
  },
  {
    image: Metropole_Thu_Thiem_03,
  },
  {
    image: Metropole_Thu_Thiem_04,
  },
  {
    image: Metropole_Thu_Thiem_05,
  },
  {
    image: Metropole_Thu_Thiem_06,
  },
  {
    image: Metropole_Thu_Thiem_07,
  },
  {
    image: Metropole_Thu_Thiem_08,
  },
  {
    image: Metropole_Thu_Thiem_09,
  },
  {
    image: Metropole_Thu_Thiem_10,
  },
  {
    image: The_Arena_Cam_Ranh_01,
  },
  {
    image: The_Classia_01,
  },
  {
    image: The_Song_01,
  },
  {
    image: The_Song_02,
  },
  {
    image: Topview_batch,
  },
  {
    image: Vinhomes_Grand_Park_01,
  },
  {
    image: Vinhomes_Grand_Park_02,
  },
  {
    image: Vinhomes_Grand_Park_03,
  },
  {
    image: Zeit_River_Thu_Thiem_01,
  },
  {
    image: Zeit_River_Thu_Thiem_02,
  },
];

const ImageMasonry = () => {
  return (
    <Wrapper>
      <h2>Ảnh tiến độ dự án</h2>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry columnsCount={3} gutter="10px">
          {imagesMasonry.map((image, i) => (
            <Image key={i} width={"100%"} src={image.image} />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  h2 {
    color: black;
    font-family: SF-Pro-Display-Bold;
    font-size: 42px;
    margin-bottom: 20px;
    text-align: center;
    padding-bottom: 40px;
  }

  @media screen and (max-width: 796px) {
    h2 {
      font-size: 34px;
      line-height: 38px;
    }
  }
`;

export default ImageMasonry;
