import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTumysContext } from '../../context/tumycontext'
import { useHistory ,useLocation } from 'react-router-dom';
function SearchInput() {
    const location = useLocation()

    const {VerseProject,ProductionProject,handleSearchVerse,handleSearchProduction,searchProject} = useTumysContext();
    useEffect(()=>{
        if(location.pathname==="/work/gokuproduction"){
            if(ProductionProject.searchValue.trim()!==""){
                console.log(ProductionProject.searchValue)
                searchProject(ProductionProject.searchValue,9,"goku-product")
              }
        }
        if(location.pathname==="/work/gokuverse"){
            if(VerseProject.searchValue.trim()!==""){
                console.log(VerseProject.searchValue)
                searchProject(VerseProject.searchValue,9,"goku-verse")
              }
        }
      
       
    },[ProductionProject.searchValue,VerseProject.searchValue])

    useEffect(()=>{
        if(location.pathname==="/work/gokuverse"){
            handleSearchVerse("")
        }
        if(location.pathname==="/work/gokuproduction"){
            handleSearchProduction("")
        }
    },[])
  return (
    <Wrapper>
         <input value={location.pathname==="/work/gokuverse" ? VerseProject.searchValue : ProductionProject.searchValue} onChange={(e)=>{
            if(location.pathname==="/work/gokuverse"){
                handleSearchVerse(e.target.value)

            }
            if(location.pathname==="/work/gokuproduction"){
                handleSearchProduction(e.target.value)

            }
         }} type="text" class="searchTerm" placeholder="Bạn muốn tìm gì?" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
width: 30%;
/* margin-top: 40px; */
margin: 40px auto 0;
input{
    width: 100%;
    padding: 6px 10px;
    border-radius: 20px;
    border: 1px solid #dcdcdc;
    
}
`

export default SearchInput