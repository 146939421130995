import React from 'react'
import styled from 'styled-components'
import missionstar from "../../assets/images/missionstar.png"
import missionglobal from "../../assets/images/missionglobal.png"
import mission from "../../assets/images/mission.png"
import vision from "../../assets/images/vision.png"
import value1 from "../../assets/images/value1.png"
import value2 from "../../assets/images/value2.png"
import value3 from "../../assets/images/value3.png"
import value4 from "../../assets/images/value4.png"
import { Link } from 'react-router-dom'
import { useTumysContext } from '../../context/tumycontext'
import cameraimage from "../../assets/images/camera.png"
import Buttonchatngay from '../Share/Buttonchatngay'



function Body() {
  const { english,handleLanguage,nav,handleNav} = useTumysContext();

    const dataValue = [
        {
            id :1,
            text : `${english ? "Quality":"Chất lượng"}`,
            img: value1
        },
        {
            id :2,
            text : `${english ? "Professionalism": "Chuyên nghiệp"}` ,
            img: value2
        },
        {
            id :3,
            text : `${english ? "Integrity":"Chính trực"}`,
            img: value3
        },
        {
            id :4,
            text : `${english ? "Transparency":"Minh bạch"}`,
            img: value4
        }
    ]
  return (
    <Wrapper>
     {/* Hello */ }
    

     <h1>Goku.agency</h1>
     <div className='elip'></div>
     <div className='camera'>
                <img alt='Camera' src={cameraimage} />

    </div>
     
     <p className='about'>{english?"Goku is the first production house in Vietnam that provides comprehensive services in photography and videography catered especially for Real estate developers. In 2020, our aim for even more all-inclusive service packages gave rise to GokuVerse, a programming product which combines the latest VR and AR technologies.":"Goku là đơn vị đầu tiên cung cấp dịch vụ sản xuất hình ảnh toàn diện cho các Dự án Bất động sản. Năm 2020, chúng tôi ra mắt thêm sản phẩm lập trình GokuVerse kết hợp công nghệ VR và AR mới nhất cho website và app bán hàng dự án. Tới nay, chúng tôi đã trở thành doanh nghiệp tiên phong trong lĩnh vực với hơn 100 dự án trên toàn quốc cho các Chủ đầu tư Bất động sản trong và ngoài nước."}</p>

     <p className='founded'>{english?"Founded in 2018":"Thành lập năm 2018"}</p>
    
   <div className='mission'>
    <div className='mission-single'>
        <div className='mission-title'>
            <img alt="Mission" src={mission} />
        </div>
      <img alt="Star" src={missionstar} />
      {english?(
        <p>We help <span>Real Estate</span> developers <span>perfect their marketing</span> and sale kit at every stage of development, by providing <span>the most premium-quality</span> image packages, website sand applications.</p>
      ):(
        <p>Giúp Chủ đầu tư Bất động sản có những hình ảnh và website/app <span>chuyên nghiệp</span> phục vụ bán hàng và marketing hiệu quả.</p>
      
      )}
     
    </div>

    <div className='mission-single'>
    <div className='vision-title'>
            <img alt="Vision" src={vision} />
        </div>
      <img alt="Global Mission" src={missionglobal} />
      {english?(
        <p>We aim to produce images and software for the Real Estate industry with <span>world-class quality standards.</span></p>
      ):(
      <p>Sản xuất hình ảnh và phần mềm cho ngành Bất động sản với chất lượng đạt <span>Tiêu chuẩn Quốc tế.</span></p>
     
      )}
     
    </div>
   </div>


   <div className='values-section'>
    {english?(
    <h1>Core Values's Goku</h1>

    ):(<h1>Giá Trị Cốt Lõi</h1>)}

    <div className='values'>
     {dataValue.map((item,index)=>{
        return(
            <div key={index} className='value-item'>
            <img alt="Value" src={item.img} />
            <p>{item.text}</p>
        </div>
        )
     })}
  
    
    </div>
   </div>


   <div className='contact'>
    {english ? (<>
        <p>More information</p>
    <h1>Connect with Goku.agency</h1>
    <Link to="/contact">
    <button>Contact now</button>
    </Link>
    </>):(<>
        <p>Tìm hiểu thêm thông tin?</p>
    <h1>Kết nối với Goku</h1>
   
    {/* <button>Liên lạc ngay</button> */}
    <Buttonchatngay />
    
    </>)}
    
   </div>

   

    </Wrapper>
  )
}

const Wrapper = styled.div`
background-color: white;
min-height: 500px;
border-top-left-radius: 25%;
    border-top-right-radius: 25% ;
    padding: 100px 200px;
    text-align: center;
    z-index: 99;
    position: relative;

    .camera{
      position: absolute;
      top: -17%;

      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
      
    }
    
    .elip{
  width: 100vw;
  position: absolute;
  top: -10%;
  left: 0;
  background-color: white;
    height: 200px;
    border-radius: 45%;
    z-index: 2;
   
}
    &>h1{
       
        margin-top: 45px;
        font-size: 68px;
        font-family: SF-Pro-Display-Bold;
        z-index: 4;
        position: relative;
    }

    .about{
        color: #888888;
        width: 50%;
        margin: 20px auto;
        font-size: 20px;
    }
    .founded{
        font-size: 12px;
        font-family: SF-Pro-Display-Medium;
        margin: 16px auto;
        font-style: italic;

    }

    /* mission */
    .mission{
        /* display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 30px; */
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr;
        width: 80%;
        margin: 80px auto;
        /* ju: flex-start; */

        div:nth-child(1){
            span{
                color: #68CC45;
            }
        }
        div:nth-child(2){
            span{
                color: #FC1E60;
            }
        }
        

    }
    .mission-single{
        background-color: #F2F2F2;
        padding: 80px;
        height: 400px;
        width: 100%;
       border-radius: 10px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        p{
            font-size: 18px;
            line-height: 24px;
            font-family: SF-Pro-Display-Bold;
            margin-top: 16px;
        }
        &>img{
            width: 40px;
            margin-top: 15%;
        }

    
    }
    .mission-title{
        position: absolute;
        width: 200px;
        /* height: 50px; */
        top: 0;
        right: 0;
        img{
            width: 100%;
            height: 100%;

        }
    }
    .vision-title{
        position: absolute;
        height: 200px;
        /* height: 50px; */
        bottom: 0;
        right: 0;
        img{
            width: 100%;
            height: 100%;

        }
    }

    /* values */
.values-section{
    width: 100%;
    margin-top: 120px;
    h1{
        color: black;
        font-family: SF-Pro-Display-Bold;
        font-size: 36px;
        text-align: center;
        margin-bottom: 62px;
    }
    .values{
        display: grid;
        grid-gap: 40px;
        width: 80%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: 0 auto;
         .value-item{
            text-align: center;
            justify-content: space-around;
            flex-basis: 25%;
            p{
                font-family: SF-Pro-Display-Medium;
                font-size: 24px;
                margin-top: 8px;
            }
            img{
                width: 100px;
            }
         }
    }
}

.contact{
    width: 90%;
    background-color: #f2f2f2;
    padding: 40px;
    margin: 110px auto 0;
    border-radius: 10px;
   

   p{
    margin-bottom: 12px;
    color: #b7b7b8;
   }
   h1{
    margin: 12px 0 16px;
    color: black;
        font-family: SF-Pro-Display-Bold;
        font-size: 36px;
   }
   button{
    padding: 8px 16px ;
    color: white;
    background: linear-gradient(90.07deg, #2EEE86 3.12%, #26BEEE 92.02%);
border-radius: 10px;
border: none;
cursor: pointer;
   }

}
@media screen and (max-width: 1396px) {
    padding: 60px 40px;
  
    
}

@media screen and (max-width: 1024px) {
   padding: 60px 20px;
   &>h1{
    font-size: 54px;
   }
    .about{
        width: 90%;
    }
    .mission{
        width: 90%;
        .mission-single{
            padding: 60px;
        }
    }
    .values-section{
        &>h1{
            font-size: 54px;
        }
        .values{
            width: 90%;
            
            /* .value-item{
                flex-basis: 50%;
            } */

        }
    }
}

@media screen and (max-width: 768px) {
    &>h1{
    font-size: 54px;
   }
   .values-section{
      &>h1{
    font-size: 54px;

      }
   }
    .mission{
        width: 98%;
        .mission-single{
            padding: 40px;
            height: 350px;
            .vision-title{
                    height: 150px;
            }
            .mission-title{
                width: 150px;

            }
        }
    }
    .values-section{
        .values{
            width: 90%;
           grid-template-columns: 1fr 1fr;
           

        }
    }
    
}

@media screen and (max-width: 596px) {
    padding: 40px 10px;

    &>h1{
    font-size: 42px;
   }
   .values-section{
      &>h1{
    font-size: 42px;

      }
   }
    .mission{
        .mission-single{
            padding: 20px;
            height: 300px;
            &>img{
                width: 32px;
            }
            .vision-title{
                    height: 120px;
            }
            .mission-title{
                width: 120px;

            }
            p{
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    .contact{
        margin: 60px auto;
        &>h1{
            font-size: 28px;
        }
    }
    
}
@media screen and (max-width: 424px) {
    padding: 0px 10px;
    .about{
        font-size: 16px;
    }
    .elip{
        height: 150px;
    }
    &>h1{
    font-size: 36px;
    margin-top: 0;
    z-index: 99;
   }
   .camera{
    img{
        width: 200px;
    }
   }
   .mission{
        .mission-single{
            padding: 20px;
            height: 250px;
            &>img{
                width: 28px;
            }
            .vision-title{
                    height: 80px;
            }
            .mission-title{
                width: 80px;

            }
            p{
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
    .values-section{
      &>h1{
    font-size: 36px;

      }
   }
    
    
}

@media screen and (max-width: 324px) {
    padding: 40px 10px;
    &>h1{
    font-size: 30px;
   }
   .values-section{
      &>h1{
    font-size: 30px;

      }
   }
   .contact{
    &>h1{
        font-size: 24px;
    }
   }
    
}

@media screen and (max-width: 296px) {
    padding: 0 10px;
   .elip{
    top: -9%;
   }
   .camera{
    top: -15%;
   }
   .mission{
    .mission-single{
        height: 200px;
        padding: 10px;
        &>p{
            font-size: 10px;
            line-height: 12px;
        }
        &>img{
            width: 15px;
        }
    }
   }
   .values-section{
        .values{
            width: 100%;
           grid-template-columns: 1fr 1fr;
           .value-item{
            p{
                font-size: 16px;
            }
           }

        }
    }
    
}

@media screen and (max-height: 696px) {
    
    
}





`

export default Body