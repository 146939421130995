import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SharedLayout from "./pages/SharedLayout";
import Loading from "./pages/Loading";

import "./App.css";
import { useEffect, useState } from "react";
import { useTumysContext } from "./context/tumycontext";
import Home from "./pages/Home";
import Gokuverseservice from "./pages/Gokuverseservice";
import Gokuverseproduction from "./pages/Gokuverseproduction";
import Gokuworkverse from "./pages/Gokuworkverse";
import Gokuproductionwork from "./pages/Gokuproductionwork";
import SingleProjectPage from "./pages/SingleProjectPage";
import Gokublog from "./pages/Gokublog";
import Singleblogpage from "./pages/Singleblogpage";
import Aboutus from "./pages/Aboutus";
import Contactpage from "./pages/Contactpage";
import Landing from "./pages/Landing";
import Profile from "./components/Profile/Profile";

function App() {
  const [loading, setLoading] = useState(true);

  const {} = useTumysContext();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" index element={<Loading />} />
          {/* <Route path="/tumys" element={<Test />} /> */}
          <Route element={<SharedLayout />}>
            <Route index path="/home" element={<Home />} />

            <Route path="/service/gokuverse" element={<Gokuverseservice />} />
            <Route
              path="/service/gokuproduction"
              element={<Gokuverseproduction />}
            />
            <Route
              path="/chup-anh-quay-phim-tien-do-du-an-bat-dong-san"
              element={<Landing />}
            />

            <Route path="/work/gokuverse" element={<Gokuworkverse />} />
            <Route
              path="/work/gokuproduction"
              element={<Gokuproductionwork />}
            />
            <Route
              path="/singleproject/gokuverse/1"
              element={<SingleProjectPage />}
            />
            <Route
              path="/projectdetail/:project/:id/:slug"
              element={<SingleProjectPage />}
            />

            <Route path="/blog" element={<Gokublog />} />
            <Route
              path="/blog/:id/:categoryId/:slug"
              element={<Singleblogpage />}
            />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/contact" element={<Contactpage />} />
            {/* <Route
              path="/service/chup-anh-khach-san-resort"
              element={<Landing />}
            /> */}
          </Route>
          {/* <Route path="*" element={<Error />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
