import React from "react";
import styled from "styled-components";
import docu1 from "../../assets/images/docu1.png";
import docu2 from "../../assets/images/docu2.png";
import docu3 from "../../assets/images/documentation.jpeg";
import { useTumysContext } from "../../context/tumycontext";
import img from "../../assets/images/kamenhansulogo.jpeg";
import Button from "./Button";

function Kame360() {
  const { english, handleLanguage, nav, handleNav } = useTumysContext();

  return (
    <Wrapper>
      <div className="text">
        <div>
          {!english ? (
            <>
              <h2>Phim tiến độ dự án</h2>
              {/* <p>
                Đây là dịch vụ chụp ảnh và lập trình Tour VR 360 cho Khách sạn
                và Resort.
              </p> */}
              {/* <p><span>*Giai đoạn thực hiện:</span> Khi công trình đang xây dựng.</p> */}
              {/* <p>
                Từ <span>2,000,000 đ</span>
              </p> */}
              {/* <button onClick={()=>{x
              document.querySelector(".fb_customer_chat_bounce_out_v2").classList.remove("fb_customer_chat_bounce_in_from_left");
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.maxHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.minHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.height="300px";


            }} role="button" className='button-30'>
                <span>
                Xem Báo giá 

                </span>
                <svg fill='#06c' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></button> */}
              {/* <Button text="Báo giá ngay" /> */}
            </>
          ) : (
            <>
              <h2>Project Progress Documentation</h2>
              <p>
                To help investors in keeping their clients updated, Goku
                documents monthly or quarterly progress of the projects through
                multi-angle, cleanly retouched photos and video collections. We
                add excitement and take away the "mess" of the construction
                site.
              </p>

              <p>
                This service is provided during the construction phase of the
                project.
              </p>
            </>
          )}
        </div>
      </div>

      <div className="container_iframe">
        <div className="iframe-item">
          {/* <img alt="Documentation Image" src={img} /> */}
          <iframe
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              borderRadius: "8px",
            }}
            src="https://www.youtube.com/embed/iLo78tieTg4"
            title="The Sea Class"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />
          <p>The Holiday</p>
          {/* <img alt="Documentation Image" src={docu2} />
                <img alt="Documentation Image" src={docu1} /> */}
        </div>
        <div className="iframe-item">
          {/* <img alt="Documentation Image" src={img} /> */}
          <iframe
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              borderRadius: "8px",
            }}
            src="https://www.youtube.com/embed/bdNe9AjU97Y"
            title="The Sea Class"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />
          {/* <img alt="Documentation Image" src={docu2} />
                <img alt="Documentation Image" src={docu1} /> */}
          <p>Him Lam Vạn Phúc</p>
        </div>
        <div className="iframe-item">
          {/* <img alt="Documentation Image" src={img} /> */}
          <iframe
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              borderRadius: "8px",
            }}
            src="https://www.youtube.com/embed/qqkbHf-xCxQ"
            title="The Sea Class"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />
          {/* <img alt="Documentation Image" src={docu2} />
                <img alt="Documentation Image" src={docu1} /> */}
          <p>Lovera Vista</p>
        </div>
        {/* <p>Đội ngũ nhân viên</p> */}
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 50px;
  margin-top: 150px;
  margin-bottom: 150px;
  text-align: center;
  .container_iframe {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    /* height: 400px; */
    margin-top: 40px;
    grid-gap: 26px;
    .iframe-item {
      height: 400px;
      p {
        font-weight: bold;
        margin-top: 20px;
      }
    }
  }

  .text {
    width: 100%;
    /* height: 100%; */
    div {
      width: 100%;

      h2 {
        color: black;
        font-family: SF-Pro-Display-Bold;
        font-size: 42px;
        margin-bottom: 20px;
      }

      p {
        font-size: 22px;
        font-family: SF-Pro-Display-Light;
      }
      p:nth-child(3) {
        span {
          color: black;
          font-family: SF-Pro-Display-Bold;
        }
      }
      p:nth-child(2) {
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 1756px) {
  }
  @media screen and (max-width: 1524px) {
  }
  @media screen and (max-width: 1396px) {
  }
  @media screen and (max-width: 1180px) {
    /* height: 400px; */
    .container_iframe {
      grid-template-columns: 1fr;
      .iframe-item {
        height: 400px;
        margin-bottom: 40px;
      }
    }

    .text {
      div {
        h2 {
          font-size: 36px;
          line-height: 40px;
          width: 100%;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
  @media screen and (max-width: 796px) {
    /* height: 400px; */
    .text {
      div {
        width: 100%;

        h2 {
          font-size: 34px;
          line-height: 38px;
          width: 100%;
        }
        p {
          font-size: 16px;
        }
        p:nth-child(2) {
          margin-bottom: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 660px) {
    /* height: 500px; */
  }
  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    /* height: 350px; */

    text-align: center;
    .text {
      text-align: center;
      div {
        width: 100%;
        h2 {
          font-family: SF-Pro-Display-Bold;
          width: 85%;
          font-size: 28px;
          margin: 0 auto 16px;
          line-height: 34px;
        }
      }
    }
  }
  @media screen and (max-width: 496px) {
    /* height: auto; */
    /* display:  none; */
    /* height: 450px; */
  }
  @media screen and (max-width: 300px) {
    /* height: 250px; */
    /* margin-top: 60px; */

    .text {
      div {
        h2 {
          font-family: SF-Pro-Display-Bold;
          width: 85%;
          font-size: 20px;
          line-height: 24px;
          margin: 0 auto 12px;
        }
        p {
          font-size: 10px;
          font-family: SF-Pro-Display-Regular;
        }
      }
    }
  }
`;

export default Kame360;
