import React from "react";
import styled from "styled-components";
function Button({ text, margin, onclick }) {
  return (
    <Wrapper margin={margin}>
      <a
        href={`${
          onclick
            ? "/service/chup-anh-khach-san-resort"
            : "https://zalo.me/0915444235"
        }`}
      >
        <span>{text}</span>
        <svg
          fill="#06c"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
        </svg>
      </a>
    </Wrapper>
  );
}
const Wrapper = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  margin: ${(props) => {
    if (props.margin) {
      return props.margin;
    } else {
      return "0 auto";
    }
  }};
  justify-content: space-between;
  align-items: center;
  color: #0066cc;
  font-size: 22px;
  line-height: 1.16667;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
  span {
    margin-right: 4px;
    color: #0066cc;
  }
  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export default Button;
