import React from 'react'
import styled from 'styled-components'

import productionleftimg from "../../assets/images/productioncamera.jpeg"
import gokuverseimg from "../../assets/images/gokuverse.png"
import { Link } from 'react-router-dom'
import { useTumysContext } from '../../context/tumycontext'
import Heading from '../Share/Heading'



function Homeservice() {
  const { english,handleLanguage,nav,handleNav} = useTumysContext();

  return (
    <Wrapper>

      <div className='left'>
        <div className='title'>

          <div className='title-box'>
                {english ? (
            <Heading color="black" text="Our Service" />

                ):(
            <Heading color="black" text="Dịch Vụ" />

                )}
         

          {/* <div className='button'>
          {english ? <>More discovery</> : <>Tìm hiểu thêm</>}
 
            <svg
              width="14"
              height="14"
              viewBox="0 0 18 18"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
                fill="black"
              />
            </svg>
          </div> */}

          </div>
        </div>
       <Link aria-label='Sản phẩm của Goku Production' to="/service/gokuproduction">

        <div  className='production' style={{ backgroundImage: `url(${productionleftimg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center" }}>
          {/* <div className='image'>
            <img src={productionleftimg} alt="Goku Production" style={{width: "100%",height:"100%"}} />
          </div> */}
          <div className='layer'></div>
           <div className='production-info'>
            <h1>Goku Production</h1>
            {english ? (
               <p>
             Goku Production provides comprehensive, world-class photography services for Real Estate Projects
            </p>
            ):(
              <p>
              Goku Production cung cấp những dịch vụ chụp hình toàn diện, đẳng cấp cho các dự án bất động sản.
            </p>
            )}
           
            <div className='production-button'>
            Khám phá
           <svg
              width="14"
              height="14"
              viewBox="0 0 18 18"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
                fill="white"
              />
            </svg>

           </div>
           </div>
          
        </div>
       </Link>

      </div>

      <Link to="/service/gokuverse" aria-label='Sản phẩm của Gokuverse'>

      <div style={{ backgroundImage: `url(${gokuverseimg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center" }} className='right'>
        
     <div className='right-title'>
      {/* <div className=''></div> */}
     


      <div className='layer'>
            <h1>Gokuverse</h1>
            {english ? (
 <p>
Software production with the latest image technology including VR and AR.
</p>
            ):(
              <p>
              Gokuverse cung cấp các phần mềm lập trình hiện đại nhất bao gồm cả VR và AR.
            </p>
            )}
           

            <div className="button">
              
            Khám phá
           <svg
              width="14"
              height="14"
              viewBox="0 0 18 18"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
                fill="white"
              />
            </svg>

          
           </div>

      </div>

     </div>
  
      </div>
      </Link>

    </Wrapper>
  )
}

const Wrapper= styled.section`
width: 100vw;
/* height: 800px; */
display: grid;
grid-template-columns: 50% 50%;
padding: 50px 30px;
/* grid-gap: 50px; */
/* height: 500px; */
/* max-height: 500px; */

/* Goku Production */
.left{
  height: 100%;
  width: 98%;
  /* margin-right: auto; */
  /* padding-right: 20px; */
 
  .title{
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* margin-right: 20px; */

    .title-box{
      width: 100%;
      text-align: right;
      /* h1{
        font-family: SF-Pro-Display-Bold;
      color: black;
      font-size: 68px;
      text-transform: none;
      } */
    }
    .button{
      border: 1px solid black;
      border-radius: 20px;
      padding: 4px 10px;
      width: 45%;
      display: flex;
      align-items: center;
      margin-left: auto;
      justify-content: center;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      svg{
        margin-left: 8px;
      }
    }
  }
  .production{
    height: 75%;
  border-radius: 20px;
  position: relative;
  text-align: center  ;
  color: white;
  &:hover .layer{
    display: block;
  }
  &:hover .production-info{
    display: block;
  }
  .image{
    position: absolute;
    top: 0;
    bottom:0 ;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .layer{
    position: absolute;
    top: 0;
    bottom:0 ;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.7);
    border-radius: 20px;
    display: none;
    animation: show 0.7s linear;
  }

  .production-info{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translateX(-50%) translateY(-50%);
    display: none;
    animation: show 1s linear;
    h1{
      font-size: 40px;
    }
    p{
      color: white;
    }
    .production-button{
       border: 1px solid white;
        border-radius: 20px;
        padding: 4px 10px;
        width: 21%;
        display: flex;
        align-items: center;
        margin: 0 auto;
        justify-content: center;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        svg{
          margin-left: 8px;
        }
  
    }
  }




  
    img{
      width: 100%;
      height: 100%;
    }
  }
  
}

 
/* End Goku production */


.right{
  height: 100%;
  width: 98%;
  margin-left: auto;
  /* padding-left: 20px; */
  border-radius: 20px;
  position: relative;
  &:hover .right-title{
    display: flex;
  }


  .right-title{
    position: absolute;
    bottom: 0;
    /* padding: 0 10%; */
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    text-align: center;
    /* text-align: center; */
    display: none;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    animation: show 0.7s linear;
    border-radius: 20px;


  

    .layer{
      width: 80%;
      margin: 0 auto;
      h1{
      color: white;
      /* font-size: 32px; */
      font-size: 40px;

    }
      p{
      color: white;
    }
    .button{
       border: 1px solid white;
        border-radius: 20px;
        padding: 4px 10px;
        width: 21%;
        display: flex;
        align-items: center;
        margin: 0 auto;
        justify-content: center;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        color: white;

        p{
          color: white;
        }
        svg{
          margin-left: 8px;
        }
  
    }
    }
    
  }




  img{
    width: 100%;
    height: 100%;
  }
}
/* Responsive */
@media screen and (max-width:1926px) {
  height: 900px;

  
}
@media screen and (max-width:1566px) {
  height: 800px;

  
}
@media screen and (max-width:1366px) {
  height: 750px;

  
}
@media screen and (max-width: 1024px) {
  height: 700px;
  .left{
     .title{
      /* width: 70%; */
     }
    .production{
      .production-info{
        display: block;
        h1{
          font-size: 32px;
          line-height: 36px;
        }
        p{
          font-size: 12px;
        }
        .production-button{
        width: 35%;
      }
      }
      .layer{
        display: block;
      }
     
    /* display: block;
    z-index: 99; */
   }
  }


  .right{
    .right-title{
      display: flex;
      .layer{
        .button{
          width: 35%;
        }
        h1{
          font-size: 32px;
          line-height: 36px;
        }
        p{
          font-size: 12px;
        }
      }
    }
  }
   
  
}

@media screen and (max-width: 768px) {
  height: 500px;
  padding: 30px 20px;
  .left{
     .title{
      /* width: 70%; */
      .title-box{
        h1{

        }
      }
     }
    .production{
      .production-info{
       
        .production-button{
        width: 45%;
      }
      }
      .layer{
        
      }
     
    /* display: block;
    z-index: 99; */
   }
  }


  .right{
    .right-title{
      display: flex;
      .layer{
        .button{
          width: 45%;
        }
      }
    }
  }
   
  
}

@media screen and (max-width:  596px) {
  height: 400px;
  padding: 30px 20px;
  .left{
     .title{
      /* width: 70%; */
      .title-box{
        h1{

        }
      }
     }
    .production{
      .production-info{
        h1{
          font-size: 24px;
          line-height: 28px;
        }
        p{
          font-size: 10px;
        }
        .production-button{
          width: 60%;
        font-size: 10px;
        svg{
          width: 10px;
          height: 10px;
        }
      }
      }
      .layer{
        
      }
     
    /* display: block;
    z-index: 99; */
   }
  }


  .right{
  
    .right-title{
      display: flex;
      .layer{
        .button{
          width: 60%;
        font-size: 10px;
        svg{
          width: 10px;
          height: 10px;
        }


        }
        h1{
          font-size: 24px;
          line-height: 28px;
        }
        p{
          font-size: 10px;
        }
      }
    }
  }
   
  
}
@media screen and (max-width: 424px){
  height: 300px;
  padding: 0 12px;
  .left{
    .production{
      .production-info{
        h1{
        font-size: 18px;
          line-height: 21px;
      }
      p{
          font-size: 8px;
          margin-bottom: 8px;
        }
        .production-button{
          width: 60%;
        font-size: 8px;
        padding: 2px 8px;
        svg{
          width: 8px;
          height: 8px;
        }


        }
      }
    }
    .title{
      .title-box{
        h1{
          line-height: 38px;
        }
      }
    }
  }
  .right{
    
    .right-title{
      .layer{
      h1{
        font-size: 18px;
          line-height: 21px;
      }
      p{
          font-size: 8px;
          margin-bottom: 8px;
        }
        .button{
          width: 60%;
        font-size: 8px;
        padding: 2px 8px;
        svg{
          width: 8px;
          height: 8px;
        }


        }
    }
    }
   
  }

}

@media screen and (max-width: 324px){
  height: 200px;
  .left{
    .production{
      border-radius: 12px;
      .layer{
      border-radius: 12px;
        
      }
      .production-info{
        h1{
        font-size: 14px;
          line-height: 16px;
      }
      p{
          font-size: 6px;
          margin-bottom: 8px;
        }
        .production-button{
          width: 48%;
        font-size: 6px;
        padding: 2px 4px;
        svg{
          width: 4px;
          height: 4px;
          margin-left: 2px;
        }


        }
      }
    }
  }
  .right{
    border-radius: 12px;
    
    .right-title{
    border-radius: 12px;

      .layer{
      border-radius: 12px;

        h1{
        font-size: 14px;
          line-height: 16px;
      }
      p{
          font-size: 6px;
          margin-bottom: 8px;
        }
        .button{
          width: 48%;
        font-size: 6px;
        padding: 2px 4px;
        svg{
          width: 4px;
          height: 4px;
          margin-left: 2px;
        }


        }
    }
    }
   
  }
}


`

export default Homeservice