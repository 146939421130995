// Link figma dự án: https://www.figma.com/file/WjaEpU5XABPMpEQlKqgUbq/Goku-Profile?type=design&node-id=382-1064&mode=design&t=AojaUrY0FUwSyTWF-0
import React, { useState } from "react";
import "./Profile.scss";
import Nav from "../../components/Share/Nav";
import { useTumysContext } from "../../context/tumycontext";

import profile_prev from "../../assets/images/profile_prev.png";
import profile_next from "../../assets/images/profile_next.png";
import profile_imac from "../../assets/images/profile_imac.png";
import profile_macbook from "../../assets/images/profile_macbook.png";
import profile_ipad from "../../assets/images/profile_ipad.png";
import profile_vector_next from "../../assets/images/profile_vector_next.png";
import profile_vector_prev from "../../assets/images/profile_vector_prev.png";

// Profile Page1
import illustration from "../../assets/images/illustration.png";
import circle_bottom_bg from "../../assets/images/circle_bottom_bg.png";
import profile_4pixos from "../../assets/images/profile_4pixos.png";
import profile_khangdien from "../../assets/images/profile_khangdien.png";
import profile_fresihouse from "../../assets/images/profile_fresihouse.png";
import profile_vingroup from "../../assets/images/profile_vingroup.png";
import profile_page1_bg from "../../assets/images/profile_page1_bg.png";
import profile_page1_text from "../../assets/images/profile_page1_text.png";
import profile_page1_goku_img_left from "../../assets/images/profile_page1_goku_img_left.png";
import profile_page1_goku_img_right from "../../assets/images/profile_page1_goku_img_right.png";
import profile_page1_goku_icon from "../../assets/images/profile_page1_goku_icon.png";
// End Profile Page1

// Profile Page2
import profile_page2_tv from "../../assets/images/profile_page2_tv.png";
import profile_page2_camera from "../../assets/images/profile_page2_camera.png";
// End Profile Page2

// Profile Page3
import missionglobal from "../../assets/images/missionglobal.png";
import missionstar from "../../assets/images/missionstar.png";
import missionhand from "../../assets/images/missionhand.png";
// End Profile Page3

// Profile Page4
import profile_page4_img1 from "../../assets/images/profile_page4_img1.png";
import profile_page4_img2 from "../../assets/images/profile_page4_img2.png";
// End Profile Page4

// Profile Page5
import profile_page5_img from "../../assets/images/profile_page5_img.png";
// End Profile Page5

// Profile Page6
import profile_page6_img from "../../assets/images/profile_page6_img.png";
// End Profile Page6

// Profile Page7
import profile_page7_img1 from "../../assets/images/profile_page7_img1.png";
import profile_page7_img2 from "../../assets/images/profile_page7_img2.png";
import profile_page7_img3 from "../../assets/images/profile_page7_img3.png";
import profile_page7_img4 from "../../assets/images/profile_page7_img4.png";
// End Profile Page7

// Profile Page8
import profile_page8_img from "../../assets/images/profile_page8_img.png";
// End Profile Page8

// Profile Page9
import profile_page9_img1 from "../../assets/images/profile_page9_img1.png";
import profile_page9_img2 from "../../assets/images/profile_page9_img2.png";
import profile_page9_img3 from "../../assets/images/profile_page9_img3.png";
import profile_page9_img4 from "../../assets/images/profile_page9_img4.png";
// End Profile Page9

// Profile Page10
import profile_page10_img from "../../assets/images/profile_page10_img.png";
// End Profile Page10

// Profile Page11
import profile_page11_img1 from "../../assets/images/profile_page11_img1.png";
import profile_page11_img2 from "../../assets/images/profile_page11_img2.png";
import profile_page11_img3 from "../../assets/images/profile_page11_img3.png";
import profile_page11_img4 from "../../assets/images/profile_page11_img4.png";
// End Profile Page11

// Profile Page12
import profile_page12_img1 from "../../assets/images/profile_page12_img1.png";
import profile_page12_img2 from "../../assets/images/profile_page12_img2.png";
import profile_page12_img3 from "../../assets/images/profile_page12_img3.png";
import profile_page12_img4 from "../../assets/images/profile_page12_img4.png";
// End Profile Page12

// Profile Page13
import profile_page13_img from "../../assets/images/profile_page13_img.png";
// End Profile Page13

// Profile Page14
import profile_page14_img1 from "../../assets/images/profile_page14_img1.png";
import profile_page14_img2 from "../../assets/images/profile_page14_img2.png";
import profile_page14_img3 from "../../assets/images/profile_page14_img3.png";
// End Profile Page14

// Profile Page15
import profile_page15_img from "../../assets/images/profile_page15_img.png";
// End Profile Page15

// Profile Page16
import profile_page16_img1 from "../../assets/images/profile_page16_img1.png";
import profile_page16_img2 from "../../assets/images/profile_page16_img2.png";
// End Profile Page16

// Profile Page18
import opeing_tour from "../../assets/video/OPENING-ROTATION.mp4";
// End Profile Page18

// Profile Page19
import hometour_walk from "../../assets/video/hometour_walk.mp4";
import hometour_closeopendoor from "../../assets/video/hometour_closeopendoor.mp4";
import hometour_floorplan from "../../assets/video/hometour_floorplan.mp4";
// End Profile Page19

// Profile Page23
import prolocation_highlightvalue from "../../assets/video/prolocation_highlightvalue.mp4";
import prolocation_dynamic from "../../assets/video/prolocation_dynamic.mp4";
// End Profile Page23

// Profile Page26
import vrmasterplan_rotation from "../../assets/video/vrmasterplan_rotation.mp4";
import vrmasterplan_timeslider from "../../assets/video/vrmasterplan_timeslider.mp4";
import vrmasterplan_productinfo from "../../assets/video/vrmasterplan_productinfo.mp4";
// End Profile Page26

// Profile Page30
import vrwalking_interior from "../../assets/video/vrwalking_interior.mp4";
// End Profile Page30

// Profile Page33
import floorplan_unitdistribution from "../../assets/video/floorplan_unitdistribution.mp4";
// End Profile Page33

// Profile Page35
import vrmodel from "../../assets/video/vrmodel.mp4";
// End Profile Page35

// Profile Page36
import profile_page36_img1 from "../../assets/images/profile_page36_img1.png";
import profile_page36_img2 from "../../assets/images/profile_page36_img2.png";
// End Profile Page36

// Profile Page38
import profile_page38_img1 from "../../assets/images/profile_page38_img1.png";
import profile_page38_img2 from "../../assets/images/profile_page38_img2.png";
// End Profile Page38

// Profile Page39
import profile_page39_img1 from "../../assets/images/profile_page39_img1.png";
import profile_page39_img2 from "../../assets/images/profile_page39_img2.png";
// End Profile Page39

// Profile Page40
import profile_page40_bg from "../../assets/images/profile_page40_bg.png";
import profile_page40_logo from "../../assets/images/profile_page40_logo.png";
import profile_page40_icon1 from "../../assets/images/profile_page40_icon1.png";
import profile_page40_icon2 from "../../assets/images/profile_page40_icon2.png";
import profile_page40_icon3 from "../../assets/images/profile_page40_icon3.png";
import Intro from "./Intro";

// End Profile Page40

// Profile Page 40
const ProfilePage40 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page40_wrapper">
      <div className="Profile_page40_container">
        <div className="Profile_page40_container_content">
          <div className="profile_page40_bg_img">
            <img src={profile_page40_bg} alt="profile_page40_bg" />
          </div>
          <div className="profile_page40_goku_img">
            <img src={profile_page40_logo} alt="profile_page40_logo" />
          </div>
        </div>

        <div className="Profile_page40_container_footer">
          <ul>
            <li>
              <div>
                <img src={profile_page40_icon1} alt="profile_page40_icon1" />
              </div>
              <span>Văn phòng TP Hồ Chí Minh</span>
              <p>
                Tầng 9, Toà nhà DMC Building, <br />
                341-343 Điện Biên Phủ, Phường 15,
                <br />
                Quận Bình Thạnh, TP.Hồ Chí Minh
              </p>
            </li>
            <li>
              <div>
                <img src={profile_page40_icon2} alt="profile_page40_icon1" />
              </div>
              <span>Liên hệ</span>
              <p>
                Email: thang@goku.agency
                <br />
                Website: www.goku.agency
                <br />
                Phone: 0988 878 656
              </p>
            </li>
            <li>
              <div>
                <img src={profile_page40_icon3} alt="profile_page40_icon1" />
              </div>
              <span>Văn phòng TP Hà Nội</span>
              <p>
                Tầng 2, Toà nhà Tây Hà, 19 Tố Hữu,
                <br />
                Phường Trung Văn, Quận Nam Từ
                <br />
                Liêm, TP. Hà Nội
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(39)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page40

// Profile Page 39
const ProfilePage39 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page39_wrapper">
      <div className="Profile_page39_container">
        <h1>Testimonial</h1>
        <div className="Profile_page39_container_img">
          <img src={profile_page39_img1} alt="profile_page39_img" />
          <img src={profile_page39_img2} alt="profile_page39_img" />
        </div>
      </div>
      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(38)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(40)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page39

// Profile Page 38
const ProfilePage38 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page38_wrapper">
      <div className="Profile_page38_container">
        <img
          className="profile_page38_img1"
          src={profile_page38_img1}
          alt="profile_page38_img1"
        />
        <img
          className="profile_page38_img2"
          src={profile_page38_img2}
          alt="profile_page38_img2"
        />
      </div>
      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(36)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(39)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page38

// Profile Page 36
const ProfilePage36 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page36_wrapper">
      <div className="Profile_page36_container">
        <div className="Profile_page36_title">
          <h1>Dự án GokuVerse</h1>
        </div>
        <div className="Profile_page36_body">
          <div className="page36_body_content">
            <div className="page36_body_content_img">
              <img src={profile_page36_img1} alt="profile_page36_img1" />
            </div>
            <p>The Ancruising</p>
            <span>Thành phố Nha Trang</span>
          </div>

          <div className="page36_body_content">
            <div className="page36_body_content_img">
              <img src={profile_page36_img2} alt="profile_page36_img1" />
            </div>
            <p>The Filmore</p>
            <span>Thành phố Đà Nẵng</span>
          </div>
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(35)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(38)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page36

// Profile Page35
const ProfilePage35 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page35_wrapper">
      <div className="Profile_page35_container">
        <div className="page35_content_right">
          <h1>VR Model</h1>
          <p>
            Giải pháp trải nghiệm 3D toàn diện nhất với đầy đủ các tính năng
            được tích hợp.
          </p>
        </div>
        <div className="page35_content_left">
          <img src={profile_ipad} alt="profile_ipad" />

          {/* <img src={profile_page16_img1} alt="profile_ipad" /> */}
          <video loop playsInline autoPlay muted>
              <source src={vrmodel} type="video/mp4" />
            </video>

          {/* <video playsInline autoPlay muted>
            <source src={profile_page16_img1} type="video/mp4" />
          </video> */}
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(33)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(36)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page35

// Profile Page33 > 34/ gộp 34 thành action
const ProfilePage33 = ({ setPageNumber }) => {
  const [page33_active_btn, setPage33_active_btn] = useState(1);
  return (
    <section className="Profile_page33_wrapper">
      <div className="Profile_page33_container">
        <div className="page33_content_left">
          <img src={profile_ipad} alt="profile_ipad" />

          {page33_active_btn === 1 && (
            // <img src={profile_page16_img1} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={floorplan_unitdistribution} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="page33_content_right">
          <h1>Floor Plan</h1>
          <p>Đơn giản và hiệu quả khi tìm thông tin từng sản phẩm.</p>

          <div className="page33_right_btn_container">
            <div
              onClick={() => setPage33_active_btn(1)}
              className={`profile_page33_btn ${
                page33_active_btn === 1 ? `btn_left_active` : null
              }`}
            >
              <div className="profile_page33_btn_left">
                <img src={profile_vector_next} alt="profile_vector_next" />
              </div>
              <div>
                <h3>Unit Distribution</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(30)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(35)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page33 > 34

// Profile Page30 > 32/ gộp 31/32 thành action
const ProfilePage30 = ({ setPageNumber }) => {
  const [page30_active_btn, setPage30_active_btn] = useState(1);
  return (
    <section className="Profile_page30_wrapper">
      <div className="Profile_page30_container">
        <div className="page30_content_right">
          <h1>VR Walking</h1>
          <p>
            Trải nghiệm các tiện ích, không gian căn hộ toàn diện với cảm xúc
            chân thật nhất.
          </p>

          <div className="page30_right_btn_container">
            <div
              onClick={() => setPage30_active_btn(1)}
              className={`profile_page30_btn ${
                page30_active_btn === 1 ? `btn_left_active` : null
              }`}
            >
              <h3>Interior</h3>
              <div className="profile_page30_btn_left">
                <img src={profile_vector_prev} alt="profile_vector_prev" />
              </div>
            </div>

            <div
              onClick={() => setPage30_active_btn(2)}
              className={`profile_page30_btn ${
                page30_active_btn === 2 ? `btn_left_active` : null
              }`}
            >
              <h3>Landscape</h3>
              <div className="profile_page30_btn_left">
                <img src={profile_vector_prev} alt="profile_vector_prev" />
              </div>
            </div>
          </div>
        </div>
        <div className="page30_content_left">
          <img src={profile_ipad} alt="profile_ipad" />

          {page30_active_btn === 1 && (
            // <img src={profile_page16_img1} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={vrwalking_interior} type="video/mp4" />
            </video>
          )}
          {page30_active_btn === 2 && (
            // <img src={profile_page16_img2} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={opeing_tour} type="video/mp4" />
            </video>
          )}

          {/* <video playsInline autoPlay muted>
            <source src={profile_page16_img1} type="video/mp4" />
          </video> */}
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(26)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(33)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page30>32

// Profile Page26 > 29/ gộp 27/28/29 thành action
const ProfilePage26 = ({ setPageNumber }) => {
  const [page26_active_btn, setPage26_active_btn] = useState(1);
  return (
    <section className="Profile_page26_wrapper">
      <div className="Profile_page26_container">
        <div className="page26_content_left">
          <img src={profile_ipad} alt="profile_ipad" />

          {page26_active_btn === 1 && (
            // <img src={profile_page16_img1} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={vrmasterplan_rotation} type="video/mp4" />
            </video>
          )}
          {page26_active_btn === 2 && (
            // <img src={profile_page16_img2} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={vrmasterplan_timeslider} type="video/mp4" />
            </video>
          )}
          {page26_active_btn === 3 && (
            // <img src={profile_page16_img1} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={vrmasterplan_productinfo} type="video/mp4" />
            </video>
          )}

          {/* <video playsInline autoPlay muted>
            <source src={profile_page16_img1} type="video/mp4" />
          </video> */}
        </div>
        <div className="page26_content_right">
          <h1>VR MasterPlan</h1>
          <p>Đa chức năng mang lại trải nghiệm tuyệt vời.</p>

          <div className="page26_right_btn_container">
            <div
              onClick={() => setPage26_active_btn(1)}
              className={`profile_page26_btn ${
                page26_active_btn === 1 ? `btn_left_active` : null
              }`}
            >
              <div className="profile_page26_btn_left">
                <img src={profile_vector_next} alt="profile_vector_next" />
              </div>
              <div>
                <h3>Project Rotation</h3>
                <h4>Xoay quanh dự án.</h4>
              </div>
            </div>

            <div
              onClick={() => setPage26_active_btn(2)}
              className={`profile_page26_btn ${
                page26_active_btn === 2 ? `btn_left_active` : null
              }`}
            >
              <div className="profile_page26_btn_left">
                <img src={profile_vector_next} alt="profile_vector_next" />
              </div>
              <div>
                <h3>Time Slider</h3>
                <h4>Xem dự án ở nhiều thời điểm.</h4>
              </div>
            </div>

            <div
              onClick={() => setPage26_active_btn(3)}
              className={`profile_page26_btn ${
                page26_active_btn === 3 ? `btn_left_active` : null
              }`}
            >
              <div className="profile_page26_btn_left">
                <img src={profile_vector_next} alt="profile_vector_next" />
              </div>
              <div>
                <h3>Product Info</h3>
                <h4>Hiển thị thông tin sản phẩm.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(23)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(30)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page26 > 29

// Profile Page23 > 25/ gộp 24/25 thành action
const ProfilePage23 = ({ setPageNumber }) => {
  const [page23_active_btn, setPage23_active_btn] = useState(1);
  return (
    <section className="Profile_page23_wrapper">
      <div className="Profile_page23_container">
        <div className="page23_content_right">
          <h1>Pro Location</h1>
          <p>
            Bản đồ vị trí chuyên nghiệp liên kết với các tiện ích, khu vực xung
            quanh.
          </p>

          <div className="page23_right_btn_container">
            <div
              onClick={() => setPage23_active_btn(1)}
              className={`profile_page23_btn ${
                page23_active_btn === 1 ? `btn_left_active` : null
              }`}
            >
              <h3>Highlight Value</h3>
              <div className="profile_page23_btn_left">
                <img src={profile_vector_prev} alt="profile_vector_prev" />
              </div>
            </div>

            <div
              onClick={() => setPage23_active_btn(2)}
              className={`profile_page23_btn ${
                page23_active_btn === 2 ? `btn_left_active` : null
              }`}
            >
              <h3>Dynamic Location</h3>
              <div className="profile_page23_btn_left">
                <img src={profile_vector_prev} alt="profile_vector_prev" />
              </div>
            </div>
          </div>
        </div>
        <div className="page23_content_left">
          <img src={profile_ipad} alt="profile_ipad" />

          {page23_active_btn === 1 && (
            // <img src={profile_page16_img1} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={prolocation_highlightvalue} type="video/mp4" />
            </video>
          )}
          {page23_active_btn === 2 && (
            // <img src={profile_page16_img2} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={prolocation_dynamic} type="video/mp4" />
            </video>
          )}

          {/* <video playsInline autoPlay muted>
            <source src={profile_page16_img1} type="video/mp4" />
          </video> */}
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(19)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(26)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page23 > 25

// Profile Page19 > 22/ gộp 20/21/22 thành action
const ProfilePage19 = ({ setPageNumber }) => {
  const [page19_active_btn, setPage19_active_btn] = useState(1);
  return (
    <section className="Profile_page19_wrapper">
      <div className="Profile_page19_container">
        <div className="page19_content_left">
          <img src={profile_ipad} alt="profile_ipad" />

          {page19_active_btn === 1 && (
            // <img src={profile_page16_img1} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={hometour_walk} type="video/mp4" />
            </video>
          )}
          {page19_active_btn === 2 && (
            // <img src={profile_page16_img2} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={hometour_closeopendoor} type="video/mp4" />
            </video>
          )}
          {page19_active_btn === 3 && (
            // <img src={profile_page16_img1} alt="profile_ipad" />
            <video loop playsInline autoPlay muted>
              <source src={hometour_floorplan} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="page19_content_right">
          <h1>Home Tour</h1>
          <p>Tham quan và trải nghiệm nhà mẫu từ xa.</p>

          <div className="page19_right_btn_container">
            <div
              onClick={() => setPage19_active_btn(1)}
              className={`profile_page19_btn ${
                page19_active_btn === 1 ? `btn_left_active` : null
              }`}
            >
              <div className="profile_page19_btn_left">
                <img src={profile_vector_next} alt="profile_vector_next" />
              </div>
              <h3>Walk</h3>
            </div>

            <div
              onClick={() => setPage19_active_btn(2)}
              className={`profile_page19_btn ${
                page19_active_btn === 2 ? `btn_left_active` : null
              }`}
            >
              <div className="profile_page19_btn_left">
                <img src={profile_vector_next} alt="profile_vector_next" />
              </div>
              <h3>Close/open door or curtain</h3>
            </div>

            <div
              onClick={() => setPage19_active_btn(3)}
              className={`profile_page19_btn ${
                page19_active_btn === 3 ? `btn_left_active` : null
              }`}
            >
              <div className="profile_page19_btn_left">
                <img src={profile_vector_next} alt="profile_vector_next" />
              </div>
              <h3>Floor Plan</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(18)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(23)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page19 > 22

// Profile Page18
const ProfilePage18 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page18_wrapper">
      <div className="Profile_page18_container">
        <div className="page18_content_left">
          <h1>Opening Tour</h1>
          <h3>
            <a
              href="https://360.goku.agency/gokuverse/"
              target="_blank"
              rel="noreferrer"
            >
              GokuVerse
            </a>{" "}
            là sản phẩm phần mềm Website và App sử dụng các công nghệ cao như
            VR, AR, ... để phục vụ Chủ đầu tư bán sản phẩm và marketing hiệu
            quả.
          </h3>
        </div>
        <div className="page18_content_right">
          <img src={profile_macbook} alt="profile_macbook" />
          {/* <video
            playsInline
            autoPlay
            muted
          >
            <source src={opeing_tour} type="video/mp4" />
          </video> */}
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(17)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(19)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page18

// Profile Page17
const ProfilePage17 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page17_wrapper">
      <div className="Profile_page17_container">
        <h1>GokuVerse</h1>
        <div className="Profile_page17_body">
          <h2>
            <a
              href="https://360.goku.agency/gokuverse/"
              target="_blank"
              rel="noreferrer"
            >
              GokuVerse
            </a>{" "}
            là sản phẩm phần mềm Website và App sử dụng các công nghệ cao như
            VR, AR, ... để phục vụ Chủ đầu tư bán sản phẩm và marketing hiệu
            quả.
          </h2>
          <div className="page17_body_imac_content">
            <div className="profile_imac_iframe">
              {/* <iframe title="gokuverse" src="http://360.goku.agency/gokuverse"></iframe> */}
            </div>
            <div className="profile_imac_img">
              <img src={profile_imac} alt="profile_imac" />
            </div>
          </div>
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(16)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(18)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page17

// Profile Page 16
const ProfilePage16 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page16_wrapper">
      <div className="Profile_page16_container">
        <div className="Profile_page16_title">
          <h1>Chụp ảnh 360°</h1>
          <p style={{ color: "#0071E3" }}>
            Giai đoạn các phần của dự án đã hoàn thiện
          </p>
        </div>
        <div className="Profile_page16_body">
          <div className="page16_body_content">
            <div className="page16_body_content_img">
              <img src={profile_page16_img1} alt="profile_page16_img1" />
            </div>
            <p>The Classia</p>
            <span>Thành phố Hồ Chí Minh</span>
          </div>

          <div className="page16_body_content">
            <div className="page16_body_content_img">
              <img src={profile_page16_img2} alt="profile_page16_img1" />
            </div>
            <p>The Nhon Hoi New City</p>
            <span>Thành phố Quy Nhơn</span>
          </div>
        </div>

        <div className="Profile_more_btn">Xem thêm</div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(15)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(17)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page16

// Profile Page15
const ProfilePage15 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page15_wrapper">
      <div className="Profile_page15_container">
        <img src={profile_page15_img} alt="profile_page13_img" />
        <p>
          <span> *Giai đoạn thực hiện:</span>Khi có các phần của dự án đã hoàn
          thiện.
        </p>
      </div>
      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(14)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(16)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page13

// Profile Page 14
const ProfilePage14 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page14_wrapper">
      <div className="Profile_page14_container">
        <div className="Profile_page14_title">
          <h1>Chụp ảnh/quay phim Cityscape</h1>
        </div>
        <div className="Profile_page14_body">
          <div className="page14_body_content">
            <div className="page14_body_content_img">
              <img src={profile_page14_img1} alt="profile_page14_img1" />
            </div>
            <p>Hà Nội Cityscape</p>
          </div>

          <div className="page14_body_content">
            <div className="page14_body_content_img">
              <img src={profile_page14_img2} alt="profile_page14_img1" />
            </div>
            <p>Nha Trang Cityscape</p>
          </div>

          <div className="page14_body_content">
            <div className="page14_body_content_img">
              <img src={profile_page14_img3} alt="profile_page14_img1" />
            </div>
            <p>Hồ Chí Minh Cityscape</p>
          </div>
        </div>
        <div className="Profile_more_btn">Xem thêm</div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(13)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(15)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page14

// Profile Page13
const ProfilePage13 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page13_wrapper">
      <div className="Profile_page13_container">
        <img src={profile_page13_img} alt="profile_page13_img" />
        <p>
          <span> *Giai đoạn thực hiện:</span>Khi có các phần của dự án đã hoàn
          thiện.
        </p>
      </div>
      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(12)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(14)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page13

// Profile Page 12
const ProfilePage12 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page12_wrapper">
      <div className="Profile_page12_container">
        <div className="Profile_page12_title">
          <h1>Chụp ảnh/quay phim tổng thể dự án</h1>
          <p style={{ color: "#0071E3" }}>
            Giai đoạn các phần của dự án đã hoàn thiện
          </p>
        </div>
        <div className="Profile_page12_body">
          <div className="page12_body_content">
            <div className="page12_body_content_img">
              <img src={profile_page12_img1} alt="profile_page12_img1" />
            </div>
            <p>The Dragon Castle</p>
            <span>Thành phố Hạ Long</span>
          </div>

          <div className="page12_body_content">
            <div className="page12_body_content_img">
              <img src={profile_page12_img2} alt="profile_page12_img1" />
            </div>
            <p>The Urban Garden</p>
            <span>Thành phố Hồ Chí Minh</span>
          </div>

          <div className="page12_body_content">
            <div className="page12_body_content_img">
              <img src={profile_page12_img3} alt="profile_page12_img1" />
            </div>
            <p>The Angsana & Dhawa Ho Tram</p>
            <span>Bà Rịa - Vũng Tàu</span>
          </div>

          <div className="page12_body_content">
            <div className="page12_body_content_img">
              <img src={profile_page12_img4} alt="profile_page12_img1" />
            </div>
            <p>D’.Palais De Louis</p>
            <span>Thành phố Hà Nội</span>
          </div>
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(11)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(13)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page12

// Profile Page 11
const ProfilePage11 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page11_wrapper">
      <div className="Profile_page11_container">
        <div className="Profile_page11_title">
          <h1>Chụp ảnh/quay phim tổng thể dự án</h1>
          <p style={{ color: "#0071E3" }}>
            Giai đoạn các phần của dự án đã hoàn thiện
          </p>
        </div>
        <div className="Profile_page11_body">
          <div className="page11_body_content">
            <div className="page11_body_content_img">
              <img src={profile_page11_img1} alt="profile_page11_img1" />
            </div>
            <p>The Sky Oasis</p>
            <span>Thành phố Hưng Yên</span>
          </div>

          <div className="page11_body_content">
            <div className="page11_body_content_img">
              <img src={profile_page11_img2} alt="profile_page11_img1" />
            </div>
            <p>The IFC</p>
            <span>Thành phố Hồ Chí Minh</span>
          </div>

          <div className="page11_body_content">
            <div className="page11_body_content_img">
              <img src={profile_page11_img3} alt="profile_page11_img1" />
            </div>
            <p>The Vinhomes Grand Park</p>
            <span>Thành phố Hồ Chí Minh</span>
          </div>

          <div className="page11_body_content">
            <div className="page11_body_content_img">
              <img src={profile_page11_img4} alt="profile_page11_img1" />
            </div>
            <p>The Arena</p>
            <span>Thành phố Cam Ranh</span>
          </div>
        </div>

        <div className="Profile_more_btn">Xem thêm</div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(10)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(12)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page11

// Profile Page10
const ProfilePage10 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page10_wrapper">
      <div className="Profile_page10_container">
        <img src={profile_page10_img} alt="profile_page10_img" />
        <p>
          <span> *Giai đoạn thực hiện:</span>Khi có các phần của dự án đã hoàn
          thiện.
        </p>
      </div>
      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(8)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(11)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page10

// Profile Page 9
const ProfilePage9 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page9_wrapper">
      <div className="Profile_page9_container_wrapper">
        <div className="Profile_page9_container_left">
          <div className="Profile_page9_title">
            <h1>Chụp ảnh/quay phim tiến độ dự án</h1>
            <p style={{ color: "#0071E3" }}>
              Giai đoạn công trình đang xây dựng
            </p>
          </div>
          <div className="Profile_more_btn">Xem thêm</div>
        </div>
        <div className="Profile_page9_container_right">
          <div className="page9_container_right_top">
            <div className="page9_container_right_top_img">
              <img src={profile_page9_img1} alt="" />
            </div>
            <p>
              The Metropole Thủ Thiêm <span>Thành phố Hồ Chí Minh</span>
            </p>
          </div>
          <div className="page9_container_right_bottom">
            <div className="page9_container_right_bottom_content">
              <div className="page9_container_right_bottom_img">
                <img src={profile_page9_img2} alt="" />
              </div>
              <p>The Him Lam Vạn Phúc</p>
              <span>Thành phố Hà Nội</span>
            </div>

            <div className="page9_container_right_bottom_content">
              <div className="page9_container_right_bottom_img">
                <img src={profile_page9_img3} alt="" />
              </div>
              <p>The De La Sol</p>
              <span>Thành phố Hồ Chí Minh</span>
            </div>

            <div className="page9_container_right_bottom_content">
              <div className="page9_container_right_bottom_img">
                <img src={profile_page9_img4} alt="" />
              </div>
              <p>The Summerland</p>
              <span>Thành phố Phan Thiết</span>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(8)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(10)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page9

// Profile Page8
const ProfilePage8 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page8_wrapper">
      <div className="Profile_page8_container">
        <img src={profile_page8_img} alt="profile_page8_img" />
        <p>
          <span> *Giai đoạn thực hiện:</span>Khi công trình đang xây dựng.
        </p>
      </div>
      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(7)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(9)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page8

// Profile Page 7
const ProfilePage7 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page7_wrapper">
      <div className="Profile_page7_container">
        <div className="Profile_page7_title">
          <h1>Chụp ảnh/quay phim CGI</h1>
          <p style={{ color: "#0071E3" }}>
            Giai đoạn diễn họa hình ảnh và làm phim dự án
          </p>
        </div>
        <div className="Profile_page7_body">
          <div className="page7_body_content">
            <div className="page7_body_content_img">
              <img src={profile_page7_img1} alt="profile_page7_img1" />
            </div>
            <p>The Light City</p>
            <span>Thành phố Vũng Tàu</span>
          </div>

          <div className="page7_body_content">
            <div className="page7_body_content_img">
              <img src={profile_page7_img2} alt="profile_page7_img1" />
            </div>
            <p>The Shizen Home</p>
            <span>Thành phố Hồ Chí Minh</span>
          </div>

          <div className="page7_body_content">
            <div className="page7_body_content_img">
              <img src={profile_page7_img3} alt="profile_page7_img1" />
            </div>
            <p>The Stella Residence</p>
            <span>Thành phố Hồ Chí Minh</span>
          </div>

          <div className="page7_body_content">
            <div className="page7_body_content_img">
              <img src={profile_page7_img4} alt="profile_page7_img1" />
            </div>
            <p>The King Crown Infinity</p>
            <span>Thành phố Hồ Chí Minh</span>
          </div>
        </div>

        <div className="Profile_more_btn">Xem thêm</div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(6)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(8)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page7

// Profile Page6
const ProfilePage6 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page6_wrapper">
      <div className="Profile_page6_container">
        <img src={profile_page6_img} alt="profile_page6_img" />
        <p>
          <span> *Giai đoạn thực hiện:</span>Giai đoạn diễn họa hình ảnh và làm
          phim dự án.
        </p>
      </div>
      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(5)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(7)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page6

// Profile Page5
const ProfilePage5 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page5_wrapper">
      <div className="Profile_page5_container">
        <h1>Goku Production</h1>
        <img src={profile_page5_img} alt="profile_page5_img" />
      </div>
      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(4)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(6)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page5

// Profile Page4
const ProfilePage4 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page4_wrapper">
      <div className="Profile_page4_container">
        {/* <div className="page4_img1"> */}
        <img src={profile_page4_img1} alt="profile_page4_img1" />
        {/* </div> */}
        {/* <div className="page4_img2"> */}
        <img src={profile_page4_img2} alt="profile_page4_img2" />
        {/* </div> */}
      </div>

      <div className="Profile_page4_footer">
        <p>
          <span>*Goku.agency</span> cung cấp hai dịch vụ chính.
        </p>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(3)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(5)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page4

// Profile Page 3
const ProfilePage3 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page3_wrapper">
      <div className="Profile_page3_container">
        <div className="Profile_page3_title">
          <h1>More than words. </h1>
          <h1>An agency you can trust.</h1>
          <p style={{ color: "#0071E3" }}>Sứ mệnh. Tầm nhìn. Giá trị cốt lõi</p>
        </div>
        <div className="Profile_page3_body">
          <div className="page3_body_content">
            <div className="page3_body_content_icon">
              <img src={missionstar} alt="icon" />
            </div>
            <p>
              Giúp Chủ đầu tư Bất động sản có những hình ảnh và website/app{" "}
              <span style={{ color: "#FF0000" }}>chuyên nghiệp</span> phục vụ
              bán hàng và marketing hiệu quả.
            </p>
          </div>

          <div className="page3_body_content">
            <div className="page3_body_content_icon">
              <img src={missionglobal} alt="icon" />
            </div>
            <p>
              Sản xuất hình ảnh và phần mềm cho ngành Bất động sản với chất
              lượng đạt{" "}
              <span style={{ color: "#68CC45" }}>Tiêu chuẩn Quốc tế</span>.
            </p>
          </div>

          <div className="page3_body_content">
            <div className="page3_body_content_icon">
              <img src={missionhand} alt="icon" />
            </div>
            <p>Chất lượng Chuyên Nghiệp Chính trực Minh Bạch</p>
          </div>
        </div>

        <div className="Profile_more_btn">Xem thêm</div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(2)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(4)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page3

// Profile Page2
const ProfilePage2 = ({ setPageNumber }) => {
  return (
    <section className="Profile_page2_wrapper">
      <div className="Profile_page2_nav">
        <span>Dịch vụ toàn diện dành cho chủ đầu tư Bất động sản.</span>
      </div>

      <div className="Profile_page2_body">
        <div className="Profile_page2_body_tv">
          <img className="profile_page2_tv" src={profile_page2_tv} alt="" />
        </div>
        <div className="Profile_page2_body_camera">
          <img
            className="profile_page2_camera"
            src={profile_page2_camera}
            alt=""
          />
        </div>
        <div className="Profile_page2_body_content">
          <h1 className="page2_body_content_title">Goku.agency</h1>
          <p className="page2_body_content">
            Goku là đơn vị đầu tiên cung cấp dịch vụ sản xuất hình ảnh toàn diện
            cho các Dự án Bất động sản. Năm 2020, chúng tôi ra mắt thêm sản phẩm
            lập trình GokuVerse kết hợp công nghệ VR và AR mới nhất cho website
            và app bán hàng dự án. Tới nay, chúng tôi đã trở thành doanh nghiệp
            tiên phong trong lĩnh vực với hơn 100 dự án trên toàn quốc cho các
            Chủ đầu tư Bất động sản trong và ngoài nước.
          </p>
          <p className="page2_body_content_since">Hoạt động từ năm 2018</p>
        </div>
      </div>

      <div className="Profile_slide_btn">
        <div
          className="Profile_slide_btn_prev"
          onClick={() => setPageNumber(1)}
        >
          <img className="profile_prev" src={profile_prev} alt="profile_prev" />
        </div>
        <div
          className="Profile_slide_btn_next"
          onClick={() => setPageNumber(3)}
        >
          <img className="profile_next" src={profile_next} alt="profile_next" />
        </div>
      </div>
    </section>
  );
};
// End Profile Page2

// Profile Page1
const profile_menu = [
  {
    id: 1,
    text: "Chụp ảnh/quay phim CGI",
    link: "https://goku.agency/service/gokuproduction",
  },
  {
    id: 2,
    text: "Chụp ảnh/quay phim tiến độ dự án",
    link: "https://goku.agency/service/gokuproduction",
  },
  {
    id: 3,
    text: "Chụp ảnh/quay phim tổng thể dự án",
    link: "https://goku.agency/service/gokuproduction",
  },
  {
    id: 4,
    text: "Chụp ảnh 360°",
    link: "https://goku.agency/service/gokuproduction",
  },
  {
    id: 5,
    text: "Chụp ảnh/quay phim Cityscape",
    link: "https://goku.agency/service/gokuproduction",
  },
];

const ProfilePage1 = () => {
  const [pageNumber, setPageNumber] = useState(1);
  return (
    <div style={{ position: "fixed", top: "0" }}>
      {pageNumber === 1 && (
        <section className="Profile_wrapper">
          <div className="Profile_menu">
            {profile_menu.map((item, index) => (
              <a key={index} href={item.link} className="Profile_menu_text">
                {item.text}
              </a>
            ))}
          </div>
          <div className="Profile_center">
            <div className="Profile_center_bg">
              <img className="profile_page1_bg" src={profile_page1_bg} alt="" />
            </div>
            <div className="Profile_center_text">
              <img
                className="profile_page1_text"
                src={profile_page1_text}
                alt=""
              />
            </div>
          </div>
          <div className="Profile_bottom">
            <div className="Profile_bottom_circle_bottom_bg">
              <img
                className="circle_bottom_bg"
                src={circle_bottom_bg}
                alt="circle_bottom_bg"
              />
            </div>
            <div className="Profile_bottom_illustration">
              <img
                className="illustration"
                src={illustration}
                alt="illustration"
              />
            </div>
          </div>
          <div className="Profile_cdt">
            <div className="cdt_icon" style={{ top: "75%", left: "10%" }}>
              <img src={profile_4pixos} alt="" />
            </div>
            <div className="cdt_icon" style={{ top: "25%", left: "70%" }}>
              <img src={profile_khangdien} alt="" />
            </div>
            <div className="cdt_icon" style={{ top: "70%", left: "85%" }}>
              <img src={profile_fresihouse} alt="" />
            </div>
            <div className="cdt_icon" style={{ top: "22%", left: "25%" }}>
              <img src={profile_vingroup} alt="" />
            </div>
          </div>
          <div className="Profile_goku_img">
            <div className="Profile_goku_img_left">
              <img
                className="profile_page1_goku_img_left"
                src={profile_page1_goku_img_left}
                alt="profile_page1_goku_img_left"
              />
            </div>
            <div className="Profile_goku_img_right">
              <img
                className="profile_page1_goku_img_right"
                src={profile_page1_goku_img_right}
                alt="profile_page1_goku_img_right"
              />
            </div>
          </div>
          <div className="Profile_project">
            <h1>200+</h1>
            <span>Dự án</span>
          </div>
          <div className="Profile_contact">
            <div className="Profile_contact_img">
              <img
                className="profile_page1_goku_icon"
                src={profile_page1_goku_icon}
                alt=""
              />
            </div>
            <div className="Profile_contact_content">
              <h3>Goku.agency</h3>
              <span>thang@goku.agency</span>
            </div>
            <div className="Profile_contact_contact">
              <a href="http://goku.agency/" target="_blank" rel="noreferrer">
                Liên Hệ
              </a>
            </div>
          </div>
          <div className="Profile_slide_btn">
            {/* <div className="Profile_slide_btn_prev">
            <img
              className="profile_prev"
              src={profile_prev}
              alt="profile_prev"
            />
          </div> */}
            <div
              className="Profile_slide_btn_next"
              onClick={() => setPageNumber(2)}
            >
              <img
                className="profile_next"
                src={profile_next}
                alt="profile_next"
              />
            </div>
          </div>
        </section>
      )}

      {pageNumber === 2 && <ProfilePage2 setPageNumber={setPageNumber} />}
      {pageNumber === 3 && <ProfilePage3 setPageNumber={setPageNumber} />}
      {pageNumber === 4 && <ProfilePage4 setPageNumber={setPageNumber} />}
      {pageNumber === 5 && <ProfilePage5 setPageNumber={setPageNumber} />}
      {pageNumber === 6 && <ProfilePage6 setPageNumber={setPageNumber} />}
      {pageNumber === 7 && <ProfilePage7 setPageNumber={setPageNumber} />}
      {pageNumber === 8 && <ProfilePage8 setPageNumber={setPageNumber} />}
      {pageNumber === 9 && <ProfilePage9 setPageNumber={setPageNumber} />}
      {pageNumber === 10 && <ProfilePage10 setPageNumber={setPageNumber} />}
      {pageNumber === 11 && <ProfilePage11 setPageNumber={setPageNumber} />}
      {pageNumber === 12 && <ProfilePage12 setPageNumber={setPageNumber} />}
      {pageNumber === 13 && <ProfilePage13 setPageNumber={setPageNumber} />}
      {pageNumber === 14 && <ProfilePage14 setPageNumber={setPageNumber} />}
      {pageNumber === 15 && <ProfilePage15 setPageNumber={setPageNumber} />}
      {pageNumber === 16 && <ProfilePage16 setPageNumber={setPageNumber} />}
      {pageNumber === 17 && <ProfilePage17 setPageNumber={setPageNumber} />}
      {pageNumber === 18 && <ProfilePage18 setPageNumber={setPageNumber} />}
      {pageNumber === 19 && <ProfilePage19 setPageNumber={setPageNumber} />}
      {pageNumber === 23 && <ProfilePage23 setPageNumber={setPageNumber} />}
      {pageNumber === 26 && <ProfilePage26 setPageNumber={setPageNumber} />}
      {pageNumber === 30 && <ProfilePage30 setPageNumber={setPageNumber} />}
      {pageNumber === 33 && <ProfilePage33 setPageNumber={setPageNumber} />}
      {pageNumber === 35 && <ProfilePage35 setPageNumber={setPageNumber} />}
      {pageNumber === 36 && <ProfilePage36 setPageNumber={setPageNumber} />}
      {pageNumber === 38 && <ProfilePage38 setPageNumber={setPageNumber} />}
      {pageNumber === 39 && <ProfilePage39 setPageNumber={setPageNumber} />}
      {pageNumber === 40 && <ProfilePage40 setPageNumber={setPageNumber} />}
    </div>
  );
};

const Profile = () => {
  const { nav } = useTumysContext();
  return (
    <>
      {nav ? (
        <Nav />
      ) : (
        <>
          <Intro />
          <ProfilePage1 />
        </>
      )}
    </>
  );
};
// End Profile Page1

export default Profile;
