import React from 'react'
import styled from 'styled-components'
import photo from "../../assets/images/photo360.jpg"
import { useTumysContext } from '../../context/tumycontext';

function Photography360() {
  const { english,handleLanguage,nav,handleNav} = useTumysContext();

  return (
    <Wrapper>
        <div className='text'>
            {english ? (
                <>
                <h1>360 Photography</h1>
                <p>We take 360 photos of the project to demonstrate the location, progress, and so on.</p>
                </>

            ):(
             <>
              <h1>Chụp ảnh 360°</h1>
                <p>Goku sẽ chụp ảnh 360° cho dự án để thể hiện vị trí, tiến độ, ...</p>
             </>
            )}

        </div>

        <div className='img'>
            <img src={photo} />
            <p className='note'>
                DELASOL CAPITAL LAND
            </p>
        </div>

    </Wrapper>
  )
}

const Wrapper = styled.div`
width: 100%;
/* height: 550px; */
text-align: center;
margin-top: 100px;
margin-bottom: 200px;
.text{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    h1{
      flex-basis: 50%;
      color: black;
            font-family: SF-Pro-Display-Bold;
            font-size: 42px;
    /* align-items: center; */
    text-align: right;

    }
    p{
        flex-basis: 50%;
        font-size: 22px;
        font-family: SF-Pro-Display-Light;
    /* align-items: left; */
    text-align: left;
    margin-bottom: 0;



    }

}
.img{
    width: 100%;
    position: relative;
    img{
    border-radius: 14px;
    height: 100%;
    width: 100%;

    }
    .note{
        position: absolute;
        right: -12%;
        top: 50%;
        
        color: #c8c9c9;
        font-family: SF-Pro-Display-Light;
        font-size: 20px;
        transform: rotate(90deg) translateY(-50%);
    }
}

@media screen and (max-width: 1396px) {
    .text{
        h1{

        }
    }
}

@media screen and (max-width: 1024px) {
    height: auto;
    .text{
        h1{
                font-size: 36px;
                line-height: 40px;
                /* width: 100%; */
            }
            p{
                font-size: 18px;
            }
    }
    .img{
        .note{
        position: absolute;
        right: -17%;
       
        font-size: 20px;
        transform: rotate(90deg) translateY(-50%);
    }
    }
}
@media screen and (max-width: 796px) {
    .text{

        h1{
                    font-size: 34px;
                    line-height: 38px;
                    /* width: 100%; */
                }

                p{
                font-size: 16px;
            }
    }
}
@media screen and (max-width: 650px) {
    height: auto;
    margin-top: 60px;

    .text{
            flex-direction: column;
            gap: 0;
        h1{
                    font-size: 28px;
                    line-height: 34px;
                    /* width: 100%; */
                    text-align: center;
                }
        p{
            /* font-size: 16px; */
            text-align: center;
        }
    }
    .img{
        height: 300px;
        /* width: 100vw; */
        img{
            height: 85%;
            /* width: 100vw; */
        }
        .note{
        /* position: absolute; */
        /* right: -17%; */
        position: static;
        left: 50%;
        margin-top: 8px;
        
        /* border-bottom: -; */
       
        font-size: 12px;
        transform: rotate(0) translateX(0);
    }
    }
    
}
@media screen and (max-width: 496px) {
    
}

@media screen and (max-width: 300px) {
    margin-top: 60px;
    .text{
        h1{

            width: 85%;
            font-size: 20px;
            line-height: 24px;
            margin: 0 auto 12px;
        }
        p{
                font-size: 10px;
            }
    }
}


`

export default Photography360