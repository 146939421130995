import React from "react";
import styled from "styled-components";
import sologanblack from "../../assets/images/gokulogoblack.png";
// import coverkame from "../../assets/images/coverkame.jpeg";
import Header from "../Share/Header";
import Button from "./Button";

function Intro() {
  return (
    <Wrapper>
      <Header
        logoBlack={sologanblack}
        iconBlack={
          <svg
            width="36"
            height="24"
            viewBox="0 0 36 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
              fill="#484848"
            />
          </svg>
        }
        fixed
        icon={
          <svg
            width="36"
            height="24"
            viewBox="0 0 36 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
              fill="#484848"
            />
          </svg>
        }
        logo={sologanblack}
      />
      <div className="info">
        <h1>DỊCH VỤ CHỤP ẢNH & QUAY PHIM TIẾN ĐỘ DỰ ÁN BẤT ĐỘNG SẢN</h1>
        <p>
          Goku sẽ mang lại cho Quý Chủ đầu tư những hình ảnh tiến độ dự án
          chuyên nghiệp nhất với các gói dịch vụ phù hợp với từng nhu cầu riêng.
        </p>
        <p>
          Từ <span>6,800,000 đ/Tháng</span>
        </p>

        {/* <button onClick={()=>{
                document.querySelector(".fb_customer_chat_bounce_out_v2").classList.remove("fb_customer_chat_bounce_in_from_left");
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.maxHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.minHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.height="300px";


            }} className="button-30" role="button">Chat ngay</button> */}
        <Button text="Chat ngay" />
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  background-color: #f4f5f6;
  padding-bottom: 220px;
  /* padding-top: 220px; */
  /* text-align: center; */
  .info {
    text-align: center;
    /* margin-top: 40px; */
    width: 80%;
    margin: 80px auto 0;

    h1 {
      font-size: 42px;
      color: black;
      font-family: SF-Pro-Display-Bold;
      margin-bottom: 40px;
    }
    p {
      font-size: 18px;
      color: black;
      span {
        background: -webkit-linear-gradient(to right, #c21500, #ffc500);
        background: linear-gradient(to right, #c21500, #ffc500);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        font-size: 20px;
        margin-left: 6px;
      }
    }
  }

  .button-30 {
    border: none;
    background-color: transparent;
    color: #06c;
    font-size: 24px;
    line-height: 1.16667;
    font-weight: 600;
    cursor: pointer;
  }

  .button-30:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 1024px) {
    padding-bottom: 120px;
    .info {
      h1 {
        font-size: 42px;
        line-height: 46px;
      }
    }
  }
  @media screen and (max-width: 896px) {
  }
  @media screen and (max-width: 796px) {
    .info {
      width: 90%;

      h1 {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
  @media screen and (max-width: 596px) {
  }
  @media screen and (max-width: 424px) {
    padding-bottom: 90px;
    /* .info {
      width: 90%;

      h1 {
        font-size: 26px;
        line-height: 30px;
      }
    } */
  }
  @media screen and (max-width: 320px) {
    padding-bottom: 60px;

    .info {
      width: 70%;
      h1 {
        font-size: 24px;
        line-height: 30px;
      }
      p {
        font-size: 14px;
      }
    }
  }
`;

export default Intro;
