import React, { useEffect, useState } from "react";

import styled from "styled-components";
import client40 from "../../assets/images/client40.png";
import client40vie from "../../assets/images/client40vie.png";

import quote from "../../assets/images/quote.png";
import thanglong from "../../assets/images/thanglong.png";
import vin from "../../assets/images/vin.jpg";
import kusto from "../../assets/images/kusto.jpg";
import khangdien from "../../assets/images/khangdien.png";
import bamboo from "../../assets/images/bamboo.png";
import eco from "../../assets/images/eco.jpg";
import masterise from "../../assets/images/masterise.png";
// import r from "../../assets/images/bamboo.png"
import danhkhoi from "../../assets/images/danhkhoi.png";
import gamudaland from "../../assets/images/gamudaland.png";
import angia from "../../assets/images/angia.png";
import filmore from "../../assets/images/filmore.png";
import quotecircle from "../../assets/images/quotecircle.png";
import quotecircleactive from "../../assets/images/quoteactive.png";
import capitaland from "../../assets/images/capitaland.png";
import pixosdexter from "../../assets/images/4pixosdexter.png";
import mrDexterNguyen from "../../assets/images/mrDexterNguyen.jpg";
import khang_dien from "../../assets/images/khang-dien.png";
import ecopark from "../../assets/images/Ecopark-logo.png";
import thang_long_real from "../../assets/images/thang-long-real.png";

import { useTumysContext } from "../../context/tumycontext";
import Heading from "../Share/Heading";

function Homepartner() {
  const [active, setActive] = useState(1);
  const { english, handleLanguage, nav, handleNav } = useTumysContext();

  const data = [
    {
      id: 1,
      img: vin,
    },
    {
      id: 2,
      img: thanglong,
    },
    {
      id: 3,
      img: khangdien,
    },
    {
      id: 4,
      img: capitaland,
    },
    {
      id: 5,
      img: gamudaland,
    },
    {
      id: 6,
      img: danhkhoi,
    },
    {
      id: 7,
      img: bamboo,
    },
    {
      id: 8,
      img: angia,
    },
    {
      id: 9,
      img: kusto,
    },
    {
      id: 10,
      img: masterise,
    },
    {
      id: 11,
      img: eco,
    },
    {
      id: 12,
      img: filmore,
    },
  ];

  const datacomment = [
    // {
    //   id: 1,
    //   text: "GOKU mang đến chất lượng dịch vụ vượt trội hơn cả sự mong đợi của khách hàng. Sản phẩm ưng ý ngay từ bản đầu tiên. GOKU luôn chuyên nghiệp và tạo được sự an tâm khi hợp tác cùng bất kì dịch vụ từ lớn đến nhỏ.",
    //   name: "Mr.Dexter Nguyen",
    //   position: "FOUNDER AT 4PIXOS",
    //   img: mrDexterNguyen,
    // },
    {
      id: 1,
      text: "Hài lòng, chất lượng tốt, hỗ trợ và xử lý nhanh.",
      name: "Mr. Trung",
      position: "KHANG ĐIỀN GROUP",
      img: khang_dien,
    },
    // {
    //   id : 3,
    //   text : "Chuyên nghiệp, vượt kỳ vọng.",
    //   name : "Mr.Dexter Nguyen",
    //   position : "FOUNDER AT 4PIXOS",
    //   img: pixosdexter
    // },
    {
      id: 2,
      text: "Chất lượng sản phẩm và dịch vụ đến từ Goku rất đáng khen",
      name: "Ecopark",
      position: "CÔNG TY CỔ PHẦN TẬP ĐOÀN ECOPARK",
      img: ecopark,
    },
    {
      id: 3,
      text: "Ngưỡng mộ Goku vì luôn hết mình với sản phẩm của mình, luôn để ý tới những tiểu tiết có lợi cho khách hàng của mình",
      name: "Thang Long Real",
      position: "THANG LONG REAL GROUP",
      img: thang_long_real,
    },
  ];
  useEffect(() => {
    setTimeout(() => {
      if (active >= datacomment.length) {
        setActive(1);
      } else {
        setActive(active + 1);
      }
    }, 8000);
  }, [active]);

  return (
    <Wrapper>
      <div className="title">
        <div className="title-img">
          <img
            alt="Partner Number"
            style={{ width: "80%" }}
            src={english ? client40 : client40vie}
          ></img>
        </div>
        {english ? (
          <Heading color="black" text="Our Partner" />
        ) : (
          <Heading color="black" text="Đối tác" />
        )}
        {/* <h1>{english ? "Our Partner" : "Đối tác của chúng tôi"}</h1> */}
      </div>

      <div className="company">
        {data.map((item, index) => {
          return (
            <div key={index}>
              <img alt="Company" style={{ width: "100%" }} src={item.img} />
            </div>
          );
        })}
      </div>

      <div className="quote">
        <div className="quote-line">
          <img alt="Quote Icon" src={quote} />
          <div className="line"></div>
        </div>
        {datacomment.map((item, index) => {
          // console.log(item.id)
          return (
            <div
              key={index}
              className={
                item.id === active ? "quote-content active" : "quote-content"
              }
            >
              <div className="quote-info">
                <h3>"{item.text}"</h3>
              </div>

              <div className="quote-person">
                <div className="quote-person-left">
                  <div className="circle">
                    {/* <div className='circle-image'></div> */}
                    {/* <img alt="Person" src={pixosdexter} /> */}
                    <img alt="Person" src={item.img} />
                  </div>

                  <div className="name">
                    <p className="name-text">{item.name}</p>
                    <p className="name-position">{item.position}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="quote-person-right">
          {datacomment.map((item, index) => {
            return (
              <img
                alt=""
                key={index}
                onClick={() => setActive(item.id)}
                src={item.id === active ? quotecircleactive : quotecircle}
              />
            );
          })}

          {/* <img src={quotecircle} /> */}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: block;
  min-height: 500px;
  padding: 50px 30px;

  .title {
    display: flex;
    align-items: center;
    padding: 0 120px;
    /* margin-left: 20%; */
    gap: 20px;
    /* height: 200px; */
    .title-img {
      /* width: 100%; */
      height: 100%;
      img {
        width: 100%;

        /* height: 100%; */
      }
    }
    /* h1 {
      margin-top: 30px;
      font-family: SF-Pro-Display-Bold;
      color: black;
      font-size: 68px;
       text-transform: none;
    } */
  }

  /* Partner */

  .company {
    display: grid;

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 60px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      margin: 0 auto;
      /* display: none; */
      img {
        /* margin: 0 auto; */
      }
    }
  }

  /* End Partner */
  .quote {
    margin-top: 50px;
    /* display: none; */

    .quote-line {
      display: flex;
      width: 70%;
      margin: 20px auto;
      gap: 30px;
      img {
        width: 5%;
      }
      .line {
        width: 85%;
        height: 1px;
        background-color: #d9d9d9;
        margin-top: 10px;
        align-self: flex-start;
      }
    }
    .quote-content {
      display: none;
      min-height: 170px;
    }
    .quote-content.active {
      animation: slideinright 0.5s linear;
      display: block !important;
    }

    .quote-info {
      width: 60%;
      margin: 0 auto;

      h3 {
        font-family: SF-Pro-Display-Medium;
        font-size: 17px;
        font-weight: 550;
        margin-left: 40px;
        margin: 0 0 30px 0;
        line-height: 23px;
      }
    }
    .quote-person {
      display: flex;
      justify-content: space-between;
      width: 60%;
      margin: 0 auto;

      .quote-person-left {
        display: flex;
        align-items: center;
        gap: 14px;
        .circle {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          border: 1px solid black;
          padding: 4px;
          .circle-image {
            border-radius: 50%;

            width: 100%;
            height: 100%;
            border: 1px solid black;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .name {
          p {
            margin: 0;
          }
          .name-text {
            font-weight: 600;
            font-size: 16px;
          }
          .name-position {
            font-style: italic;
            font-size: 14px;
          }
        }
      }
    }
  }
  .quote-person-right {
    display: flex;
    height: 20%;
    width: 20%;
    margin-left: 70%;
    img {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1080px) {
    padding: 40px 20px;
    .title {
      padding: 0 40px;
    }
    .company {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .quote {
      .quote-line {
        width: 95%;
      }
      .quote-info {
        width: 80%;
      }
    }
  }
  @media screen and (max-width: 796px) {
    padding: 30px 20px;
    .title {
      padding: 0 10px;
    }

    .company {
      grid-template-columns: 1fr 1fr;
      div {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: 424px) {
    .company {
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 324px) {
    padding: 30px 20px;
    .title {
      padding: 0 10px;
    }

    .company {
      margin-top: 20px;
      /* grid-template-columns: 1fr 1fr; */
    }
    .quote {
      margin-top: 25px;
      .quote-line {
        width: 95%;
      }
      .quote-info {
        width: 80%;
        h3 {
          font-size: 12px;
          line-height: 14px;
        }
      }
      .quote-person {
        width: 90%;
        .quote-person-left {
          .name {
            .name-text {
              font-size: 12px;
            }
            .name-position {
              font-size: 10px;
            }
          }
        }
      }
      .quote-content {
        min-height: 150px;
      }
    }
    .quote-person-right {
      display: flex;
      height: 20%;
      width: 20%;
      margin-left: 55%;
      img {
        width: 8px;
        height: 8px;
        margin-right: 6px;
        cursor: pointer;
      }
    }
  }
`;

export default Homepartner;
