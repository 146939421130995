import React, { useState } from 'react'
import styled from 'styled-components'
import img1 from "../../assets/images/actual-facility.jpg"
import img2 from "../../assets/images/actual-showunit.jpg"
import img3 from "../../assets/images/actual-whole.jpg"
import { useTumysContext } from '../../context/tumycontext'
import video1 from "../../assets/video/test.mp4"
import video2 from "../../assets/video/test1.mp4"



function Actualproject() {
    const [active,setActive]  = useState(1)
  const { english,handleLanguage,nav,handleNav} = useTumysContext();
  
  const data = [
    {
        id : 1,
        src: img2,
        type: "image",
        text: "Show-unit"
    },
    {
        id : 2,
        src: img1,
        type: "image",
        text: "Facilities"
    },
    {
        id : 3,
        src: img3,
        type: "image",
        text: "Whole Project"
    },

  ]
  return (
    <Wrapper>
        {!english ?<h2>Chụp ảnh Công trình</h2> :
        <h2>Actual Projects  Photography/Videography</h2>
        }
                {/* <h2>Chụp ảnh/quay phim tổng thể dự án</h2> */}

        <div className='container'>
             <div className='left'>
          

            <div className='box'>
                {/* <ul className='control'>
                    {data.map((item,index)=>{
                        return (
                            <li key={index} className={item.id===active && "active"} onClick={()=>setActive(item.id)} >{item.text}</li>
                        )
                    })}

                </ul> */}
                    
                          {data.map((item,index)=>{
                    return (

                        <>
                        {item.id === active && (
                            <>
                             {item.type ==="image" ? (
                             <div key={index} className='img-box'>

                             <img alt="Project Image" src={item.src} />
             
                           
                             </div>
                        ):(
                            <div key={index} className='video-box'>
                            <div className='video'>
                            <video autoPlay muted loop src={item.src} />
                            </div>
                       
        
                        </div>
                        )}
                            </>
                        )}
                       
                        </>
                    )
                })}
                      
              
                
              


               
            </div>


        </div>
        <div className='right'>
            <div className='text'>
                {english?
                <>
                

            <p>
            To bring investor's idea to life, Goku provides high-quality videos and/or photos of the areas surrounding the real-estate project, which are subsequently layered into the project's 3D videos or photos. We give home-buyers the most realistic and immersive view of the project in its embryonic stage. 
            </p>
            <p>This service is provided during the 3D modeling phase.</p>
                </>:
                
                
                <>
          

            <p>
            Goku với kinh nghiệm chụp trên 100 công trình toàn quốc, chúng tôi khẳng định sẽ mang lại một bộ ảnh công trình đẹp nhất cho Quý khách.
            </p>
            {/* <p><span>*Giai đoạn thực hiện:</span> Khi có các phần của dự án đã hoàn thiện.</p> */}
            <p>Từ  <span>6,900,000 đ</span></p>
            <button onClick={()=>{
                document.querySelector(".fb_customer_chat_bounce_out_v2").classList.remove("fb_customer_chat_bounce_in_from_left");
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.maxHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.minHeight="calc(100% - 84px)";
                document.querySelector(".fb_customer_chat_bounce_out_v2").style.height="300px";


            }} role="button" className='btn-price'>Xem Báo giá</button>
                </>}
            
            </div>
        </div>
        </div>
       
        
    </Wrapper>
  )
}
const Wrapper = styled.div`
width: 100%;
margin-top: 100px;

background-color: white;
padding: 40px 40px 40px;
border-radius: 16px ;
.btn-price {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.btn-price:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.btn-price:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.btn-price:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

&> h2{
            color: black;
            font-family: SF-Pro-Display-Bold;
            font-size: 42px;
            line-height: 50px;
            width: 70%;
            text-align: left;
        }
.container{
    width: 100%;
    /* height: 100%; */
/* height: 450px; */

display: grid;
grid-template-columns: 50% 50%;
/* height: 500px; */
}
/* grid-gap: 30px; */
/* margin-bottom: 60px; */

.left{
    width: 100%;
        /* height: 100%; */
        /* display: flex;
        align-items: center; */
        /* margin: auto 0; */
        display: flex;
        justify-content: center;
        align-items: center;
   
    .box{
        width: 100%;
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
        justify-content: space-evenly;
        /* margin: auto 0; */
        .control{
            width: 100%;
            /* height: 100%; */
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 20px;
            li{
                font-family: SF-Pro-Display-Bold;
                color: #888888;
                font-size: 18px;
                cursor: pointer;
            }
            li.active{
                background: linear-gradient(90.07deg, #2EEE86 3.12%, #26BEEE 92.02%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
            }


        }
        .img-box{
            width: 100%;
            /* height: 40%; */
            /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
           
            
            img{
                /* display: none; */
                /* height: 100%; */
                width: 100%;
                height: 350px;
                border-radius: 10px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
              
            }
            
           
            
        }
        .video-box{
            position: relative;
                width: 100%;

                height: 350px;
                .video{
                    position: absolute;
                    width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      right: 0;
      video{
        width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;

      }
            }
        }
        
    }
    
       
    
    
   
}
.right{
.text{
    margin-left: 40px;
    margin-top: 8%;
    /* h2:nth-child(1){
        margin-bottom: 0;
    } */
   
    p{
        font-size: 22px;
        font-family: SF-Pro-Display-Light;
    }
    p:nth-child(2){
        margin-bottom: 40px;
    }
    p:nth-child(2){
        span{
            color: black;
            font-family: SF-Pro-Display-Bold;
        }
       
    }
}
}

@media screen and (max-width : 1024px) {
    margin-top: 0px;

   &>h2{
    font-size: 36px;

   }
   .right{
    .text{
        p{
            font-size: 18px;
            
        }
    }
   }
    
}
@media screen and (max-width : 796px) {
    &>h2{
    font-size: 34px;
    line-height: 38px;

   }
   .left{
    .box{
        .control{
           li{
            text-align: center;
            font-size: 13px;
           }
        }
        .img-box{
            img{
                height: 300px;
            }
        }
        .video-box{
            height: 300px;

            .video{
                video{
                }
            }
        }
       
    }
   }
   .right{
    .text{
        p{
            font-size: 16px;
            
        }
    }
   }
    
}
@media screen and (max-width : 650px) {
    padding: 30px 10px 0;
     margin-top: 60px;
     &>  h2{
                font-family: SF-Pro-Display-Bold;
        width: 85%;
        font-size: 28px;
        margin: 0 auto 16px;
        line-height: 34px;
        text-align: center;
            }
        .container{
            grid-template-columns: 1fr;
            text-align: center;
        }
        .right{
    .text{
        margin-left: 0;

        p{
            font-size: 16px;
            
        }
    }
   }

   .left{
    .box{
        .control{
            li{
                font-size: 12px;
            }
        }
    }
   }
    
}
@media screen and (max-width : 496px) {
    .left{
        .box{
            .control{
                li{
                    font-size: 14px;
                }
            }
        }
    }
}
@media screen and (max-width : 424px) {
    
    /* &> h2{
                font-family: SF-Pro-Display-Bold;
        width: 85%;
        font-size: 20px;
        line-height: 24px;
        margin: 0 auto 12px;
            } */

            .left{
                .box{
                    .control{
                        li{
                            font-size: 12px;
                        }
                    }

                }
            }
}
@media screen and (max-width : 300px) {
      &> h2{
                font-family: SF-Pro-Display-Bold;
        width: 85%;
        font-size: 20px;
        line-height: 24px;
        margin: 0 auto 12px;
            }
    .right{
    .text{
        p{
            font-size: 10px;
            
        }
       
        
    }
   }
   .left{
                .box{
                    .control{
                        li{
                            font-size: 10px;
                        }
                    }

                }
            }
}

`

export default Actualproject