import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
// import sologan from "../../assets/images/sologanintro.png";
import iconheader from "../../assets/images/iconheaderhomeintro.png";
import { useTumysContext } from '../../context/tumycontext';
import { Link } from 'react-router-dom';
import cameraimage from "../../assets/images/camera.png"
import { useMediaQuery } from 'usehooks-ts';
function Header({icon,logo,height,camera,fixed,iconBlack,logoBlack}) {
  const { english, handleLanguage, nav, handleNav } = useTumysContext();
  const refContainer = useRef();
  const headerLogoContainer = useRef();
  // const headerIconContainer = useRef();

  const [iconmenu,setIconmenu] = useState(icon)
  const [sologan,setSologan] = useState(logo)
  const matches = useMediaQuery('(max-width: 690px)')
 const handleScroll = ()=>{
  var top = window.scrollY
  // console.log(top)

//  console.log(top)
if(refContainer.current !== null){
if(fixed && top > 150 ){
refContainer.current.style.padding = "10px 30px";
refContainer.current.style.position = "fixed";
refContainer.current.style.background = "white";
refContainer.current.style.boxShadow = "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px";
setIconmenu(iconBlack);
setSologan(logoBlack);
if(matches){
  headerLogoContainer.current.style.width = "50px";

}else{
headerLogoContainer.current.style.width = "80px";

}
// document.querySelector(".header-icon img").style.height = "26px";
// document.querySelector(".header-icon img").style.height = "18px"



}else{
refContainer.current.style.padding = "30px";
refContainer.current.style.position = "relative";
refContainer.current.style.background = "transparent";
refContainer.current.style.boxShadow = "none";
setIconmenu(icon);
setSologan(logo);
if(matches){
  headerLogoContainer.current.style.width = "80px";

}else{
  headerLogoContainer.current.style.width = "120px";

}




}
}
 }
 useEffect(()=>{
  if(fixed){
    window.addEventListener("scroll", handleScroll);
  }
  return ()=>{
    window.removeEventListener("scroll", handleScroll);
  }
 },[])
  
 
  return (
    <Wrapper ref={refContainer} fixed={fixed} height={height}>
      <Link to="/home">
<div ref={headerLogoContainer} className="header-logo">
                        <img 
                        style={{ width: "100%", height: "85%" }}
                        alt="sologan"
                        src={sologan}
                        />
                    </div>
        </Link> 
                    <div onClick={()=>{
            if(nav === true){
              handleNav(false)
            }else{
              handleNav(true)
            }
          }} className="header-icon">
                        {iconmenu}
                    </div>
                {camera && 
                <div className='camera'>
                <img src={cameraimage} />

              </div>
                }
                    
    </Wrapper>
  )
}


const Wrapper = styled.div`
 width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid rgba(0,0,0,0.1); */
    padding: 30px;
    min-height: ${props => props.height};
    position: relative;
    top: 0;
    left: 0;
    transition: all linear 0.1s;
    /* background-color:transparent; */
    /* position: relative; */
    z-index: 999999999;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */

    .camera{
      position: absolute;
    bottom: -60%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;
      
    }

    .header-logo {
      width: 120px;
      img {
      }
    }

    .header-icon {
      img {
        width: 30px;
      }
      cursor: pointer;
    }
    @media screen and (max-width: 596px) {
      padding: 15px;
      align-items: center;
      .header-logo{
        width: 80px;
      }
      .header-icon{
        display: flex;
        align-items: center;
        /* margin-bottom: 10px; */
        img{
          width: 20px;
        }
        svg{
          width: 26px;
        }
      }
      
    }

    @media screen and (max-width: 300px) {
      padding: 15px;
      .header-logo{
        width: 80px;
      }
      .header-icon{
        display: flex;
        align-items: center;
        /* margin-bottom: 10px; */
        img{
          width: 20px;
        }
        svg{
          width: 24px;
        }
      }
      
    }
`

export default Header