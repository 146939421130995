import React, { useEffect } from "react";
import Blog from "../components/Gokublog/Blog";
// import Gokunews from '../components/Gokublog/Gokunews';
import Intro from "../components/Gokublog/Intro";
import Pagenumber from "../components/Gokublog/Pagenumber";
import Nav from "../components/Share/Nav";
import { useTumysContext } from "../context/tumycontext";
import { Helmet } from "react-helmet-async";

function Gokublog() {
  const { perPage, english, nav, fetchBlog, currentNumberBlog } =
    useTumysContext();

  useEffect(() => {
    fetchBlog(`${english ? "en" : "vi"}`, 3, perPage, currentNumberBlog);
    // fetchBlog(`${"en"}`,currentNumberBlog,10);

    // console.log(currentpageBlog)
  }, [english, currentNumberBlog]);
  // useEffect(()=>{
  //   console.log(currentNumberBlog)
  // },[currentNumberBlog])

  // useEffect(() => {
  //   document.title ="Goku - Blog";
  //   document.getElementsByTagName("META")[4].content="Goku Blog";
  //   document.getElementsByTagName("META")[7].content="Goku Blog";

  // }, []);
  useEffect(() => {
    // updateMeta(`KAME BLOG`,`Our blog is dedicated to providing valuable insights and information on a wide range of topics and much more.`,"https://goku.agency/img/thumb-min.png",`https://goku.agency/blog`)
  }, []);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="https://goku.agency/img/favicon.png" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content="#000000" />

        <meta
          data-rh="true"
          name="description"
          content="Our blog is dedicated to providing valuable insights and information on a wide range of topics and much more."
        />

        <title>BLOG</title>
        <meta data-rh="true" name="robots" content="all" />

        <meta data-rh="true" property="og:title" content="BLOG" />
        <meta
          data-rh="true"
          property="og:description"
          content="Our blog is dedicated to providing valuable insights and information on a wide range of topics and much more."
        />
        <meta data-rh="true" property="og:type" content="website" />
        <meta
          data-rh="true"
          property="og:url"
          content="https://goku.agency/blog"
        />
        <meta
          data-rh="true"
          property="og:image"
          content="https://goku.agency/img/rsz_goku.png"
        />
        <meta data-rh="true" property="og:image:alt" content="Goku" />
        <meta data-rh="true" property="og:image:width" content="1200" />
        <meta data-rh="true" property="og:image:height" content="628" />
        <link rel="canonical" href="https://goku.agency/blog" />
      </Helmet>
      {nav ? (
        <Nav />
      ) : (
        <>
          <Intro />
          {/* <Body /> */}
          <Blog />
          <Pagenumber />

          {/* <Gokunews /> */}
        </>
      )}
    </>
  );
}

export default Gokublog;
