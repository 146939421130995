import React, { useEffect } from "react";
import styled from "styled-components";
import video from "../../assets/video/test1.mp4";

function Content1({ data }) {
  useEffect(()=>{
    // fetchBlog(`${english ? "en" : "vi"}`,1,10);
    document.querySelectorAll(".item p video").forEach((item)=>{
      item.loop = true;
      item.autoplay = true;
      item.muted = true;
    })
},[])
  return (
    <Wrapper>
      <div className="container">
        <div
          dangerouslySetInnerHTML={{
            __html: `
            ${data.content}

            

               
           
                
                `,
          }}
          className="item"
        >
          {/* <div class="iframe-box">
                <iframe src="http://360.goku.agency/fiato360/"></iframe>

                <em>Web 360 Demo dự án<em> -
                <a href="http://360.goku.agency/fiato360">Link dự án</a>
                </div> */}
        </div>
        <div className="line"></div>
        <div className="share">
          <div className="feature">
            {data.categories &&
              data.categories.map((item,index) => {
                return <p key={index}>{item.name}</p>;
              })}
          </div>

          <div className="button">
            <p>Share</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  .container {
    width: 100%;
    /* padding: 40px 0; */
    .item {
      width: 100%;
      &>p{
        margin-bottom: 10px;

      }
      p {
        /* height: 100%; */
        /* height: 550px; */
        /* margin: 0 auto 60px; */
        text-align: center;
        &>img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
        }
      }
      p {
        /* position: relative;
        margin-bottom: 10px;
        height: 800px;
        display: flex;
        justify-content: center;
        text-align: center; */

        /* align-items: flex-start; */

        &>video {
          border-radius: 10px;
          height: 800px;
          width: 100%;
          /* height: 100%; */
          object-fit: cover;
          /* margin-bottom: 20px; */
        }
      }
      p {
        text-align: center;
        &>em {
          text-align: center;
          font-size: 16px;
          display: block;
        margin-bottom: 60px;

        }
      }

      p {
        width: 100%;
        margin: 10px auto;
        color: #484848;
        line-height: 36px;
        text-align: left;
        font-size: 18px;
      }
      .text {
        margin-bottom: 30px;
      }
      p {
        text-align: center;
        &>iframe {
          width: 100%;
          height: 600px;
          border-radius: 10px;
          border: none;
        }
      }
    }
  }
  .share {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
    .feature {
      display: flex;
      p {
        font-size: 16px;
        color: #484848;
        padding: 4px 8px;
        border: 0.4px solid #c6c6c6;
        margin-right: 8px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid #c6c6c6;
      border-radius: 6px;
      height: 30px;
      /* min-height: 20px; */
      padding: 4px 12px;
      p {
        font-family: SF-Pro-Display-Bold;
        margin-bottom: 0;
        background-color: rgba(226, 117, 38, 1);
        background-image: linear-gradient(
          90deg,
          rgba(226, 117, 38, 1) 0%,
          rgba(148, 206, 25, 1) 34.38%,
          rgba(25, 119, 206, 1) 66.67%,
          rgba(190, 107, 255, 1) 100%
        );
        /* background: -webkit-linear-gradient(to right, #FDFBFB, #EBEDEE 70%); */

        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }
    }
  }

  .line {
    background-color: #484848;
    height: 0.2px;
    width: 100%;
    margin-top: 60px;
  }

  @media screen and (max-width: 1396px) {
    .share {
      .feature {
        width: 56%;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 10px 0px 30px;
    text-align: center;

    .container {
      /* padding: 20px 0; */
      .item {
        text-align: center;
        /* p {
          text-align: justify;
        } */
        .image {
          margin-bottom: 0;
        }

        p {

          &>video {
            /* height: 93%; */
          height: 300px;

          }
        }
        p {
          /* margin-bottom: 50px; */
          &>iframe {
            height: 450px;
            width: 100%;
            /* width: 100%; */

border-radius: 10px;
border: none;

          }
        }
      }
      .line {
        margin-top: 80px;
      }
    }
    .share {
      .feature {
        width: 56%;
      }
    }
  }
  @media screen and (max-width: 796px) {
    padding: 10px 0px 120px;
    text-align: center;

    .container {
      .item {
        p {
          width: 85%;
          /* text-align: justify; */
        }
        .text {
          text-align: justify;
        }
        .image {
          margin-bottom: 0;
        }
        p>em {
          font-size: 16px;
          line-height: 20px;
        }
        p {
          /* height: 400px; */

          .video {
            height: 400px;

            video {
              border-radius: 12px;
            }
          }
        }
        p{
          /* margin-bottom: 40px; */

          iframe {
            height: 400px;
            width: 100%;

          }
        }
      }
    }
    .share {
      .feature {
        width: 59%;
      }
    }
  }
  @media screen and (max-width: 596px) {
    padding: 10px 0px 60px;

    .container {
      .item {
        p {
          /* width: 80%;
          text-align: justify; */
          width: 100%;
        }
        .text {
          text-align: justify;
        }

        .image {
          margin-bottom: 0;
        }
        p {
          em {
            font-size: 14px;
            line-height: 18px;
          }
        }
        p {
          /* height: 400px; */
          /* margin-bottom: 30px; */

          &>video{
          height: 400px;

          }
        }
        p {
          margin-bottom: 0px;

          iframe {
            height: 300px;
            width: 100%;
            border-radius: 8px;
          border: none;
          }
        }
      }
      .line {
        margin-top: 60px;
      }
    }
    .share {
      .feature {
        width: 68%;
        p {
          font-size: 9px;
          padding: 6px 12px;
          line-height: 13px !important;
        }
      }
      .button {
        font-size: 12px;
        padding: 2px 8px;
      }
    }
  }
  @media screen and (max-width: 424px) {
    padding: 10px 10px 40px;

    .share {
      .feature {
        width: 70%;
        p {
          font-size: 8px;
          padding: 3px 8px;
          line-height: 8px !important;
        }
      }
    }

    .container {
      .item {
       &>p{
        margin-bottom: 0;
       }
        p {
          /* margin-bottom: 20px; */

         
            /* height: 88%; */
            &>video {
              border-radius: 12px;
          height: 300px;
          /* margin-bottom: 20px; */


            }
         
          &>em {
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 25px;
          }
        }

      
        p {
          &>em {
            font-size: 12px;
            line-height: 16px;
          }
        }
        p {
          margin-bottom: 0;
          &>iframe {
            height: 200px;
            border-radius: 10px;
          border: none;
          }
        }
      }
      .line {
        margin-top: 60px;
      }
    }
  }
  @media screen and (max-width: 324px) {
    padding: 0px 0px 20px;
    margin-top: 10px;

    .container {
      /* margin-bottom */
      .line {
        margin-top: 60px;
      }
      .item {
        p {
          font-size: 14px;
          line-height: 30px;
        }
        .text {
          text-align: justify;
        }
        p{
          video{
            height: 250px;
          margin-bottom: 10px;
          }
         
        }
        p {
          img{
            margin-bottom: 10px;

          }
        }
        
        p {
          &>iframe {

            height: 180px;
            width: 100%;

            border-radius: 10px;
          border: none;
          }
        }
      }
    }

    .share {
      .feature {
        width: 70%;
        p {
          font-size: 6px;
          padding: 3px 4px;
          line-height: 8px !important;
        }
      }
    }
  }
`;

export default Content1;
