import React from 'react'
import styled from 'styled-components'
import SingleFeature from './SingleFeature'
import img from "../../assets/images/singleproject1.png"
import video from "../../assets/video/singleproject.mp4"
// import SingleFeature from '../Gokuverseservice/SingleFeature'
function Feature({data}) {
   
  return (
    <Wrapper>
        {data.feature.map((item)=>{
            return (

                <SingleFeature data={item}  />
            )
        })}

<div className='line'></div>
<div className='share'>
    <div className='feature'>
        {data.feature.map((item,index)=>{
            return (
                <p key={index}>{item.text}</p>
            )
        })}
    </div>

    <div className='button'><p>Share</p></div>

</div>

      

    </Wrapper>
  )
}

const Wrapper = styled.div`
width: 100%;
.share{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
    .feature{
        display: flex;
        p{
            font-size: 16px;
            color: #484848;
            padding: 4px 8px;
            border: 0.4px solid #C6C6C6;
            margin-right: 8px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .button{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid #C6C6C6;
        border-radius: 6px;
        height: 30px;
        /* min-height: 20px; */
        padding: 4px 12px;
        p{
            font-family:SF-Pro-Display-Bold ;
            margin-bottom: 0;
            background-color: rgba(226, 117, 38, 1);
      background-image: linear-gradient(
        90deg,
      
        
        rgba(226, 117, 38, 1) 0%,
        rgba(148, 206, 25, 1) 34.38%,
        rgba(25, 119, 206, 1) 66.67%,
        rgba(190, 107, 255, 1) 100%
      );
      /* background: -webkit-linear-gradient(to right, #FDFBFB, #EBEDEE 70%); */

      background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
            
        }

    }
}

.line{
    background-color: #484848;
    height: 0.2px;
    width: 100%;
    margin-top: 60px;
}
`

export default Feature