import React from 'react'
import styled from 'styled-components'

import { useTumysContext } from '../../context/tumycontext'
import Singleproject from '../Share/Singleproject'
import { useParams } from 'react-router-dom'
function Moreproject() {
   
   

        let {project} = useParams()

        const { SingleProject } = useTumysContext();
        console.log(SingleProject)
  return (
    <Wrapper>
      <h1>
        More From <span>
            Goku
        </span>
      </h1>
      <div className='project'>

      {SingleProject.otherProject.map((item,index)=>{
        return(
            <Singleproject key={index} project={project} data={item} />
        )
      })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
width: 100%;
margin-top: 42px;
margin-bottom: 28px;
h1{
    font-family: SF-Pro-Display-Bold;
      font-size: 42px;
    color: black;
    span{

        background-color: rgba(226, 117, 38, 1);
        background-image: linear-gradient(
          90deg,
        
          
          rgba(226, 117, 38, 1) 0%,
          rgba(148, 206, 25, 1) 34.38%,
          rgba(25, 119, 206, 1) 66.67%,
          rgba(190, 107, 255, 1) 100%
        );
        /* background: -webkit-linear-gradient(to right, #FDFBFB, #EBEDEE 70%); */
  
        background-size: 100%;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-text-fill-color: transparent;
    }
}
.project{
    width: 100%;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
margin-top: 42px;
grid-gap: 24px;
}
@media screen and (max-width: 1024px) {

/* grid-template-columns: 1fr; */
.project{
  grid-template-columns: 1fr 1fr;

}

  
}
@media screen and (max-width: 768px) {
.project{
  grid-template-columns: 1fr 1fr;

}
  
}
@media screen and (max-width: 650px) {
.project{
  grid-template-columns: 1fr;

}
  
}
@media screen and (max-width: 424px) {
  h1{
        font-size: 32px;
        line-height: 36px;
        text-align: center;

    }
.project{
  grid-template-columns: 1fr;

}
  
}
@media screen and (max-width: 324px) {
  h1{
    font-size: 28px;
        line-height: 32px;

    }
.project{
  grid-template-columns: 1fr;

}
  
}


`

export default Moreproject