import React from "react";
import styled from "styled-components";
import background from "../../assets/images/backgroundintrowork.png";
import sologanblack from "../../assets/images/gokulogoblack.png";

import sologan from "../../assets/images/gokulogoblack.png";
import iconheader from "../../assets/images/iconheaderhomeintro.png";
import { useTumysContext } from "../../context/tumycontext";
import Header from "../Share/Header";

function Intro() {
  const { english, handleLanguage, nav, handleNav } = useTumysContext();

  return (
    <div
      style={{
        background: "#DCDCDC",
      }}
      className="introproductionwork show"
    >
      <Wrapper>
        <Header
          logoBlack={sologanblack}
          iconBlack={
            <svg
              width="36"
              height="24"
              viewBox="0 0 36 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                fill="#484848"
              />
            </svg>
          }
          fixed
          icon={
            <svg
              width="36"
              height="24"
              viewBox="0 0 36 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                fill="#484848"
              />
            </svg>
          }
          logo={sologan}
        />

        <div className="info">
          <p>{english ? <>WORK</> : <>DỰ ÁN</>}</p>
          <h1>Goku Production</h1>
        </div>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  /* min-height: 400px; */
  /* height: 40%; */
  /* min-height: 500px; */
  /* height: 40vh; */
  height: 100%;

  display: block;

  position: relative;
  .info {
    position: absolute;
    bottom: 18%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: black;
    width: 42%;
    h1 {
      color: black;

      font-size: 60px;
      font-weight: 600;
      font-family: SF-Pro-Display-Bold;
    }
    p {
      color: black;

      font-size: 20px;
      line-height: 26px;
    }
    button {
      width: 30%;
      height: 40px;
      padding: 8px;
      background-color: transparent;
      color: white;
      border: 0.7px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      cursor: pointer;
      margin-left: 50%;
      transform: translateX(-50%);
      gap: 8px;
      span {
        font-weight: 700;
      }
      svg {
        width: 15px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    /* height: 32vh; */
    .info {
      width: 70%;
      h1 {
        font-size: 52px;
      }
    }
  }
  @media screen and (max-width: 796px) {
    .info {
      width: 70%;
      h1 {
        font-size: 46px;
      }
    }
  }
  @media screen and (max-width: 596px) {
    .info {
      width: 70%;
      h1 {
        font-size: 28px;
      }
      p {
        margin-bottom: 8px;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
  @media screen and (max-width: 424px) {
    .info {
      width: 80%;
      bottom: 8%;
      p {
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 16px;
      }
      h1 {
        font-size: 22px;
      }
    }
  }
  @media screen and (max-width: 324px) {
    .info {
      width: 85%;

      p {
        margin-bottom: 8px;
      }
      h1 {
        font-size: 22px;
      }
    }
  }
`;

export default Intro;
