import React from 'react'
import styled from 'styled-components'
import img1 from "../../assets/images/cgi.JPG"
import img2 from "../../assets/images/test2.png"
import img3 from "../../assets/images/test3.jpeg"
import { useTumysContext } from '../../context/tumycontext'
import Button from '../Landing/Button'
import { useNavigate } from "react-router-dom";


function Cgi() {
  const { english,handleLanguage,nav,handleNav} = useTumysContext();
  const navigate = useNavigate();
  return (
    <Wrapper>
        <div className='left'>
            <div className='text'>

            <h2>{english ? "CGI Photography & Videography":"Chụp Ảnh/Quay Phim CGI"}</h2>
            {/* <h1> & Videography</h1> */}
            </div>

            <div className='left-img'>
                <div className='img-box'>

                <img alt="CGI Image" src={img1} style={{width: "100%",height: "100%"}} />
                </div>

                <div className='shadow'></div>
            </div>


        </div>
        <div className='right'>
            <div className='text'>

            <p>
                {english?" To bring investor's idea to life, Goku provides high-quality videos and/or photos of the areas surrounding the real-estate project, which are subsequently layered into the project's 3D videos or photos. We give home-buyers the most realistic and immersive view of the project in its embryonic stage. ":"Goku cung cấp hình ảnh/video thực tế chất lượng cao của khu vực xung quanh dự án, để đưa vào hình ảnh diễn họa/video 3D của dự án nhằm phục vụ cho marketing và bán hàng. Không chỉ giúp biến ý tưởng của chủ đầu tư thành hiện thực, chúng tôi còn giúp người mua nhà có cái nhìn rõ nét và sống động nhất về dự án bất động sản ngay từ giai đoạn sơ khai."}
           
            </p>

            {!english?<p><span>*Giai đoạn thực hiện: </span>Giai đoạn diễn hoạ hình ảnh và làm phim dự án</p>:<p>This service is provided during the 3D modeling phase.</p>}
            {/* <p>Từ  <span>6,900,000 đ</span></p>
            <Button onclick text="Xem thêm" margin="0" /> */}




            
            </div>
        </div>
        
    </Wrapper>
  )
}
const Wrapper = styled.div`
width: 100%;
display: grid;
grid-template-columns: 50% 50%;
height: 550px;
background-color: white;
padding: 40px 40px 70px;
border-radius: 16px ;
/* margin: 200px 0; */

/* grid-gap: 30px; */
margin-bottom: 300px;

.left{
    
    .text{
        width: 100%;
        text-align: right;
        margin-bottom: 20px;
        /* height: 20%; */

        h2{
            color: black;
            font-family: SF-Pro-Display-Bold;
            font-size: 42px;
        }
        
        
    }
    .left-img{
        height: 75%;
        border-radius: 16px ;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        
        .img-box{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
        z-index: 99;
        img{
            border-radius: 16px ;

        }
        }
    
       
    
    }
    .shadow{
        width: 100%;
        height: 100%;
        background-color: #F2F2F2;
        position: absolute;
        top: -5%;
        right: -3%;
        border-radius: 16px ;
    


    }
}
.right{
.text{
    margin-left: 40px;
    p{
        font-size: 22px;
        font-family: SF-Pro-Display-Light;
    }
    p:nth-child(1){
        margin-bottom: 20px;
    }
    p:nth-child(2){
        span{

            background: -webkit-linear-gradient(to right, #c21500, #ffc500); 
  background: linear-gradient(to right, #c21500, #ffc500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
            font-family: SF-Pro-Display-Bold;
        }

    }
}
.btn-price {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.btn-price:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.btn-price:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.btn-price:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}
}
@media screen and (max-width: 1396px) {
    .left{

    }
    
}
@media screen and (max-width: 1024px) {
  .left{
    .text{
        h2{
            font-size: 36px;
        }
    }
    .left-img{
        height: 65%;
    }

  }
  .right{
    .text{
        p{
            font-size: 18px;
        }
    }
  }
    
}
@media screen and (max-width: 796px) {
    height: 460px;
    .left{
    .text{
        h2{
            font-size: 34px;
        }
    }
    .left-img{
        height: 55%;
    }

  }
  .right{
    .text{
        p{
            font-size: 16px;
            text-align: justify;
        }
    }
  }
    
}
@media screen and (max-width: 596px) {
    padding: 30px 30px 10px;
    height: 460px;
   .left{
    .text{
        h2{
            font-size: 30px;
        }
    }
   }
    .right{
    .text{
        margin-left: 20px;
        p{
            font-size: 14px;
        }
    }
  }
}
@media screen and (max-width: 456px) {
    padding: 30px 30px 10px;
    height: 430px;
   .left{
    .text{
        margin-bottom: 20px;
        h2{
            font-size: 26px;
        }
    }
   }
    .right{
    .text{
        margin-left: 20px;
        p{
            font-size: 11px;
        }
        p:nth-child(1){
            margin-bottom: 20px;

        }
    }
  }
}
@media screen and (max-width: 360px) {
    padding: 30px 30px 10px;
    height: 300px;
   .left{
    .text{
        margin-bottom: 10px;
        h2{
            font-size: 22px;
        }
    }
   }
    .right{
    .text{
        margin-left: 12px;
        p{
            font-size: 9px;
            line-height: 12px;
        }
        p:nth-child(1){
            margin-bottom: 12px;

        }
    }
  }
}
@media screen and (max-width: 290px) {
    padding: 20px 10px 10px;
    height: 280px;
   .left{
    .text{
        margin-bottom: 8px;
        h2{
            font-size: 18px;
        }
    }
   }
    .right{
    .text{
        margin-left: 8px;
        p{
            font-size: 8px;
            line-height: 12px;
        }
        p:nth-child(1){
            margin-bottom: 8px;

        }
    }
  }
}

`

export default Cgi