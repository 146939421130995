import React from 'react'
import styled from 'styled-components'
function Heading({text,color="white"}) {
  return (
    <Wrapper color={color}>{text}</Wrapper>
  )
}
const Wrapper = styled.h1`
     font-size: 60px;
      font-weight: 600;
      font-family: SF-Pro-Display-Bold;
      color : ${props => props.color};
      @media screen and (max-width: 1366px) {
    
}
@media screen and (max-width: 1080px) {
    
      font-size: 52px;
line-height: 54px!important;

   
}
@media screen and (max-width: 768px) {
  
    font-size: 46px;
line-height: 48px!important;

  
}

@media screen and (max-width: 596px) {

    font-size: 40px;
line-height: 42px!important;

  
}

@media screen and (max-width: 424px) {

font-size: 38px;
line-height: 40px!important;

}
@media screen and (max-width: 300px) {

font-size: 24px;
line-height: 26px!important;


}


`

export default Heading