import React, { useEffect } from 'react'
import { useTumysContext } from '../context/tumycontext';
import Body from '../components/Gokuproductionwork/Body';
import Intro from '../components/Gokuproductionwork/Intro'
import Nav from '../components/Share/Nav';
// import {Helmet} from "react-helmet";
import { Helmet } from 'react-helmet-async';



function Gokuproductionwork() {
    const {updateMeta, english, handleLanguage, nav, handleNav,fetchProject,fetchFilter,ProductionProject } = useTumysContext();
   const {filterAPI,currentFilter,currentPage,perPage} = ProductionProject;
  
    useEffect(()=>{
      fetchFilter(`${english?"en":"vi"}`,"goku-product")

      if(currentFilter === "Tất cả"){
        fetchProject(`${english?"en":"vi"}`,"goku-product",0,perPage,currentPage);
        return;
      }

      filterAPI.forEach((item) => {
        // console.log(item.name)
          //  console.log(item.name)
           if(item.name === currentFilter){
       
          
            fetchProject(`${english?"en":"vi"}`,"goku-product",item.id,perPage,currentPage);
            
          

           }
           
           
      });
      

      
    },[currentPage,english,currentFilter])
    // console.log(filterAPI,currentFilter)
    useEffect(() => {
      window.scrollTo(0, 0);
      // updateMeta(`KAME PRODUCTION`,`Explore our production page to see our innovative products and exceptional services. Our team of experts is dedicated to delivering high-quality solutions that meet your needs and exceed your expectations. Learn more about our state-of-the-art production process and why we are the best choice for your production needs.`,"https://goku.agency/img/thumb-min.png",`https://goku.agency/work/gokuproduction`)
    }, [currentPage,english,currentFilter])
   
  return (
    // <div className='page-goku'>
    // {nav ? (<Nav />) : (

    //     <Intro />
    // )}

    // <Body />
    // </div>

     <>
        <Helmet>
    <meta charset="utf-8" />
    <link rel="icon" href="https://goku.agency/img/favicon.png" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    
    <meta data-rh="true" name="description" content="Explore our production page to see our innovative products and exceptional services. Our team of experts is dedicated to delivering high-quality solutions that meet your needs and exceed your expectations. Learn more about our state-of-the-art production process and why we are the best choice for your production needs." />

    <title>GOKU PROJECT</title>
    <meta data-rh="true" name="robots" content="all" />
  
    <meta data-rh="true" property="og:title" content="KAME PROJECT" />
    <meta data-rh="true" property="og:description" content="Explore our production page to see our innovative products and exceptional services. Our team of experts is dedicated to delivering high-quality solutions that meet your needs and exceed your expectations. Learn more about our state-of-the-art production process and why we are the best choice for your production needs." />
    <meta  data-rh="true" property="og:type" content="website" />
    <meta data-rh="true" property="og:url"content="https://goku.agency/work/gokuproduction" />
    <meta data-rh="true" property="og:image" content="https://goku.agency/img/rsz_goku.png" />
    <meta data-rh="true" property="og:image:alt" content="Goku"/>
    <meta data-rh="true" property="og:image:width" content="1200" />
    <meta data-rh="true" property="og:image:height" content="628" />
    <link rel='canonical' href='https://goku.agency/work/gokuproduction' />

    </Helmet>

     {nav?(<Nav />):(
      

      <>
      
      <Intro />
      <Body />
      </>

     )}
     
     </>
  )
}

export default Gokuproductionwork