import React from 'react'
import styled from 'styled-components'
import Singleproject from '../Share/Singleproject'
import Pagenumber from './Pagenumber'
import project1 from "../../assets/images/project1.png"
import project2 from "../../assets/images/project2.png"
import project3 from "../../assets/images/project3.png"
import { useTumysContext } from '../../context/tumycontext'
import Loadingproject from '../Share/Loadingproject'


function Project() {
    const { english,VerseProject, handleLanguage, nav, handleNav,dataProjecgokuverse,currentArraygokuverse } = useTumysContext();


  //  if(!VerseProject.isLoading){
  //   return  <Loadingproject />
  //  }
  return (
    <Wrapper>
      {/* <Loadingproject /> */}
     
        {VerseProject.currentArray.map((item,index)=>{
          return (
     <Singleproject key={index} project="goku-verse" data={item} />

          )
        })}
    






    </Wrapper>
  )
}

const Wrapper = styled.div`
background-color: transparent;
min-height: 400px;
margin-top: 60px;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 40px;
@media screen and (max-width: 1024px) {
 
 grid-gap: 25px;
   
   
 }
 @media screen and (max-width: 796px) {
  margin-top: 30px;
 grid-gap: 15px;
 
   
   
 }
 @media screen and (max-width: 596px) {
 grid-template-columns: 1fr 1fr;
 margin-top: 30px;
  
 grid-gap: 15px;
   
   
 }
 @media screen and (max-width: 496px) {
  margin-top: 30px;
   
 grid-template-columns: 1fr;
  
 grid-gap: 10px;
   
 }

`

export default Project