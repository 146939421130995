import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Share/Footer';
import ScrollToTop from "react-scroll-to-top";
import callbutton from "../assets/images/callbutton.png"

import { useTumysContext } from '../context/tumycontext'




function SharedLayout() {
  const { handleNav ,nav}  = useTumysContext();

    useEffect(()=>{
      handleNav(false)
    },[])

  return (
    <>
    
    
    

   
    <Outlet />
    {!nav &&  <Footer />}
    <ScrollToTop style={{bottom : "18%"}}  smooth />
    {!nav && <button className='call_button'>
      <a href="tel:0915 444 235">
      <img alt="Phone" src={callbutton} />

      </a>
    </button> }
    

   
  
    </>
  )
}

export default SharedLayout