import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTumysContext } from '../../context/tumycontext'
import ReactPaginate from 'react-paginate';
// import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
function Pagenumbernew() {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const {VerseProject,updateCurrentPageVerse} = useTumysContext();

    const {total,perPage,currentPage,currentArray,} = VerseProject

    const number =  Math.ceil(total/perPage) ;
    const newArr = Array.from({length: number},(_,i)=>i+1)

    
    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + perPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(newArr.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(newArr.length / perPage));
      }, [itemOffset, perPage]);
      const handlePageClick = (event)=>{
        const newOffset = event.selected * perPage % newArr.length;
        // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
        console.log(event.selected + 1)
        // updateCurrentPageProduction(newOffset + 1)
        updateCurrentPageVerse(event.selected + 1)
        
        
    }
  return (
    <Wrapper>
        {/* <div>

        {newArr.map((item,index)=>{
                        return(
                            <p key={index} onClick={()=>{
                                // updateCurrentPageBlog(item)
                                // updatePage
                                updateCurrentPageProduction(item)
                            
                            }} className={`${item===currentPage ? "active": ""}`}>{item}</p>
                        )
                })}

        </div> */}
         <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={4}
        marginPagesDisplayed={1}
        pageCount={newArr.length}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`

width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin-top: 60px;
div{
    display: flex;
    /* margin: 0 auto; */
align-items: center;
justify-content: center;
width: 60%;

    gap: 40px;
    p{
        font-size: 18px;
        font-family: SF-Pro-Display-Light;
        cursor: pointer;
    }
    .active{
        border: 0.3px solid #C6C6C6;
        font-family: SF-Pro-Display-Bold;
        padding: 2px 10px;
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 6px;
    }

}

@media screen and (max-width: 1024px) {
    div{
        gap:30px;
    }
    
}
@media screen and (max-width: 796px) {
    div{
        gap:30px;
        p{
            font-size: 16px;
        }
    }
    
}
@media screen and (max-width: 424px) {
    div{
        gap:16px;
        p{
            font-size: 14px;
        }
    }
    
}

`

export default Pagenumbernew