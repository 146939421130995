import React from 'react'
import styled from 'styled-components'
import { useTumysContext } from '../../context/tumycontext';
import scape1 from "../../assets/images/cityscape1.jpg"
import scape2 from "../../assets/images/cityscape1.png"

import scape3 from "../../assets/images/cityscape2.jpg"
function Cityscapemobile() {
  const { english,handleLanguage,nav,handleNav} = useTumysContext();

    
  return (
    <Wrapper>
        <div className='text'>
        {english ? (
                <>
                <h1>Cityscape Photography</h1>
            {/* <h1>Photography</h1> */}

            <p>
            To show the beauty of the city, Goku provides cityscape photography services, bringing comprehensive and realistic views to the customers.
            </p>
                </>
            ):
            <>
             <h1>Chụp ảnh/quay phim Cityscape</h1>
            

            <p>
            Goku chụp ảnh hoặc quay phim Cityscape để thấy được vẻ đẹp của các thành phố.
            </p>
            </>
            }
        </div>
        <div className='container'>
            <div className='img-box'>
                <img alt="Cityscape Image" src={scape1} />
            </div>
            <div  alt="Cityscape Image" className='img-box'>
                <img alt="Cityscape Image" src={scape2} />
            </div>
            <div alt="Cityscape Image" className='img-box'>
                <img alt="Cityscape Image" src={scape3} />
            </div>
        </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
width: 100%;
margin-top: 60px;
padding: 10px;
background-color: white;
border-radius: 16px;
padding: 20px 10px;
.text{
    h1{
        color: black;
            font-family: SF-Pro-Display-Bold;
            font-size: 28px;
            line-height: 34px;
            text-align: center;
            margin-top: 18px;
    }
    p{
        text-align: center;
    }
    
}
.container{
    width: 100%;
    .img-box{
        width: 100%;
        margin-bottom: 40px;
        img{
            width: 100%;
            border-radius: 8px;
        }
    }
}

@media screen and (max-width: 496px) {

    
}
@media screen and (max-width: 300px) {
    .text{
        h1{
                font-family: SF-Pro-Display-Bold;
        /* width: 85%; */
        font-size: 20px;
        /* margin: 0 auto 16px; */
        line-height: 24px;
            }
    }
   
    
}



`

export default Cityscapemobile