import React from 'react'
import styled from 'styled-components'
import { useTumysContext } from '../../context/tumycontext';
function Pagenumber() {
    const {VerseProject,updateCurrentPageVerse} = useTumysContext();
    const {total,perPage,currentPage,currentArray} = VerseProject

    const number =  Math.ceil(total/perPage) ;
    const newArr = Array.from({length: number},(_,i)=>i+1)
    // console.log(total)



  return (
    <Wrapper>
        <div>

        {newArr.map((item,index)=>{
                        return(
                            <p key={index} onClick={()=>{
                                // updateCurrentPageBlog(item)
                                // updatePage
                                // updateCurrentPageProduction(item)
                                updateCurrentPageVerse(item)
                            
                            }} className={`${item===currentPage ? "active": ""}`}>{item}</p>
                        )
                })}
        </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin-top: 60px;
div{
    display: flex;
    /* margin: 0 auto; */
align-items: center;
justify-content: center;
width: 60%;

    gap: 40px;
    p{
        font-size: 18px;
        font-family: SF-Pro-Display-Light;
        cursor: pointer;
    }
    .active{
        border: 0.3px solid #C6C6C6;
        font-family: SF-Pro-Display-Bold;
        padding: 2px 10px;
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 6px;
    }

}
@media screen and (max-width: 1024px) {
    div{
        gap:30px;
    }
    
}
@media screen and (max-width: 796px) {
    div{
        gap:30px;
        p{
            font-size: 16px;
        }
    }
    
}
@media screen and (max-width: 424px) {
    div{
        gap:30px;
        p{
            font-size: 14px;
        }
    }
    
}

`

export default Pagenumber