import React from "react";
import ReactDOM from "react-dom/client";
import { TumysProvider } from "./context/tumycontext";
import App from "./App";
import "./assets/Fonts/00186-UTM-Trajan-Pro-Bold.ttf";
import "./assets/Fonts/SVN-Gotham Bold.ttf";
import "./assets/Fonts/SVN-Gotham Light.ttf";
import "./assets/Fonts/SF-Pro-Display-Regular.ttf";
import "./assets/Fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf";
import "./assets/Fonts/SF-Pro-Display/SF-Pro-Display-RegularItalic.otf";
import "./assets/Fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf";
import "./assets/Fonts/SF-Pro-Display/SF-Pro-Display-Light.otf";
// import GlobalStyle from './components/Share/Globalstyle';
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <TumysProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </TumysProvider>
  </React.StrictMode>
);

