import React, { useEffect } from "react";
import styled from "styled-components";

import logo from "../assets/images/logo-intro-black.png";


import { useNavigate } from "react-router-dom";


function Loading() {
  const navigate = useNavigate();

  useEffect(() => {
    const timerId = setTimeout(() => {
      navigate("/home")
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  useEffect(()=>{
  
  },[])
  return (
    <Wrapper>
  <div className="loading_container">
       

        <div
          className="logo_backgound"
          style={{
            width: "100%",
            height: "100%",
            // backgroundImage:
            //   "radial-gradient(circle at center, white, rgba(32, 165, 185, 0.9))",
            // background: "#141416",
          }}
        />

        <div className="loading_content">
          <div className="loading_content-container">
            <div className="loading_content-container_border">
              <div className="loading_content-container_border-child">
                <div className="loading_content-container_border-box">
                  {/*  */}
                  <div className="loading_logo_img">
                    <img src={logo} alt="Logo Goku" width={700} />
                  </div>
                  <div className="loading_text">
                    <h1 >More Than Words</h1>
                  </div>
                  <div className="loading_subtext">
                    <div>Bạn Vui Lòng Chờ Trong Giây Lát...</div>
                  </div>
                  <div className="infinity-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Wrapper>
  );
}
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  .loading_container {
    width: 100%;
    height: 100%;

    .logo_backgound {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .loading_content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;

      .loading_content-container {
        width: 100%;
        height: 100%;
        z-index: 100;
        padding: 30px;
        .loading_content-container_border {
          width: 100%;
          height: 100%;
          padding: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .loading_content-container_border-child {
          width: 100%;
          height: 100%;
          border-radius: inherit;
        }
        .loading_content-container_border-box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-color: transparent;

          .loading_content_logo {
            display: block;
            width: 41.8%;
            margin-bottom: 10px;
          }
          .loading_content_loading {
            position: fixed;
            width: 3%;
            bottom: 200px;
            display: block;
            animation: spinner 1s linear infinite;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
  .loading_content-container {
    .loading_content-container_border-box {
      .loading_content_logo {
        width: 80% !important;
      }
      .loading_content_loading {
        width: 7% !important;
      }
      .loading_logo_img{
        width: 70%;
        img{
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .loading_content_loading {
    width: 10% !important;
  }

  .loading_content_logo {
    width: 80% !important;
    margin-bottom: 80px;
  }
  .loading_content-container {
    .loading_content-container_border-box {
      .loading_content_logo {
        width: 80% !important;
      }
      .loading_content_loading {
        width: 7% !important;
      }
      .loading_logo_img{
        width: 70%;
        img{
          width: 100%;
        }
      }
      .loading_subtext{
        font-size: 20px;
      }
    }
  }
}
@media screen and (max-width: 596px) {
  .loading_content_logo {
    margin-bottom: 120px !important;
  }
  .loading_content-container {
    .loading_content-container_border-box {
      .loading_content_logo {
        width: 80% !important;
      }
      .loading_content_loading {
        width: 7% !important;
      }
      .loading_logo_img{
        width: 70%;
        img{
          width: 100%;
        }
      }
      .loading_subtext{
        font-size: 16px;
        margin-top: 30px;

      }
      .loading_text{
        margin-top: 30px;

        h1{
          font-size: 1.6rem;
          text-align: center;
        }
      }
      .infinity-3{
        margin-top: 40px;
      }
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading_logo_img {
  top: 30%;
}

.loading_text {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: #141416;
    font-size: 5em;
  }

  h1:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    max-width: 8em;
    white-space: nowrap;
    color: black;
    animation: loading 0s infinite alternate;
  }
}

.loading_subtext {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-top: 50px;
  font-weight: 400;
}

.progress {
  position: relative;
  // height: 10px;
  width: 40%;
  border: 10px solid black;
  border-radius: 15px;
  display: flex;
  align-items: center;
  bottom: -20%;
  font-family: "Inter";
}
.progress .color {
  position: absolute;
  background-color: #ffffff;
  width: 0px;
  height: 10px;
  border-radius: 15px;
  animation: progres 5.2s infinite linear;
}
@keyframes progres {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}

// Stripe loading
.infinity-3 {
  width: calc(160px / 0.707); /* 0.707 = cos(45deg) */
  height: 14px;
  border-radius: 10px;
  margin-top: 100px;
  background: repeating-linear-gradient(
      -45deg,
      currentColor 0 15px,
      #0000 0 20px
    )
    left/200% 100%;
  animation: i3 2s infinite linear;
  border: 1px solid black;
}
@keyframes i3 {
  0% {
    background-position: right;
  }
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .loading_logo_img {
    img {
      width: 400px;
    }
  }

  .loading_text {
    margin-top: 50px;

    h1 {
      font-size: 2.5em;
    }
  }
}

`;

export default Loading;
