import React from "react";
import styled from "styled-components";
// import background from "../../assets/images/backgroundintrohome.png";
// import backgroundnav from "../../assets/images/backgroundnav.png";
import video from "../../assets/video/singleproject1.mp4";

import sologan from "../../assets/images/sologanintro.png";
// import iconheader from "../../assets/images/iconheaderhomeintro.png";
// import Nav from "../Share/Nav";
import sologanblack from "../../assets/images/gokulogoblack.png";
import homeintro_img from "../../assets/images/homeintro_img-min.jpeg";


import { Link } from "react-router-dom";
import { useTumysContext } from "../../context/tumycontext";
import Heading from "../Share/Heading";
import Header from "../Share/Header";

function Homeintro() {
  // const [nav,setNav] = useState(false)
  // const [en,setEn] = useState(false)
  // const videoRef = useRef();

  // const { english, handleLanguage, nav, handleNav } = useTumysContext();
  const { english } = useTumysContext();

  return (
    // <>
    // {!nav ? (
    <div className="homeintro show">
      <Wrapper>
        {/* <div className="header">
          <div className="header-logo">
            <img alt="SOLOGAN" style={{width: "100%"}}  src={sologan} />
          </div>
          <div onClick={()=>{
            if(nav === true){
              handleNav(false)
            }else{
              handleNav(true)
            }
          }} className="header-icon">
            <img alt="ICON MENU"  src={iconheader} />
          </div>
        </div> */}
        <Header
          fixed
          icon={
            <svg
              width="36"
              height="24"
              viewBox="0 0 36 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                fill="white"
              />
            </svg>
          }
          logo={sologan}
          logoBlack={sologanblack}
          iconBlack={
            <svg
              width="36"
              height="24"
              viewBox="0 0 36 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                fill="#484848"
              />
            </svg>
          }
        />

        <div className="info">
          <Heading text="More Than Words" />
          {/* <h1>More Than Words</h1> */}
          <p>
            {english
              ? "Creating captivating Real Estate Project content to take your marketing and selling to the next level"
              : "Goku là đơn vị đầu tiên cung cấp dịch vụ sản xuất hình ảnh toàn diện cho các Dự án Bất động sản, cùng với sản phẩm website và app bán hàng dự án sử dụng công nghệ AR/VR."}
          </p>
          <button>
            <Link to="/aboutus">
              {english ? (
                <>
                  About <span> Goku</span>
                </>
              ) : (
                <>Giới thiệu</>
              )}

              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
                  fill="white"
                />
              </svg>
            </Link>
          </button>
        </div>
        <img alt="homeintro_img" src={homeintro_img} className="homeintro_img"/>
        {/* <div
          dangerouslySetInnerHTML={{
            __html: `
        <video
          loop
          muted
          playsinline
          src="${video}"
         
        />,
      `,
          }}
          className="video"
        ></div> */}
        {/* <div
          dangerouslySetInnerHTML={{
            __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${video}"
         
        />,
      `,
          }}
          className="video"
        ></div> */}

        <div className="play">
          <svg
            width="70"
            height="70"
            viewBox="0 0 98 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                d="M48.9994 10.6667C41.4177 10.6667 34.0064 12.9149 27.7025 17.127C21.3986 21.3391 16.4853 27.326 13.584 34.3305C10.6826 41.335 9.9235 49.0425 11.4026 56.4785C12.8817 63.9144 16.5326 70.7447 21.8936 76.1058C27.2546 81.4668 34.085 85.1177 41.5209 86.5968C48.9568 88.0759 56.6644 87.3167 63.6689 84.4154C70.6734 81.514 76.6603 76.6007 80.8724 70.2969C85.0845 63.993 87.3327 56.5816 87.3327 49C87.3217 38.8368 83.2794 29.0929 76.0929 21.9064C68.9064 14.7199 59.1626 10.6777 48.9994 10.6667ZM43.9994 62.3333C43.9994 62.7754 43.8238 63.1993 43.5112 63.5118C43.1987 63.8244 42.7747 64 42.3327 64H35.666C35.224 64 34.8001 63.8244 34.4875 63.5118C34.175 63.1993 33.9994 62.7754 33.9994 62.3333V35.6667C33.9994 35.2246 34.175 34.8007 34.4875 34.4882C34.8001 34.1756 35.224 34 35.666 34H42.3327C42.7747 34 43.1987 34.1756 43.5112 34.4882C43.8238 34.8007 43.9994 35.2246 43.9994 35.6667V62.3333ZM63.9994 62.3333C63.9994 62.7754 63.8238 63.1993 63.5112 63.5118C63.1987 63.8244 62.7747 64 62.3327 64H55.666C55.224 64 54.8001 63.8244 54.4875 63.5118C54.175 63.1993 53.9994 62.7754 53.9994 62.3333V35.6667C53.9994 35.2246 54.175 34.8007 54.4875 34.4882C54.8001 34.1756 55.224 34 55.666 34H62.3327C62.7747 34 63.1987 34.1756 63.5112 34.4882C63.8238 34.8007 63.9994 35.2246 63.9994 35.6667V62.3333Z"
                fill="white"
              />
            </g>
            <circle
              opacity="0.6"
              cx="49"
              cy="49"
              r="47.5"
              stroke="white"
              stroke-width="3"
            />
          </svg>
        </div>
      </Wrapper>
    </div>
    // ) :
    //   (

    //    <Nav />
    //   )}
    // </>
  );
}
const Wrapper = styled.section`
  width: 100vw;
  /* min-height: 900px; */
  height: 100%;
  display: block;
  position: relative;

  .homeintro_img {
    position: absolute;

    /* padding: 20px; */

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    right: 0;
    z-index: 1;
    /* z-index: 100; */
  }

  .video {
    position: absolute;
    /* padding: 20px; */

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    right: 0;
    z-index: 1;
    /* z-index: 100; */

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    z-index: 3;

    .header-logo {
      width: 160px;
      z-index: 3;

      img {
      }
    }

    .header-icon {
      z-index: 3;

      img {
        width: 30px;
      }
      cursor: pointer;
    }
  }
  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    z-index: 4;
    display: none;
  }
  /* Info */
  .info {
    position: absolute;
    bottom: 18%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    width: 42%;
    z-index: 2;

    h1 {
      color: white;
      /* font-size: 60px; */
      font-weight: 600;
      font-family: SF-Pro-Display-Bold;
    }
    & > p {
      color: white;
      font-size: 22px;
      line-height: 32px;
      width: 80%;
      margin: 16px auto;
    }
    button {
      width: 23%;
      height: 40px;
      padding: 8px 6px;
      background-color: transparent;
      color: white;
      border: 0.7px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      cursor: pointer;
      margin-left: 50%;
      transform: translateX(-50%);
      gap: 8px;
      a {
        color: white;
        display: block;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        svg {
          margin-left: 8px;
        }
        span {
          font-weight: 700;
          margin-left: 6px;
        }
      }
    }
  }

  @media screen and (max-width: 1366px) {
    .info {
      width: 50%;
    }
  }
  @media screen and (max-width: 1080px) {
    .info {
      width: 56%;

      /* h1{
        font-size: 52px;
      } */
      & > p {
        font-size: 18px;
        line-height: 24px;
      }
      button {
        width: 26%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .info {
      width: 74%;
    }
  }

  @media screen and (max-width: 596px) {
    .info {
      width: 95%;
    }
  }

  @media screen and (max-width: 424px) {
    .header {
      .header-logo {
        width: 100px;
      }
    }

    .info {
      width: 95%;
      p {
        font-size: 12px;
        line-height: 18px;
        margin: 8px auto;
      }
      button {
        /* padding: 6px 16px; */
        width: 30%;
        padding: 4px 6px;
        height: auto;
        /* height: 16px; */
        svg {
          width: 12px;
          height: 12px;
        }
        a {
          font-size: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 324px) {
    .header {
      .header-logo {
        width: 80px;
      }
    }
    .info {
      button {
        width: 38%;
      }
    }
  }

  /* End info */
`;

export default Homeintro;
