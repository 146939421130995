import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import blog1 from "../../assets/images/blog1.png"
import blog2 from "../../assets/images/blog2.png"
import blog3 from "../../assets/images/blog3.png"
import Singleblog from '../Gokublog/Singleblog'
import video from "../../assets/video/test1.mp4"
import { useTumysContext } from '../../context/tumycontext'
// import Moment from 'moment';
import Moment from 'react-moment';
import Heading from '../Share/Heading'
// import 'moment-timezone';



function Body() {
    const { moreBlog,english, handleLanguage, nav, handleNav ,fetchBlog,currentpageBlog,currentSingleBLog} = useTumysContext();
    
    //   const randomElement1 = currentpageBlog.articles[Math.floor(Math.random() * currentpageBlog.articles.length)];
    //   const randomElement2 = currentpageBlog.articles[Math.floor(Math.random() * currentpageBlog.articles.length)];
    // const randomElement1 = currentpageBlog[0]
    // const randomElement2 = currentpageBlog[0]
     
    // console.log(currentpageBlog[0])
    // console.log(randomElement1)
    useEffect(()=>{
        // fetchBlog(`${english ? "en" : "vi"}`,1,10);
        document.querySelectorAll(".item p video").forEach((item)=>{
          item.loop = true;
          item.autoplay = true;
          item.muted = true;
        })
    },[])
 

//   console.log(currentSingleBLog)
  return (
    <Wrapper>
        <div className='heading'>
            <p>HOME / {currentSingleBLog.category_name}</p>
            <h1>{currentSingleBLog.name}</h1>
            {/* <Heading text={currentSingleBLog.name} color="black" /> */}
            <p><Moment format="LL" date={currentSingleBLog.date} /></p>
            
        </div>
     
        <div className='content'>
            {/* <div dangerouslySetInnerHTML={{
                __html:  `
              

               <p>Thiết kế theo lối kiến trúc tân cổ điển, The Classia thuộc quần thể khu dân cư Mega, Safira của Tập đoàn Khang Điền hứa hẹn là nơi an cư lý tưởng cho giới tinh hoa tại TP Thủ Đức.\r\nĐối với những người thành đạt và am tường phong cách sống, một ngôi nhà đúng nghĩa không đơn thuần là chốn trú ngụ thông thường, mà là nơi mang đến nguồn cảm hứng cũng như năng lượng sáng tạo. Do đó, họ đòi hỏi những chuẩn mực khắt khe về tính riêng tư, môi trường sinh thái, dịch vụ đặc quyền cùng tiện ích xứng tầm khi lựa chọn nơi an cư.</p>

                <div class='image'>
                <img src="https://goku.agency/uploads/photos/khoang-dien.png" />
            </div>
               
            <div class="video-container">
            <div class="video">
            <video src=${video} autoPlay loop muted  />

            </div>
            <em>thiết kế theo lối kiến trúc tân cổ điển, The Classia thuộc quần thể khu dân cư Mega</em>
            </div>

            <p>thiết kế theo lối kiến trúc tân cổ điển, The Classia thuộc quần thể khu dân cư Mega, Safira của Tập đoàn Khang Điền hứa hẹn là nơi an cư lý tưởng cho giới tinh hoa tại TP Thủ Đức.\r\nĐối với những người thành đạt và am tường phong cách sống, một ngôi nhà đúng nghĩa không đơn thuần là chốn trú ngụ thông thường, mà là nơi mang đến nguồn cảm hứng cũng như năng lượng sáng tạo. Do đó, họ đòi hỏi những chuẩn mực khắt khe về tính riêng tư, môi trường sinh thái, dịch vụ đặc quyền cùng tiện ích xứng tầm khi lựa chọn nơi an cư.</p>
            

           
            <div class='image'>
            <img src="https://goku.agency/uploads/photos/khoang-dien.png" />

            <em>Nhà phố cho thuê là tài sản đầu tư đảm bảo cả giá trị lãi vốn lẫn dòng tiền. Ảnh minh họa

            </em>
        </div>

                
                `
                
                }}  className='item'>
              
                
                
            </div> */}

            <div dangerouslySetInnerHTML={{
                __html:  `
              
               
           
               ${currentSingleBLog.content}

                
                `
                
                }}  className='item'>
              
              
                
            </div>

          
             

             <div className='line'></div>

<div className='share'>


<div className='feature'>
    {currentSingleBLog.tags !== undefined && currentSingleBLog.tags.map((item,index)=>{
        return (
            <p key={index}>{item.name}</p>
        )
    })}
</div>

<div className='button'><p>Share</p></div>

</div>
             
           
        </div>

        <div className='related'>
            <h1> <span>Related</span> Blog</h1>
            <div className='news'>

                {moreBlog.map((item,index)=>{
                    return <Singleblog item={item} key={index} />
                })}
            
                {/* <Singleblog item={randomElement1} />
                <Singleblog item={randomElement2} /> */}

                

            </div>
        </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
 padding: 10px 150px 120px;
 background-color: white;
 min-height: 500px;
 .heading{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    p:nth-child(1){
        font-family: SF-Pro-Display-Bold;
    text-transform: uppercase;
        /* color: red */

    }
    &>h1{
        font-family: SF-Pro-Display-Bold;
        font-size: 42px;
        line-height: 55px;
        color: black

    }
    p:nth-child(3){
        font-family: SF-Pro-Display-RegularItalic;
        font-size: 12px;
    /* text-transform: uppercase; */
        /* color: red */

    }
 }
 .content{
    width: 100%;
    margin-top: 40px;
    margin-bottom: 80px;
    text-align: center;
    position: relative;

     .item {
      width: 100%;
      &>p{
        margin-bottom: 10px;

      }
      p {
        /* height: 100%; */
        /* height: 550px; */
        /* margin: 0 auto 60px; */
        text-align: center;
        &>img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
        }
      }
      p {
        /* position: relative;
        margin-bottom: 10px;
        height: 800px;
        display: flex;
        justify-content: center;
        text-align: center; */

        /* align-items: flex-start; */

        &>video {
          border-radius: 10px;
          height: 800px;
          width: 100%;
          /* height: 100%; */
          object-fit: cover;
          /* margin-bottom: 20px; */
        }
      }
      p {
        text-align: center;
        &>em {
          text-align: center;
          font-size: 16px;
          display: block;
        margin-bottom: 60px;

        }
      }

      p {
        width: 100%;
        margin: 10px auto;
        color: #484848;
        line-height: 36px;
        text-align: left;
        font-size: 18px;
      }
      .text {
        margin-bottom: 30px;
      }
      p {
        text-align: center;
        &>iframe {
          width: 100%;
          height: 600px;
          border-radius: 10px;
          border: none;
        }
      }
    }
   
    
   
 }
 .share{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    .feature{
        display: flex;
        width: 25%;
        p{
            font-size: 12px;
            color: #484848;
            line-height: 14px;
            padding: 0px 16px;
            border: 0.4px solid #C6C6C6;
            margin-right: 8px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
        }
    }
    .button{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid #C6C6C6;
        border-radius: 6px;
        height: 30px;
        /* width: 80px; */
        /* min-height: 20px; */
        padding: 2px 12px;
        width: 100px;
        p{
            font-family:SF-Pro-Display-Bold ;
            margin-bottom: 0;
            background-color: rgba(226, 117, 38, 1);
      background-image: linear-gradient(
        90deg,
      
        
        rgba(226, 117, 38, 1) 0%,
        rgba(148, 206, 25, 1) 34.38%,
        rgba(25, 119, 206, 1) 66.67%,
        rgba(190, 107, 255, 1) 100%
      );
      /* background: -webkit-linear-gradient(to right, #FDFBFB, #EBEDEE 70%); */

      background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
            
        }

    }
}

.line{
    background-color: #484848;
    height: 0.2px;
    width: 100%;
    margin-top: 140px;
}
.related{
    width: 100%;
    text-align: center;

    &>h1{
        font-family: SF-Pro-Display-Bold;
        font-size: 42px;
        span{

            font-family:SF-Pro-Display-Bold ;
            margin-bottom: 0;
            background-color: rgba(226, 117, 38, 1);
      background-image: linear-gradient(90.07deg, #2EEE86 3.12%, #26BEEE 92.02%);
        background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;

        }
    }
    .news{
        margin-top: 60px;
        display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 20px;
       
    }
}

@media screen and (max-width: 1396px){
    .share{
        .feature{
            width: 56%;
        }
    }
}
@media screen and (max-width: 1024px){
    padding: 10px 100px 120px;
    text-align: center;
    .heading{
        h1{
     
     font-size: 32px;
     line-height: 40px;
    

 }
    }
   
    .content{
        .item {
        text-align: center;
        /* p {
          text-align: justify;
        } */
        .image {
          margin-bottom: 0;
        }

        p {

          &>video {
            /* height: 93%; */
          height: 300px;

          }
        }
        p {
          /* margin-bottom: 50px; */
          &>iframe {
            height: 450px;
            width: 100%;
            /* width: 100%; */

border-radius: 10px;
border: none;

          }
        }
      }
        .line{
        margin-top: 80px;
    }
    }
    .share{
        .feature{
            width: 56%;
        }
    }
    .related{
        &>h1{
     
     font-size: 32px;
     line-height: 40px;
    

 }
    }
}
@media screen and (max-width: 796px){
    padding: 10px 60px 120px;
    text-align: center;
    .heading{
        h1{
       
       font-size: 28px;
       line-height: 34px;
      

   }
    }
    .content{
      .item {
        p {
          width: 85%;
          /* text-align: justify; */
        }
        .text {
          text-align: justify;
        }
        .image {
          margin-bottom: 0;
        }
        p>em {
          font-size: 16px;
          line-height: 20px;
        }
        p {
          /* height: 400px; */

          .video {
            height: 400px;

            video {
              border-radius: 12px;
            }
          }
        }
        p{
          /* margin-bottom: 40px; */

          iframe {
            height: 400px;
            width: 100%;

          }
        }
      }
    }
    .share{
        .feature{
            width: 59%;
        }
    }
    .related{
        .news{
            grid-template-columns: 1fr;
        }
        &>h1{
            font-size: 28px;
       line-height: 34px;
        }
    }

}
@media screen and (max-width: 596px){
    padding: 10px 40px 120px;
    .heading{
        h1{
       
       font-size: 24px;
       line-height: 28px;
      

   }}

   .content{
     .item {
        p {
          /* width: 80%;
          text-align: justify; */
          width: 100%;
        }
        .text {
          text-align: justify;
        }

        .image {
          margin-bottom: 0;
        }
        p {
          em {
            font-size: 14px;
            line-height: 18px;
          }
        }
        p {
          /* height: 400px; */
          /* margin-bottom: 30px; */

          &>video{
          height: 400px;

          }
        }
        p {
          margin-bottom: 0px;

          iframe {
            height: 300px;
            width: 100%;
            border-radius: 8px;
          border: none;
          }
        }
      }
        .line{
        margin-top: 60px;
    }
    }
    .share{
        .feature{
            width: 68%;
            p{
                font-size: 9px;
                padding: 6px 12px;
                line-height: 13px!important;
            }
        }
        .button{
                font-size: 12px;
                padding: 2px 8px;
        }
    }
    .related{
        &>h1{
        font-size: 24px;
       line-height: 28px;
        }
        .news{
        margin-top: 40px;
    }
    }
    
}
@media screen and (max-width: 424px){
    padding: 10px 20px 120px;
   .related{
    .news{
        margin-top: 35px;
    }
   }
    .share{
        .feature{
            width: 70%;
            p{
                font-size: 8px;
                padding: 0px 8px;
                line-height: 8px!important;
            }
        }
    }
    .container {
       .item {
       &>p{
        margin-bottom: 0;
       }
        p {
          /* margin-bottom: 20px; */

         
            /* height: 88%; */
            &>video {
              border-radius: 12px;
          height: 300px;
          /* margin-bottom: 20px; */


            }
         
          &>em {
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 25px;
          }
        }

      
        p {
          &>em {
            font-size: 12px;
            line-height: 16px;
          }
        }
        p {
          margin-bottom: 0;
          &>iframe {
            height: 200px;
            border-radius: 10px;
          border: none;
          }
        }
      }
      .line {
        margin-top: 60px;
      }
    }
    
    
}
@media screen and (max-width: 324px){
    padding: 10px 20px 120px;
    .heading{
        h1{
       
       font-size: 20px;
       line-height: 24px;
      

   }
   .news{
    margin-top: 30px;
   }

}
   .related{
    h1{
        font-size: 20px;
       line-height: 24px;
      
    }
   }
   .content{
    /* margin-bottom */
    .line{
        margin-top: 60px;
    }
     .item {
        p {
          font-size: 14px;
          line-height: 30px;
        }
        .text {
          text-align: justify;
        }
        p{
          video{
            height: 250px;
          margin-bottom: 10px;
          }
         
        }
        p {
          img{
            margin-bottom: 10px;

          }
        }
        
        p {
          &>iframe {

            height: 180px;
            width: 100%;

            border-radius: 10px;
          border: none;
          }
        }
      }
   }
  
    .share{
        .feature{
            width: 70%;
            p{
                font-size: 6px;
                padding: 2px 4px;
                line-height: 8px!important;
            }
        }
    }
    
}

`

export default Body