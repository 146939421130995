import React, { useEffect } from "react";
// import Intro from '../components/Blogdetail/Intro';
import Header from "../components/Share/Header";
import Nav from "../components/Share/Nav";
import { useTumysContext } from "../context/tumycontext";
import sologan from "../assets/images/gokulogoblack.png";
import Body from "../components/Blogdetail/Body";
import { useParams } from "react-router-dom";
import Loadingproject from "../components/Share/Loadingproject";
import { Helmet } from "react-helmet-async";
import sologanblack from "../assets/images/gokulogoblack.png";

function Singleblogpage() {
  const {
    english,
    nav,
    fetchSingleBLog,
    fetchMoreBlog,
    currentSingleBLog,
    isBlogLoading,
  } = useTumysContext();
  let { id } = useParams();

  useEffect(() => {
    fetchSingleBLog(id, `${english ? "en" : "vi"}`, 3);

    fetchMoreBlog(`${english ? "en" : "vi"}`, id, 3);
  }, [id, english]);
  useEffect(() => {}, [currentSingleBLog.name]);
  // console.log(currentSingleBLog)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id, english]);

  // console.log(currentSingleBLog)
  return (
    <>
      {nav ? (
        <Nav />
      ) : (
        <>
          <Helmet>
            <meta charset="utf-8" />
            <link rel="icon" href="https://goku.agency/img/favicon.png" />
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1"
            />
            <meta name="theme-color" content="#000000" />

            <meta
              data-rh="true"
              name="description"
              content={`${currentSingleBLog.briefSEO}`}
            />

            <title>{currentSingleBLog.name}</title>
            <meta data-rh="true" name="robots" content="all" />

            <meta
              data-rh="true"
              property="og:title"
              content={`${currentSingleBLog.name}`}
            />
            <meta
              data-rh="true"
              property="og:description"
              content={`${currentSingleBLog.briefSEO}`}
            />
            <meta data-rh="true" property="og:type" content="website" />
            <meta
              data-rh="true"
              property="og:url"
              content={`https://goku.agency/blog/${currentSingleBLog.id}/2/${currentSingleBLog.slug}`}
            />
            <meta
              data-rh="true"
              property="og:image"
              content="https://goku.agency/img/rsz_goku.png"
            />
            <meta data-rh="true" property="og:image:alt" content="Goku" />
            <meta data-rh="true" property="og:image:width" content="1200" />
            <meta data-rh="true" property="og:image:height" content="628" />
            <link
              rel="canonical"
              href={`https://goku.agency/blog/${currentSingleBLog.id}/2/${currentSingleBLog.slug}`}
            />
          </Helmet>

          <Header
            logoBlack={sologanblack}
            iconBlack={
              <svg
                width="36"
                height="24"
                viewBox="0 0 36 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                  fill="#484848"
                />
              </svg>
            }
            fixed
            icon={
              <svg
                width="36"
                height="24"
                viewBox="0 0 36 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z"
                  fill="#484848"
                />
              </svg>
            }
            logo={sologan}
          />
          {isBlogLoading ? <Loadingproject /> : <Body />}
          {/* <Body /> */}
        </>
      )}
    </>
  );
}

export default Singleblogpage;
