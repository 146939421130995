import React from 'react'
import styled from 'styled-components'
import Filter from './Filter'
import Pagenumber from './Pagenumber'
import Project from './Project'
import { useMediaQuery } from 'usehooks-ts'
import Filtermobile from './Filtermobile'
import { useTumysContext } from '../../context/tumycontext'
import Loadingproject from '../Share/Loadingproject'
import SearchInput from '../Share/SearchInput'
import Pagenumbernew from './Pagenumbernew'

function Body() {
  const matches = useMediaQuery('(max-width: 650px)')
  const { english, handleLanguage, nav, handleNav,fetchProject,fetchFilter,VerseProject } = useTumysContext();

  return (
    <Wrapper>
     {/* <Filter /> */}

     {matches ? <Filtermobile /> : <Filter />}
     <SearchInput />
      {VerseProject.isLoading ? <Loadingproject/> :  <Project />}
    
     {/* <Pagenumber /> */}
     <Pagenumbernew />

      
    </Wrapper>
  )
}
const Wrapper = styled.div`
padding: 60px 200px;
background-color: white;
min-height: 500px;
/* position: ; */

@media screen and (max-width: 1396px) {
  padding: 60px 60px;

  
}

@media screen and (max-width: 1024px) {
  padding: 60px 25px;

  
}
@media screen and (max-width: 796px) {
  padding: 40px 15px;
  /* margin-top: 30px; */
  
  
}
@media screen and (max-width: 524px) {
  padding: 30px 20px;
  /* margin-top: 20px; */

  
  
}
@media screen and (max-width: 324px) {
  
  padding: 30px 10px;
  /* margin-top: 15px; */

  
}


`

export default Body