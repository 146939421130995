// import {
//     SIDEBAR_OPEN,
//     SIDEBAR_CLOSE,
//     GET_PRODUCTS_BEGIN,
//     GET_PRODUCTS_SUCCESS,
//     GET_PRODUCTS_ERROR,
//     GET_SINGLE_PRODUCT_BEGIN,
//     GET_SINGLE_PRODUCT_SUCCESS,
//     GET_SINGLE_PRODUCT_ERROR,
//   } from "../utils/actions";

import { faSleigh } from "@fortawesome/free-solid-svg-icons";

  
  const tumys_reducer = (state, action) => {
    switch (action.type) {
      //Home
      case "GET_PROJECT_HOME_START":
          return {...state, HomeProject: {...state.HomeProject,isLoading: true}};
      case "GET_PROJECT_HOME_SUCCESS":
        // console.log();
        const arrayData1 = [...action.payload.data.slice(0,8)]
        arrayData1.splice(4,0,{ 
          id: 6,
          textTitle : `${state.english ? "OUR WORK":"Dự án"}`,
          height: "20",
          button : true
        })

        if(arrayData1[0]){
          arrayData1[0].height1= "40%";

        }
        if(arrayData1[3]){
          arrayData1[3].height1= "25%";

        }
        if(arrayData1[6]){
          arrayData1[6].height1= "35%";

        }
        if(arrayData1[1]){
          arrayData1[1].height1= "30%";

        }
        if(arrayData1[4]){

        arrayData1[4].height1= "20%";
      }

        if(arrayData1[7]){
          arrayData1[7].height1= "50%";

        }
        if(arrayData1[2]){
          arrayData1[2].height1= "35%";

        }
        if(arrayData1[5]){
          arrayData1[5].height1= "25%";

        }
        if(arrayData1[8]){
          arrayData1[8].height1= "40%";

        }


        // console.log(array)

        //  console.log(action.payload.data)
        const arrayData2 = [...action.payload.data.slice(8,17)]
        if(arrayData2[0]){
          arrayData2[0].height1= "40%";

        }
        if(arrayData2[3]){
          arrayData2[3].height1= "25%";

        }
          if(arrayData2[6]){
            arrayData2[6].height1= "35%";

          }
          if(arrayData2[1]){
        arrayData2[1].height1= "30%";
            
          }
          if(arrayData2[4]){
            arrayData2[4].height1= "20%";

          }
          if(arrayData2[7]){
            arrayData2[7].height1= "50%";

          }

          if(arrayData2[2]){
            arrayData2[2].height1= "35%";

          }
          if(arrayData2[5]){
            arrayData2[5].height1= "25%";

          }
          if(arrayData2[8]){
            arrayData2[8].height1= "40%";

          }

      






        // console.log(array)
            return {...state, HomeProject: {...state.HomeProject,isLoading: false,data1: [...arrayData1],data2:[...arrayData2]}};
      case "GET_PROJECT_HOME_ERROR":
              return {...state, HomeProject: {...state.HomeProject,isLoading: false}};
      // BLog
      case "GET_BLOG_START":
          return {...state, isBlogLoading : true};
          
      case "GET_BLOG_SUCCESS":
       
            return {...state, currentpageBlog : [...action.payload.blogs],isBlogLoading:false,totalBlogs: action.payload.total,perPage: action.payload.perPage}
      case "GET_BLOG_ERROR":
            return {...state,isBlogLoading: true }
      case "UPDATE_PAGE_NUMBER_BLOG":
            return {...state,currentNumberBlog: action.payload.page}
      case "GET_SINGLE_BLOG_START":
              return {...state, isSingleBlogLoading : true}
      case "GET_SINGLE_BLOG_SUCCESS":
              return {...state,currentSingleBLog : {...action.payload},isSingleBlogLoading: false}
      case "GET_SINGLE_BLOG_ERROR":
              return {...state,isSingleBlogLoading: false}
      case "GET_MORE_BLOG_START":
              return {...state,isBlogLoading: true}
      case "GET_MORE_BLOG_SUCCESS":
               return {...state,isBlogLoading: false,moreBlog: action.payload.blogs}
      case "GET_MORE_BLOG_ERROR":
              return {...state,isBlogLoading: false}

           

      // End BLog
      case "HANDLE_LANGUAGE":
          if(state.english===false){
            return { ...state, english: true };

          }else{
            return { ...state, english: false };


          }
      case "HANDLE_NAV":

        return {...state, nav: action.payload };

           
              // if(state.nav===false){
              //   return {...state, nav: true };
    
              // }
              // if(state.nav===true){
              //   return {...state, nav: false };

              // }
             
      //PROJECT
      case "GET_PROJECT_START":
        if(action.payload.project==="goku-product"){
            return {...state,ProductionProject: {...state.ProductionProject,isLoading : true}}

        }else{
          return {...state,VerseProject: {...state.VerseProject,isLoading : true}}
        }
      case "GET_PROJECT_SUCCESS":
        if(action.payload.project==="goku-product"){
          
          return {...state,ProductionProject: {...state.ProductionProject,dataProject: [...action.payload.listProjects],currentArray: [...action.payload.listProjects], total : action.payload.total,isLoading : false }}

      }else{
          return {...state,VerseProject: {...state.VerseProject,dataProject: [...action.payload.listProjects],currentArray: [...action.payload.listProjects], total : action.payload.total,isLoading : false }}
      }
      case "GET_SINGLE_PROJECT_START":
        
            return {...state,SingleProject: {...state.SingleProject,isLoading : true}}

       
      case "GET_SINGLE_PROJECT_SUCCESS":
         
           
            return {...state,SingleProject: {...state.SingleProject,data: {...action.payload.data},isLoading: false}}
  
       
      case "GET_SINGLE_PROJECT_ERROR":
       
            return {...state,SingleProject: {...state.SingleProject,isLoading : false}}

        
      case "GET_MORE_PROJECT_START":
        
            return {...state,SingleProject: {...state.SingleProject,isLoading : true}}

        
      case "GET_MORE_PROJECT_SUCCESS":
       
            return {...state,SingleProject: {...state.SingleProject,isLoading : true,otherProject: action.payload.data}}

        
      case "GET_MORE_PROJECT_ERROR":

              return {...state,SingleProject: {...state.SingleProject,isLoading : false}}
  
        
      case "GET_FILTER_SUCCESS":
          if(action.payload.project==="goku-product"){
           
          return {...state,ProductionProject: {...state.ProductionProject,filterAPI : action.payload.data}}

      }
      if(action.payload.project==="goku-verse")
      {
        return {...state,VerseProject: {...state.VerseProject,filterAPI : action.payload.data}}
      }

      case "UPDATE_CURRENT_PAGE_PRODUCTION":
          return {...state,ProductionProject: {...state.ProductionProject,currentPage: action.payload}}
      case "UPDATE_CURRENT_PAGE_VERSE":
            return {...state,VerseProject: {...state.VerseProject,currentPage: action.payload}}

           

              
      case "UPDATE_CURRENT_FILTER_GOKUVERSE":
              return {...state,VerseProject:{...state.VerseProject,currentFilter:action.payload.filter}}
      case "UPDATE_CURRENT_FILTER_GOKUPRODUCTION":
              return {...state,ProductionProject:{...state.ProductionProject,currentFilter:action.payload.filter}}
      
              
      case "UPDATE_PROJECT_GOKUVERSE":
        const tempgokuverse = [
          ...state.ProductionProject.dataProject
        ]
        // console.log("temp1",temp1)
        const filtergokuverse= tempgokuverse.filter((item)=>{
              const arrayFilter = item.categories.map((itemfilter)=>{
                return itemfilter.name
              })
             return arrayFilter.includes(state.ProductionProject.currentFilter)
        })
        // console.log(filter1)

        if(state.VerseProject.currentFilter==="Tất cả"){
          // console.log("all"
              return {
                ...state,VerseProject: {...state.VerseProject,currentArray: state.VerseProject.dataProject}
                
              }
        }
        // console.log("notall")
        
        return {...state,VerseProject: {
          ...state.VerseProject,currentArray : [...filtergokuverse]
        }}
      case "UPDATE_PROJECT_GOKUPRODUCTION":
        // console.log("test")
                const temp1 = [
                  ...state.ProductionProject.dataProject
                ]
                // console.log("temp1",temp1)
                const filter1= temp1.filter((item)=>{
                      const arrayFilter = item.categories.map((itemfilter)=>{
                        return itemfilter.name
                      })
                     return arrayFilter.includes(state.ProductionProject.currentFilter)
                })
                // console.log(filter1)

                if(state.ProductionProject.currentFilter==="Tất cả"){
                  // console.log("all"
                      return {
                        ...state,ProductionProject: {...state.ProductionProject,currentArray: state.ProductionProject.dataProject}
                        
                      }
                }
                // console.log("notall")
                
                return {...state,ProductionProject: {
                  ...state.ProductionProject,currentArray : [...filter1]
                }}
      case "UPDATE_INPUT":
                return {...state, contact:{...state.contact,[action.payload.name]: action.payload.value}}
      case "UPDATE_ERROR":
                return {...state,contact:{
                  ...state.contact,isError: action.payload.error,textError : action.payload.textError 
                }}
      case "UPDATE_SUCCESS":
                  return {...state,contact:{
                    ...state.contact,
                    name: "",
                    email: "",
                    message: "",
                    textSuccess:"Gửi email thành công",
                    success: true,
                   
                  }}
      case "UPDATE_META_TAG":
                  return{
                    ...state,thumb: action.payload.thumb,title: action.payload.title,description: action.payload.description,url : action.payload.url
                  }
      case "UPDATE_SEARCH_PRODUCTION":
                    return{
                      ...state,ProductionProject: {...state.ProductionProject,searchValue : action.payload.value}
                    }
      case "UPDATE_SEARCH_VERSE":
        return{
          ...state,VerseProject: {...state.VerseProject,searchValue : action.payload.value}
        }
      case "SEARCH_PROJECT_START":
       
          if(action.payload.project==="goku-product"){
            return {
              ...state,ProductionProject:{...state.ProductionProject,searchLoading : true}
            }
          }
          if(action.payload.project==="goku-verse"){
            return {
              ...state,VerseProject:{...state.VerseProject,searchLoading : true}
            }
          }
        
       
         
      case "SEARCH_PROJECT_SUCCESS":
        if(action.payload.listProjects!==undefined){
          if(action.payload.project==="goku-product"){
            if(state.ProductionProject.searchValue.trim()!==""){
            return {
              ...state,ProductionProject:{...state.ProductionProject,searchLoading : false,currentArray:  action.payload.listProjects}
            }
          }}
          if(action.payload.project==="goku-verse"){
            if(state.VerseProject.searchValue.trim()!==""){
            return {
              ...state,VerseProject:{...state.VerseProject,searchLoading : false,currentArray: action.payload.listProjects}
            }
          }}
        }
         
         
      case "SEARCH_PROJECT_ERROR":
           
            if(action.payload.project==="goku-product"){
              return {
                ...state,ProductionProject:{...state.ProductionProject,searchLoading : false,currentArray: []}
              }
            }
            if(action.payload.project==="goku-verse"){
              return {
                ...state,VerseProject:{...state.VerseProject,searchLoading : false,currentArray: []}
              }
            }
          

     
      default:
        throw new Error(`No Matching "${action.type}" - action type`);
    }
  };
  
  export default tumys_reducer;
  