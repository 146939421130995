import React, { useState } from "react";
import styled from "styled-components";
import sologan from "../../assets/images/sologanintro.png";
import backgroundnav from "../../assets/images/backgroundnav.png";

import { Link, useLocation } from "react-router-dom";
import { useTumysContext } from "../../context/tumycontext";

function Nav() {
  const [active, setActive] = useState("/home");

  const { english, handleLanguage, nav, handleNav } = useTumysContext();
  const location = useLocation();
  const data = [
    {
      id: 1,
      text: `${english ? "HOME" : "TRANG CHỦ"}`,

      link: "/home",
      // active: `${location.pathname === active ? true : false}`
    },
    {
      id: 2,
      text: `${english ? "WORKS" : "DỰ ÁN"}`,
      // active: `${location.pathname === active ? true : false}`,

      link: "",
      submenu: [
        {
          id: 1,
          text: "Goku Production",
          link: "/work/gokuproduction",
          // active: `${location.pathname === active ? true : false}`
        },
        { id: 2, text: "Gokuverse", link: "/work/gokuverse" },
      ],
    },
    {
      id: 3,
      text: `${english ? "SERVICES" : "DỊCH VỤ"}`,

      link: "",
      submenu: [
        {
          id: 1,
          text: "Goku Production",
          link: "/service/gokuproduction",
          // active: `${location.pathname === active ? true : false}`
        },
        // {
        //   id :2 ,
        //   text : `${english?"LANDING" : "Chụp ảnh Khách sạn"}`,

        //   link :"/service/chup-anh-khach-san-resort",
        //   // active: `${location.pathname === active ? true : false}`

        // },
        { id: 2, text: "Gokuverse", link: "/service/gokuverse" },
        // { id: 3, text: "Chụp Ảnh Tiến Độ", link: "/service/chup-anh-quay-phim-tien-do-du-an-bat-dong-san" },

      ],
    },
    {
      id: 4,
      text: `${english ? "ABOUT US" : "GIỚI THIỆU"}`,
      link: "/aboutus",
      // active: `${location.pathname === active ? true : false}`
    },
    {
      id: 5,
      text: "BLOG",
      link: "/blog",
    },
    {
      id: 6,
      text: "PROFILE",
      link: "/profile",
    },
    {
      id: 7,
      text: `${english ? "CONTACT" : "LIÊN HỆ"}`,
      link: "/contact",
    },
  ];
  // console.log()
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundnav})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="homeintro show"
    >
      <Navwrapper>
        <div className="header">
          <div className="header-logo">
            <img style={{ width: "100%" }} alt="sologan" src={sologan} />
          </div>
          <div className="header-icon">
            {/* <div onClick={() => handleLanguage()} className="btn-language">
              <div className={`${!english && "active"}`}>VI</div>
              <div className={`${english && "active"}`}>EN</div>
            </div> */}

            <svg
              onClick={() => handleNav(false)}
              width="24"
              height="24"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.4343 0.735992L16.9993 14.172L3.56433 0.735992L0.736328 3.56399L14.1713 17L0.736328 30.436L3.56433 33.264L16.9993 19.828L30.4343 33.264L33.2623 30.436L19.8273 17L33.2623 3.56399L30.4343 0.735992Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div className="content">
          <div className="contact">
            <div className="phone">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.2005 18.176L15.6105 15.776C15.427 15.6974 15.2242 15.6755 15.0282 15.7131C14.8322 15.7507 14.6519 15.8461 14.5105 15.987L12.1765 18.287C10.9577 17.5541 9.8252 16.6863 8.8005 15.7C7.8155 14.6762 6.94749 13.5459 6.2125 12.33L8.5125 10C8.65294 9.85832 8.74779 9.67787 8.78485 9.48185C8.82192 9.28582 8.79951 9.0832 8.7205 8.90001L6.3205 3.31001C6.22612 3.09046 6.05624 2.91196 5.84161 2.80685C5.62699 2.70173 5.38181 2.67696 5.1505 2.73701L0.750501 3.90001C0.53048 3.9567 0.336324 4.08662 0.2 4.26839C0.063677 4.45015 -0.00668491 4.67292 0.000500499 4.90001C0.280578 10.0137 2.40726 14.852 5.9855 18.516C9.65052 22.0956 14.491 24.2221 19.6065 24.5C19.8335 24.5073 20.0561 24.437 20.2379 24.3009C20.4196 24.1648 20.5496 23.9708 20.6065 23.751L21.7685 19.351C21.8302 19.1196 21.8068 18.8737 21.7026 18.6581C21.5984 18.4424 21.4202 18.2714 21.2005 18.176Z"
                  fill="url(#paint0_linear_158_1282)"
                />
                <path
                  d="M18 10.5C18 10.7652 18.1054 11.0196 18.2929 11.2071C18.4804 11.3946 18.7348 11.5 19 11.5C19.2652 11.5 19.5196 11.3946 19.7071 11.2071C19.8946 11.0196 20 10.7652 20 10.5C19.9984 8.90919 19.3658 7.38399 18.2409 6.25911C17.116 5.13424 15.5908 4.50159 14 4.5C13.7348 4.5 13.4804 4.60536 13.2929 4.79289C13.1054 4.98043 13 5.23478 13 5.5C13 5.76522 13.1054 6.01957 13.2929 6.20711C13.4804 6.39464 13.7348 6.5 14 6.5C15.0609 6.5 16.0783 6.92143 16.8284 7.67157C17.5786 8.42172 18 9.43913 18 10.5Z"
                  fill="url(#paint1_linear_158_1282)"
                />
                <path
                  d="M14 0.5C13.7348 0.5 13.4804 0.605357 13.2929 0.792893C13.1054 0.98043 13 1.23478 13 1.5C13 1.76522 13.1054 2.01957 13.2929 2.20711C13.4804 2.39464 13.7348 2.5 14 2.5C16.121 2.50238 18.1544 3.346 19.6542 4.84578C21.154 6.34555 21.9976 8.379 22 10.5C22 10.7652 22.1054 11.0196 22.2929 11.2071C22.4804 11.3946 22.7348 11.5 23 11.5C23.2652 11.5 23.5196 11.3946 23.7071 11.2071C23.8946 11.0196 24 10.7652 24 10.5C23.9971 7.84873 22.9426 5.30688 21.0679 3.43215C19.1931 1.55742 16.6513 0.502912 14 0.5V0.5Z"
                  fill="url(#paint2_linear_158_1282)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_158_1282"
                    x1="0.673889"
                    y1="8.02708"
                    x2="14.2954"
                    y2="8.14433"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E92EE" />
                    <stop offset="1" stop-color="#BE6BFF" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_158_1282"
                    x1="13.2164"
                    y1="6.20929"
                    x2="17.5898"
                    y2="6.24693"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E92EE" />
                    <stop offset="1" stop-color="#BE6BFF" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_158_1282"
                    x1="13.34"
                    y1="3.18603"
                    x2="20.2125"
                    y2="3.24517"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E92EE" />
                    <stop offset="1" stop-color="#BE6BFF" />
                  </linearGradient>
                </defs>
              </svg>
              0915 444 235
            </div>

            <div className="mail">
              <svg
                width="22"
                height="19"
                viewBox="0 0 22 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.0992 0.499908H2.89922C2.18313 0.499908 1.49638 0.784372 0.99003 1.29072C0.483682 1.79707 0.199219 2.48382 0.199219 3.19991L0.199219 15.7999C0.199219 16.516 0.483682 17.2027 0.99003 17.7091C1.49638 18.2154 2.18313 18.4999 2.89922 18.4999H19.0992C19.8153 18.4999 20.5021 18.2154 21.0084 17.7091C21.5148 17.2027 21.7992 16.516 21.7992 15.7999V3.19991C21.7992 2.48382 21.5148 1.79707 21.0084 1.29072C20.5021 0.784372 19.8153 0.499908 19.0992 0.499908ZM18.8292 4.81991L11.6292 11.1199C11.4332 11.221 11.2191 11.2821 10.9992 11.2999C10.7794 11.2821 10.5653 11.221 10.3692 11.1199L3.16922 4.81991C2.9902 4.66476 2.88014 4.44484 2.86326 4.20855C2.84639 3.97225 2.92407 3.73893 3.07922 3.55991C3.23437 3.38089 3.45428 3.27083 3.69058 3.25395C3.92688 3.23708 4.1602 3.31476 4.33922 3.46991L10.9092 9.22991L17.4792 3.46991C17.5667 3.39074 17.6694 3.33009 17.7809 3.29162C17.8925 3.25315 18.0107 3.23765 18.1284 3.24606C18.2461 3.25447 18.3609 3.28661 18.4659 3.34055C18.5709 3.39448 18.6638 3.4691 18.7392 3.55991C18.8389 3.62888 18.9219 3.71936 18.9819 3.82466C19.042 3.92996 19.0777 4.0474 19.0863 4.16833C19.095 4.28925 19.0763 4.41058 19.0318 4.52335C18.9873 4.63612 18.9181 4.73746 18.8292 4.81991Z"
                  fill="url(#paint0_linear_158_1290)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_158_1290"
                    x1="0.866855"
                    y1="4.89527"
                    x2="20.0913"
                    y2="4.92172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2EEE86" />
                    <stop offset="1" stop-color="#26BEEE" />
                  </linearGradient>
                </defs>
              </svg>
              thang@goku.agency
            </div>

            <div className="social">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C6.477 2 2 6.477 2 12C2 16.991 5.657 21.128 10.438 21.879V14.89H7.898V12H10.438V9.797C10.438 7.291 11.93 5.907 14.215 5.907C15.309 5.907 16.453 6.102 16.453 6.102V8.562H15.193C13.95 8.562 13.563 9.333 13.563 10.124V12H16.336L15.893 14.89H13.563V21.879C18.343 21.129 22 16.99 22 12C22 6.477 17.523 2 12 2Z"
                  fill="white"
                />
              </svg>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C14.717 2 15.056 2.01 16.122 2.06C17.187 2.11 17.912 2.277 18.55 2.525C19.21 2.779 19.766 3.123 20.322 3.678C20.8305 4.1779 21.224 4.78259 21.475 5.45C21.722 6.087 21.89 6.813 21.94 7.878C21.987 8.944 22 9.283 22 12C22 14.717 21.99 15.056 21.94 16.122C21.89 17.187 21.722 17.912 21.475 18.55C21.2247 19.2178 20.8311 19.8226 20.322 20.322C19.822 20.8303 19.2173 21.2238 18.55 21.475C17.913 21.722 17.187 21.89 16.122 21.94C15.056 21.987 14.717 22 12 22C9.283 22 8.944 21.99 7.878 21.94C6.813 21.89 6.088 21.722 5.45 21.475C4.78233 21.2245 4.17753 20.8309 3.678 20.322C3.16941 19.8222 2.77593 19.2175 2.525 18.55C2.277 17.913 2.11 17.187 2.06 16.122C2.013 15.056 2 14.717 2 12C2 9.283 2.01 8.944 2.06 7.878C2.11 6.812 2.277 6.088 2.525 5.45C2.77524 4.78218 3.1688 4.17732 3.678 3.678C4.17767 3.16923 4.78243 2.77573 5.45 2.525C6.088 2.277 6.812 2.11 7.878 2.06C8.944 2.013 9.283 2 12 2ZM12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7ZM18.5 6.75C18.5 6.41848 18.3683 6.10054 18.1339 5.86612C17.8995 5.6317 17.5815 5.5 17.25 5.5C16.9185 5.5 16.6005 5.6317 16.3661 5.86612C16.1317 6.10054 16 6.41848 16 6.75C16 7.08152 16.1317 7.39946 16.3661 7.63388C16.6005 7.8683 16.9185 8 17.25 8C17.5815 8 17.8995 7.8683 18.1339 7.63388C18.3683 7.39946 18.5 7.08152 18.5 6.75ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9Z"
                  fill="white"
                />
              </svg>

              <svg
                width="24"
                height="16"
                viewBox="0 0 24 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.9991 5.70998C23.0485 4.27864 22.7355 2.858 22.0891 1.57998C21.6505 1.05558 21.0418 0.701694 20.3691 0.579984C17.5866 0.327509 14.7926 0.224028 11.9991 0.269984C9.2157 0.221942 6.43176 0.322084 3.65905 0.569984C3.11087 0.669701 2.60357 0.926826 2.19905 1.30998C1.29905 2.13998 1.19905 3.55998 1.09905 4.75998C0.953962 6.91755 0.953962 9.08242 1.09905 11.24C1.12798 11.9154 1.22854 12.5858 1.39905 13.24C1.51963 13.745 1.76357 14.2123 2.10905 14.6C2.51632 15.0034 3.03543 15.2752 3.59905 15.38C5.75497 15.6461 7.92727 15.7564 10.0991 15.71C13.5991 15.76 16.669 15.71 20.299 15.43C20.8765 15.3316 21.4102 15.0595 21.8291 14.65C22.109 14.3699 22.3181 14.0271 22.4391 13.65C22.7967 12.5526 22.9723 11.4041 22.959 10.25C22.9991 9.68998 22.9991 6.30998 22.9991 5.70998ZM9.73905 10.85V4.65998L15.659 7.76998C13.9991 8.68998 11.809 9.72998 9.73905 10.85Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>

          <ul className="nav-menu">
            {data.map((item) => {
              return (
                <li
                  onClick={() => {
                    setActive(item.link);

                    if (item.link !== "") {
                      handleNav(false);
                    }
                  }}
                  className="item-menu"
                >
                  {item.link ? (
                    <Link
                      className={`${item.link === active && "active"}`}
                      to={item.link}
                    >
                      {item.text}
                    </Link>
                  ) : (
                    <h1 className={`${item.link === active && "active"}`}>
                      {item.text}
                    </h1>
                  )}
                  {item.submenu && (
                    <ul>
                      {item.submenu.map((itemsubmenu, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => handleNav(false)}
                            className="item-submenu"
                          >
                            <Link to={itemsubmenu.link}>
                              {itemsubmenu.text}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </Navwrapper>
    </div>
  );
}

const Navwrapper = styled.section`
  width: 100%;
  /* min-height: 900px; */
  height: 100vh;
  /* display: none; */
  position: relative;
  background-color: black;

  .active {
    /* text-decoration: underline; */
    /* line-height: 36px; */
  }
  .header {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 0;
    z-index: 10;

    .header-logo {
      width: 160px;
      img {
      }
    }

    .header-icon {
      display: flex;
      gap: 20px;
      .btn-language {
        width: 20vh;
        height: 30px;
        background-color: transparent;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        border: 1px solid white;
        div {
          width: 50%;
          text-align: center;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          color: white;
        }
        .active {
          background-color: white;
          color: black;
        }
      }
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px 20px;
    height: 85%;
    /* height: 100%; */
  }
  .contact {
    align-self: flex-end;
    div {
      display: flex;
      /* justify-content: center; */
      /* gap: 20px; */
      align-items: center;
      margin-top: 16px;
      color: white;
    }
    .phone {
      svg {
        margin-right: 8px;
      }
    }
    .mail {
      svg {
        margin-right: 8px;
      }
    }
    .social {
      svg {
        margin-right: 15px;
      }
    }
  }
  .nav-menu {
    margin-right: 5vw;
    text-align: right;
    .item-menu {
      margin-bottom: 30px;
      & > a {
        width: 100%;
        height: 100%;
        color: white;
        font-size: 28px;
        font-family: SF-Pro-Display-Bold;
        /* margin-bottom: 10px; */
        cursor: pointer;
      }
      h1 {
        width: 100%;
        height: 100%;
        color: white;
        font-size: 28px;
        font-family: SF-Pro-Display-Bold;
        margin-bottom: 0;
        /* margin-bottom: 20px; */
      }
      & > ul {
        margin: 5px 0 0 0;
        padding: 0;
      }

      .item-submenu {
        a {
          color: #c6c6c6;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 569px) {
    .header {
      .header-logo {
        width: 120px;
      }
      .header-icon {
        .btn-language {
          width: 14vh;
        }
      }
    }
    .nav-menu {
      .item-menu {
        & > a {
          font-size: 24px;
        }
        h1 {
          font-size: 24px;
        }
      }
    }
  }

  /* @media screen and (max-width: 424px) {
    .header{
      .header-logo{
      width: 50px;
        
      }
    }
  } */
  @media screen and (max-width: 324px) {
    .header {
      padding: 30px 15px 0;
      .header-logo {
        width: 80px;
      }
      .header-icon {
        .btn-language {
          width: 12vh;
        }
      }
    }
    .content {
      padding: 10px 15px 20px;
      .contact {
        flex-basis: 40%;
        & > div {
          font-size: 12px;
          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
      .nav-menu {
        flex-basis: 60%;
        margin-right: 0;

        .item-menu {
          margin-bottom: 12px;
          & > a {
            font-size: 16px;
          }
          & > h1 {
            font-size: 16px;
          }
          .item-submenu {
            a {
              font-size: 10px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
`;

export default Nav;
