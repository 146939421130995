import React from "react";
import styled from "styled-components";
import Chuyennghiep from "./Chuyennghiep";
import Tableprice from "./Tableprice";
import Taptrung from "./Taptrung";
import Toanquoc from "./Toanquoc";
import { useMediaQuery } from "usehooks-ts";
import Toanquocmobile from "./Toanquocmobile";
import Haukynew from "./Haukynew";
import Chuyennghiepmobile from "./Chuyenghiepmobile";
import coverkame from "../../assets/images/coverkame.jpeg";
import covergoku from "../../assets/images/covergoku.jpg";
import Homepartner from "../Home/Homepartner";
import Kame360 from "./Kame360";
import ImageMasonry from "./ImageMasonry";

function Body() {
  const matches = useMediaQuery("(max-width: 650px)");

  return (
    <>
      <Wrapper>
        {/* <Tienloi /> */}
        {matches ? <Toanquocmobile /> : <Toanquoc />}
        {/* <Toanquoc /> */}
        <Taptrung />

        {matches ? <Chuyennghiepmobile /> : <Chuyennghiep />}

        {/* <Hauky /> */}
        <Haukynew />
      </Wrapper>
      <Wrapper2>
        <Tableprice />
        <Kame360 />
        {/* <Quayphimflycam /> */}
        {/* <Flycam /> */}
        {/* <Landingfeature /> */}
        <ImageMasonry />
      </Wrapper2>
      {/* <div className="kame-cover">
        <img style={{ width: "100%" }} alt="Kame Image" src={covergoku} />
      </div> */}
      <Homepartner />
    </>
  );
}
const Wrapper = styled.section`
  background-color: #f4f5f6;
  /* min-height: 500px; */
  /* min-height: 1000px; */
  padding: 0 280px 80px;
  @media screen and (max-width: 1524px) {
    padding: 0 100px 80px;
  }
  @media screen and (max-width: 1396px) {
    padding: 0 80px 80px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 50px 80px;
  }
  @media screen and (max-width: 796px) {
    /* padding: 0 40px 80px; */
    padding: 0;
  }
  @media screen and (max-width: 596px) {
    /* padding: 0 30px 50px; */
  }
  @media screen and (max-width: 424px) {
    /* padding: 0 30px 30px; */
  }
  @media screen and (max-width: 300px) {
    /* padding: 0 8px 30px; */
  }
`;
const Wrapper2 = styled.section`
  padding: 0 160px 40px;
  background-color: #f4f5f6;

  @media screen and (max-width: 1524px) {
    padding: 0 100px 40px;
  }
  @media screen and (max-width: 1396px) {
    padding: 0 0px 40px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 50px 40px;
  }
  @media screen and (max-width: 796px) {
    /* padding: 0 40px 40px; */
    padding: 0;
  }
  @media screen and (max-width: 596px) {
    /* padding: 0 30px 40px; */
  }
  @media screen and (max-width: 424px) {
    /* padding: 0 30px 40px; */
  }
  @media screen and (max-width: 300px) {
    /* padding: 0 10px 40px; */
  }
`;

export default Body;
