import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import Body from '../components/Aboutus/Body';
import Intro from '../components/Aboutus/Intro';
import Nav from '../components/Share/Nav';
import { useTumysContext } from '../context/tumycontext';

function Aboutus() {
    const { updateMeta,english, handleLanguage, nav, handleNav } = useTumysContext();
    useEffect(() => {
      window.scrollTo(0, 0);
      // updateMeta(`ABOUT US`,`Learn more about our company and our mission on our about us page. Discover who we are, what we do, and what sets us apart. Our team of experts is committed to providing exceptional products/services and excellent customer service. Read our story and see why we are the best choice for your needs.`,"https://goku.agency/img/thumb-min.png",`https://goku.agency/aboutus`)
    }, [])
  return (
    <>
    <Helmet>
    <meta charset="utf-8" />
    <link rel="icon" href="https://goku.agency/img/favicon.png" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    
    <meta data-rh="true" name="description" content="Công ty Cổ phần Goku Việt Nam - là đơn vị tiên phong trong lĩnh vực sản xuất media cho hạng mục kiến trúc, nội thất và bất động sản" />

    <title>ABOUT US</title>
    <meta data-rh="true" name="robots" content="all" />
  
    <meta data-rh="true" property="og:title" content="ABOUT US" />
    <meta data-rh="true" property="og:description" content="Công ty Cổ phần Goku Việt Nam - là đơn vị tiên phong trong lĩnh vực sản xuất media cho hạng mục kiến trúc, nội thất và bất động sản" />
    <meta  data-rh="true" property="og:type" content="website" />
    <meta data-rh="true" property="og:url"content="https://goku.agency/aboutus" />
    <meta data-rh="true" property="og:image" content="https://goku.agency/img/rsz_goku.png" />
    <meta data-rh="true" property="og:image:alt" content="Goku"/>
    <meta data-rh="true" property="og:image:width" content="1200" />
    <meta data-rh="true" property="og:image:height" content="628" />
    <link rel='canonical' href='https://goku.agency/aboutus' />

    </Helmet>
    {nav?(<Nav />):(
     

     <>
      <Intro />

      <Body />

     {/* <Body /> */}
     {/* <Intro /> */}
     {/* <Body /> */}
     </>

    )}
    
    </>
  )
}

export default Aboutus