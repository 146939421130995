import React from 'react'
import styled from "styled-components";
import background from "../../assets/images/backgroundintrowork.png";
import sologanblack from "../../assets/images/gokulogoblack.png";

import sologan from "../../assets/images/sologanintro.png";
import iconheader from "../../assets/images/iconheaderhomeintro.png";
import Header from '../Share/Header';
import { useTumysContext } from '../../context/tumycontext';

function Intro() {
  const { english,handleLanguage,nav,handleNav} = useTumysContext();

  return (
    <div
    style={{
      backgroundImage: `url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center"
    }}
    className="introproductionwork show"
  >
   

    <Wrapper>
      <Header logoBlack={sologanblack} iconBlack={
<svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z" fill="#484848"/>
</svg>} fixed icon={<svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z" fill="white"/>
</svg>} logo={sologan} />

      <div className="info">
        <p>
        {english ? <>WORK</> : <>DỰ ÁN</>}
        </p>
        <h1>Gokuverse</h1>

        {/* <button>
          About <span>Goku</span>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.75 0.59C16.8388 0.590263 16.9268 0.606173 17.01 0.636999L17.032 0.645C17.1698 0.701792 17.2878 0.798073 17.3711 0.921734C17.4544 1.04539 17.4992 1.19092 17.5 1.34V16.34C17.5 16.5389 17.421 16.7297 17.2803 16.8703C17.1397 17.011 16.9489 17.09 16.75 17.09C16.5511 17.09 16.3603 17.011 16.2197 16.8703C16.079 16.7297 16 16.5389 16 16.34V3.15L1.37 17.78C1.229 17.922 1.04 18 0.84 18C0.639999 18 0.451 17.922 0.309999 17.78C0.167999 17.639 0.0900002 17.45 0.0900002 17.25C0.0900002 17.05 0.167999 16.861 0.309999 16.72L14.939 2.09H1.75C1.55109 2.09 1.36032 2.01098 1.21967 1.87033C1.07902 1.72968 1 1.53891 1 1.34C1 1.14109 1.07902 0.950321 1.21967 0.809669C1.36032 0.669018 1.55109 0.59 1.75 0.59H16.75Z"
              fill="white"
            />
          </svg>
        </button> */}
      </div>
    </Wrapper>
   

  
   


  </div>
  )
}

const Wrapper=styled.div`
 width: 100vw;
  /* min-height: 400px; */
  /* height: 40%; */
  /* min-height: 500px; */
  /* height: 40vh; */
  height: 100%;


  display: block;


  position: relative;
 .info {
    position: absolute;
    bottom: 18%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    width: 42%;
    h1 {
      color: white;
      font-size: 60px;
      font-weight: 600;
      font-family: SF-Pro-Display-Bold;
    }
    p {
      color: white;
      font-size: 20px;
      line-height: 26px;
     
      
    }
    button{
        width: 30%;
        height: 40px;
        padding: 8px;
        background-color: transparent;
        color: white;
        border: 0.7px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        cursor: pointer;
        margin-left: 50%;
        transform: translateX(-50%);
        gap: 8px;
        span{
        font-weight: 700;
      }
        svg{
            width: 15px;
        }
    }
  }
  @media screen and (max-width: 1024px) {
  /* height: 32vh; */
.info{
  width: 70%;
  h1{
    font-size: 52px;
  }
}
  
}
@media screen and (max-width: 796px) {
  .info{
  width: 70%;
  h1{
    font-size: 46px;
  }
}
  
}
@media screen and (max-width: 596px) {
  .info{
  width: 70%;
  h1{
    font-size: 28px;
  }
  p{
    margin-bottom: 8px;
    font-size: 10px;
    line-height: 14px;
  }

}
  
}
@media screen and (max-width: 424px) {
  .info{
  width: 80%;
  bottom: 8%;
  p{
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
  }
  h1{
    font-size: 22px;
  }}
  
}
@media screen and (max-width: 324px) {
  
  .info{
  width: 85%;

  p{
    margin-bottom: 8px;
  }
  h1{
    font-size: 22px;
  }}
}
`


export default Intro