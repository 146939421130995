import React, { useEffect, useState } from "react";
import styled from "styled-components";
import background from "../../assets/images/servicegokuverseintrobg.png";
import backgroundnav from "../../assets/images/backgroundnav.png";
import Nav from "../Share/Nav";
import sologan from "../../assets/images/sologanintro.png";
import iconheader from "../../assets/images/iconheaderhomeintro.png";
import sologanblack from "../../assets/images/gokulogoblack.png";

import { Link } from "react-router-dom";
import { useTumysContext } from "../../context/tumycontext";
import Header from "../Share/Header";

function Intro() {
  // const [nav,setNav] = useState(false)
 
  const { english, handleLanguage, nav, handleNav } = useTumysContext();
  const data = [
    {
      id: 1,
      text: "HOME",

      link: "",
    },
    {
      id: 2,
      text: "WORKS",

      link: "",
      submenu: [
        { id: 1, text: "Kame Production", link: "" },
        { id: 2, text: "Kame360", link: "" },
      ],
    },
    {
      id: 3,
      text: "SERVICES",

      link: "",
      submenu: [
        { id: 1, text: "Kame Production", link: "" },
        { id: 2, text: "Kame360", link: "" },
      ],
    },
    {
      id: 4,
      text: "ABOUT US",

      link: "",
    },
    {
      id: 5,
      text: "BLOG",

      link: "",
    },
    {
      id: 6,
      text: "CONTACT",

      link: "",
    },
  ];


  return (
    <>
      
        <div
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // backgroundPositionY: "60%"
          }}
          className="gokuverseintro show"
        >
          <Wrapper>
           
            <Header logoBlack={sologanblack} iconBlack={
<svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z" fill="#484848"/>
</svg>}  fixed icon={
<svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 24H0V20H18V24ZM36 14H0V10H36V14ZM36 4H18V0H36V4Z" fill="white"/>
</svg>
} logo={sologan}/>

            <div className="info">
              <h1>Gokuverse</h1>
              <p>
                {!english ? <>Gokuverse là sản phẩm phần mềm Website và App sử dụng các công nghệ cao như VR, AR, ... để phục vụ Chủ đầu tư bán sản phẩm và marketing hiệu quả.</>:<>
                Gokuverse is a software production arm under Goku. We utilise
                the latest image technology including Virtual Reality (VR) and
                Augmented Reality (AR), to help provide the most holistic and
                immersive virtual tour to the entire project and each facility.
                
                </>}
                
              </p>
            

              <p className="discovery">
              {english ? <>Let’s discovery highlight features!</> :<>
              Khám phá ngay!
              </>}
              </p>
            </div>
          </Wrapper>
        </div>
      
    </>
  );
}
const Wrapper = styled.section`
  width: 100vw;
  /* min-height: 900px; */
  height: 100%;
  display: block;

  position: relative;

 

  .info {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    width: 42%;
    h1 {
      color: white;
      font-size: 60px;
      font-weight: 600;
      font-family: SF-Pro-Display-Bold;
    }
    p {
      color: white;
      font-size: 18px;
      line-height: 26px;

      /* font-family: SF-Pro-Display-Bold; */
    }
    .discovery {
      font-family: SF-Pro-Display-Bold;
      font-size: 22px;
      background: linear-gradient(90.27deg, #E27526 3.19%, #94CE19 36.47%, #1977CE 67.73%, #BE6BFF 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

    }
    button {
      width: 30%;
      height: 40px;
      padding: 8px;
      background-color: transparent;
      color: white;
      border: 0.7px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      cursor: pointer;
      margin-left: 50%;
      transform: translateX(-50%);
      gap: 8px;
      span {
        font-weight: 700;
      }
      svg {
        width: 15px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .info{
      h1{
font-size: 56px;
}
p{
  font-size: 17px;
}
    }
  
    
  }
  @media screen and (max-width: 796px) {
    .info{
      width: 60%;
      h1{
font-size: 46px;
}
p{
  font-size: 15px;
}
.discovery{
  font-size: 20px;
}
    }
  
    
  }
  @media screen and (max-width: 524px) {
    .info{
      width: 65%;
      h1{
font-size: 42px;
}
p{
  font-size: 14px;
}
.discovery{
  font-size: 18px;
}
    }
  
    
  }
  @media screen and (max-width: 424px) {
    .info{
      width: 65%;
      h1{
font-size: 32px;
}
p{
  font-size: 12px;
  line-height: 18px;
}
.discovery{
  font-size: 16px;
}
    }
  
    
  }
`;

export default Intro;
