import React from 'react'
import styled from 'styled-components'
import Content1 from './Content1'
import Feature from './Feature'
import Info from './Info'
import Moreproject from './Moreproject'
function Body({data}) {
  return (
    <Wrapper>
        <Info data={data} />

        {/* <Feature data={data} /> */}

        <Content1 data={data} />
        <Moreproject />


        

       
    </Wrapper>
  )
}
const Wrapper= styled.div`
width: 100%;
padding: 40px 300px;
background-color:  white;

@media screen and (max-width: 1396px) {
padding: 40px 200px;
  
}
@media screen and (max-width: 1024px) {
padding: 40px 100px;
  
}
@media screen and (max-width: 796px) {
padding: 40px 60px;
  
}
@media screen and (max-width: 596px) {
padding: 40px 40px;
  
}
@media screen and (max-width: 424px) {
padding: 40px 20px;
  
}


`
export default Body