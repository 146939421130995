import React from "react";
import styled from "styled-components";
import { useTumysContext } from "../../context/tumycontext";
import { useMediaQuery } from 'usehooks-ts'


function Contact() {
  const { english,handleLanguage,nav,handleNav,contact,handleSubmit,updateInfo} = useTumysContext();
  const matches = useMediaQuery('(max-width: 1024px)')


  return (
    <Wrapper>
      <div className="container">

      
      <h1>{english? "Interesting?" : "Bạn có cảm thấy ấn tượng?"}</h1>
      {english ? (
 <h1>
 Connect to try <span>Gokuverse</span> now!
 
</h1>

      ):(
        <h1>
        Trải nghiệm <span>Gokuverse</span> ngay!
        
       </h1>
      )}
     
      <div className="content">
        <div className="contact share">
          <p>{english?"CONTACT":"LIÊN LẠC"}</p>
          <div className="contact-info">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.2005 18.1762L15.6105 15.7762C15.427 15.6975 15.2242 15.6756 15.0282 15.7132C14.8322 15.7508 14.6519 15.8462 14.5105 15.9872L12.1765 18.2872C10.9577 17.5542 9.8252 16.6864 8.8005 15.7002C7.8155 14.6763 6.94749 13.546 6.2125 12.3302L8.5125 10.0002C8.65294 9.85848 8.74779 9.67802 8.78485 9.482C8.82192 9.28598 8.79951 9.08335 8.7205 8.90016L6.3205 3.31017C6.22612 3.09061 6.05624 2.91211 5.84161 2.807C5.62699 2.70189 5.38181 2.67711 5.1505 2.73717L0.750501 3.90017C0.53048 3.95685 0.336324 4.08678 0.2 4.26854C0.063677 4.4503 -0.00668491 4.67307 0.000500499 4.90017C0.280578 10.0139 2.40726 14.8522 5.9855 18.5162C9.65052 22.0958 14.491 24.2223 19.6065 24.5002C19.8335 24.5074 20.0561 24.4372 20.2379 24.3011C20.4196 24.1649 20.5496 23.971 20.6065 23.7512L21.7685 19.3512C21.8302 19.1198 21.8068 18.8739 21.7026 18.6582C21.5984 18.4426 21.4202 18.2715 21.2005 18.1762Z"
                fill="url(#paint0_linear_147_467)"
              />
              <path
                d="M18 10.5C18 10.7652 18.1054 11.0196 18.2929 11.2071C18.4804 11.3946 18.7348 11.5 19 11.5C19.2652 11.5 19.5196 11.3946 19.7071 11.2071C19.8946 11.0196 20 10.7652 20 10.5C19.9984 8.90919 19.3658 7.38399 18.2409 6.25911C17.116 5.13424 15.5908 4.50159 14 4.5C13.7348 4.5 13.4804 4.60536 13.2929 4.79289C13.1054 4.98043 13 5.23478 13 5.5C13 5.76522 13.1054 6.01957 13.2929 6.20711C13.4804 6.39464 13.7348 6.5 14 6.5C15.0609 6.5 16.0783 6.92143 16.8284 7.67157C17.5786 8.42172 18 9.43913 18 10.5Z"
                fill="url(#paint1_linear_147_467)"
              />
              <path
                d="M14 0.5C13.7348 0.5 13.4804 0.605357 13.2929 0.792893C13.1054 0.98043 13 1.23478 13 1.5C13 1.76522 13.1054 2.01957 13.2929 2.20711C13.4804 2.39464 13.7348 2.5 14 2.5C16.121 2.50238 18.1544 3.346 19.6542 4.84578C21.154 6.34555 21.9976 8.379 22 10.5C22 10.7652 22.1054 11.0196 22.2929 11.2071C22.4804 11.3946 22.7348 11.5 23 11.5C23.2652 11.5 23.5196 11.3946 23.7071 11.2071C23.8946 11.0196 24 10.7652 24 10.5C23.9971 7.84873 22.9426 5.30688 21.0679 3.43215C19.1931 1.55742 16.6513 0.502912 14 0.5V0.5Z"
                fill="url(#paint2_linear_147_467)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_147_467"
                  x1="0.673889"
                  y1="8.02723"
                  x2="14.2954"
                  y2="8.14448"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2E92EE" />
                  <stop offset="1" stop-color="#BE6BFF" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_147_467"
                  x1="13.2164"
                  y1="6.20929"
                  x2="17.5898"
                  y2="6.24693"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2E92EE" />
                  <stop offset="1" stop-color="#BE6BFF" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_147_467"
                  x1="13.34"
                  y1="3.18603"
                  x2="20.2125"
                  y2="3.24517"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2E92EE" />
                  <stop offset="1" stop-color="#BE6BFF" />
                </linearGradient>
              </defs>
            </svg>
            <p>0915 444 235</p>
            
          </div>

          <div className="contact-info">
            <svg
              width="22"
              height="19"
              viewBox="0 0 22 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.0992 0.499908H2.89922C2.18313 0.499908 1.49638 0.784372 0.99003 1.29072C0.483682 1.79707 0.199219 2.48382 0.199219 3.19991L0.199219 15.7999C0.199219 16.516 0.483682 17.2027 0.99003 17.7091C1.49638 18.2154 2.18313 18.4999 2.89922 18.4999H19.0992C19.8153 18.4999 20.5021 18.2154 21.0084 17.7091C21.5148 17.2027 21.7992 16.516 21.7992 15.7999V3.19991C21.7992 2.48382 21.5148 1.79707 21.0084 1.29072C20.5021 0.784372 19.8153 0.499908 19.0992 0.499908ZM18.8292 4.81991L11.6292 11.1199C11.4332 11.221 11.2191 11.2821 10.9992 11.2999C10.7794 11.2821 10.5653 11.221 10.3692 11.1199L3.16922 4.81991C2.9902 4.66476 2.88014 4.44484 2.86326 4.20855C2.84639 3.97225 2.92407 3.73893 3.07922 3.55991C3.23437 3.38089 3.45428 3.27083 3.69058 3.25395C3.92688 3.23708 4.1602 3.31476 4.33922 3.46991L10.9092 9.22991L17.4792 3.46991C17.5667 3.39074 17.6694 3.33009 17.7809 3.29162C17.8925 3.25315 18.0107 3.23765 18.1284 3.24606C18.2461 3.25447 18.3609 3.28661 18.4659 3.34055C18.5709 3.39448 18.6638 3.4691 18.7392 3.55991C18.8389 3.62888 18.9219 3.71936 18.9819 3.82466C19.042 3.92996 19.0777 4.0474 19.0863 4.16833C19.095 4.28925 19.0763 4.41058 19.0318 4.52335C18.9873 4.63612 18.9181 4.73746 18.8292 4.81991Z"
                fill="url(#paint0_linear_158_1290)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_158_1290"
                  x1="0.866855"
                  y1="4.89527"
                  x2="20.0913"
                  y2="4.92172"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2EEE86" />
                  <stop offset="1" stop-color="#26BEEE" />
                </linearGradient>
              </defs>
            </svg>
            <p>thang@goku.agency</p>
           
          </div>

          <div className="social">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2C6.477 2 2 6.477 2 12C2 16.991 5.657 21.128 10.438 21.879V14.89H7.898V12H10.438V9.797C10.438 7.291 11.93 5.907 14.215 5.907C15.309 5.907 16.453 6.102 16.453 6.102V8.562H15.193C13.95 8.562 13.563 9.333 13.563 10.124V12H16.336L15.893 14.89H13.563V21.879C18.343 21.129 22 16.99 22 12C22 6.477 17.523 2 12 2Z"
                fill="white"
              />
            </svg>

            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2C14.717 2 15.056 2.01 16.122 2.06C17.187 2.11 17.912 2.277 18.55 2.525C19.21 2.779 19.766 3.123 20.322 3.678C20.8305 4.1779 21.224 4.78259 21.475 5.45C21.722 6.087 21.89 6.813 21.94 7.878C21.987 8.944 22 9.283 22 12C22 14.717 21.99 15.056 21.94 16.122C21.89 17.187 21.722 17.912 21.475 18.55C21.2247 19.2178 20.8311 19.8226 20.322 20.322C19.822 20.8303 19.2173 21.2238 18.55 21.475C17.913 21.722 17.187 21.89 16.122 21.94C15.056 21.987 14.717 22 12 22C9.283 22 8.944 21.99 7.878 21.94C6.813 21.89 6.088 21.722 5.45 21.475C4.78233 21.2245 4.17753 20.8309 3.678 20.322C3.16941 19.8222 2.77593 19.2175 2.525 18.55C2.277 17.913 2.11 17.187 2.06 16.122C2.013 15.056 2 14.717 2 12C2 9.283 2.01 8.944 2.06 7.878C2.11 6.812 2.277 6.088 2.525 5.45C2.77524 4.78218 3.1688 4.17732 3.678 3.678C4.17767 3.16923 4.78243 2.77573 5.45 2.525C6.088 2.277 6.812 2.11 7.878 2.06C8.944 2.013 9.283 2 12 2ZM12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7ZM18.5 6.75C18.5 6.41848 18.3683 6.10054 18.1339 5.86612C17.8995 5.6317 17.5815 5.5 17.25 5.5C16.9185 5.5 16.6005 5.6317 16.3661 5.86612C16.1317 6.10054 16 6.41848 16 6.75C16 7.08152 16.1317 7.39946 16.3661 7.63388C16.6005 7.8683 16.9185 8 17.25 8C17.5815 8 17.8995 7.8683 18.1339 7.63388C18.3683 7.39946 18.5 7.08152 18.5 6.75ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9Z"
                fill="white"
              />
            </svg>

            <svg
              width="24"
              height="16"
              viewBox="0 0 24 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.9991 5.70998C23.0485 4.27864 22.7355 2.858 22.0891 1.57998C21.6505 1.05558 21.0418 0.701694 20.3691 0.579984C17.5866 0.327509 14.7926 0.224028 11.9991 0.269984C9.2157 0.221942 6.43176 0.322084 3.65905 0.569984C3.11087 0.669701 2.60357 0.926826 2.19905 1.30998C1.29905 2.13998 1.19905 3.55998 1.09905 4.75998C0.953962 6.91755 0.953962 9.08242 1.09905 11.24C1.12798 11.9154 1.22854 12.5858 1.39905 13.24C1.51963 13.745 1.76357 14.2123 2.10905 14.6C2.51632 15.0034 3.03543 15.2752 3.59905 15.38C5.75497 15.6461 7.92727 15.7564 10.0991 15.71C13.5991 15.76 16.669 15.71 20.299 15.43C20.8765 15.3316 21.4102 15.0595 21.8291 14.65C22.109 14.3699 22.3181 14.0271 22.4391 13.65C22.7967 12.5526 22.9723 11.4041 22.959 10.25C22.9991 9.68998 22.9991 6.30998 22.9991 5.70998ZM9.73905 10.85V4.65998L15.659 7.76998C13.9991 8.68998 11.809 9.72998 9.73905 10.85Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="share input">

          
<p> {english?"OR LEAVE YOUR INFOMATION":"ĐỂ LẠI THÔNG TIN CỦA BẠN"}</p>
{contact.isError && (
<p className="error">{contact.textError}</p>
)}
{contact.success && (
<p>{contact.textSuccess}</p>
)}

<input required onChange={(e)=>{
    updateInfo(e.target.name,e.target.value)
  }} value={contact.message}  className="message" type="text" name="message" placeholder="Your message" />

{!matches ? (
<div className="info-customer">
<input required onChange={(e)=>{
updateInfo(e.target.name,e.target.value)
}} value={contact.name} type="text" placeholder="Name" name="name" />
<input required onChange={(e)=>{
updateInfo(e.target.name,e.target.value)
}} value={contact.value} type="text" placeholder="Email" name="email" />

<button type="submit">
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.1829 19.1666C12.0207 19.1665 11.8617 19.1214 11.7236 19.0362C11.5856 18.951 11.474 18.8291 11.4012 18.6841L8.81038 13.4999C8.74051 13.3598 8.70945 13.2035 8.72044 13.0472C8.73143 12.891 8.78407 12.7406 8.87289 12.6116L13.0562 6.94494L7.38955 11.1266C7.26072 11.2154 7.11043 11.2681 6.95434 11.2791C6.79824 11.2901 6.64206 11.259 6.50205 11.1891L1.31622 8.59828C1.16448 8.5218 1.03835 8.40273 0.953282 8.25565C0.86821 8.10856 0.827892 7.93985 0.837268 7.7702C0.846645 7.60054 0.905308 7.4373 1.00607 7.30049C1.10683 7.16368 1.24532 7.05923 1.40455 6.99994L17.9921 0.886608C18.1485 0.828789 18.3183 0.817036 18.4812 0.852742C18.6442 0.888448 18.7935 0.970116 18.9114 1.08807C19.0294 1.20602 19.111 1.35532 19.1468 1.51826C19.1825 1.68121 19.1707 1.85097 19.1129 2.00744L13.0004 18.5958C12.9415 18.7556 12.8372 18.8946 12.7002 18.9958C12.5632 19.0969 12.3996 19.1557 12.2296 19.1649L12.1829 19.1666Z" fill="white"/>
</svg>
<span>{english ? "Send":"Gửi"}</span>



</button>
</div>
):(
  <div className="info-customer-mobile">
  <div className="box-input">
  <input required onChange={(e)=>{
    updateInfo(e.target.name,e.target.value)
  }} value={contact.name} type="text" placeholder="Name" name="name" />
  <input required onChange={(e)=>{
    updateInfo(e.target.name,e.target.value)
  }} value={contact.value} type="text" placeholder="Email" name="email" />
  </div>
  

  <button type="submit">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.1829 19.1666C12.0207 19.1665 11.8617 19.1214 11.7236 19.0362C11.5856 18.951 11.474 18.8291 11.4012 18.6841L8.81038 13.4999C8.74051 13.3598 8.70945 13.2035 8.72044 13.0472C8.73143 12.891 8.78407 12.7406 8.87289 12.6116L13.0562 6.94494L7.38955 11.1266C7.26072 11.2154 7.11043 11.2681 6.95434 11.2791C6.79824 11.2901 6.64206 11.259 6.50205 11.1891L1.31622 8.59828C1.16448 8.5218 1.03835 8.40273 0.953282 8.25565C0.86821 8.10856 0.827892 7.93985 0.837268 7.7702C0.846645 7.60054 0.905308 7.4373 1.00607 7.30049C1.10683 7.16368 1.24532 7.05923 1.40455 6.99994L17.9921 0.886608C18.1485 0.828789 18.3183 0.817036 18.4812 0.852742C18.6442 0.888448 18.7935 0.970116 18.9114 1.08807C19.0294 1.20602 19.111 1.35532 19.1468 1.51826C19.1825 1.68121 19.1707 1.85097 19.1129 2.00744L13.0004 18.5958C12.9415 18.7556 12.8372 18.8946 12.7002 18.9958C12.5632 19.0969 12.3996 19.1557 12.2296 19.1649L12.1829 19.1666Z" fill="white"/>
</svg>
    <span>{english ? "Send":"Gửi"}</span>
   
    
    
  </button>
</div>
)}



</form>
      </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
padding: 20px 280px;    

.container{
  background-color: #161616;
  padding: 60px 120px;
  border-radius: 20px;
  border: 0.7px solid #C6C6C6;
/* text-align:  center; */

h1{
  text-align: left;
  color: white;
  font-family: SF-Pro-Display-Bold;
  font-size: 42px;
  span{

    background-color: rgba(226, 117, 38, 1);
      background-image: linear-gradient(
        90deg,
      
        
        rgba(226, 117, 38, 1) 0%,
        rgba(148, 206, 25, 1) 34.38%,
        rgba(25, 119, 206, 1) 66.67%,
        rgba(190, 107, 255, 1) 100%
      );
      /* background: -webkit-linear-gradient(to right, #FDFBFB, #EBEDEE 70%); */

      background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;

  }
}
  

}
.content{
    display: grid;
    margin-top: 60px;
    grid-template-columns: 50% 50%;

    .share{
      &>p{
        color: #C6C6C6;
        font-size: 18px;
      }
      

    }
    .contact{
      div{
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        p{
          color: white;
          margin-left: 10px;
          margin-bottom: 0;
        }
      }
      .social{
        svg{
          margin-left: 14px;
        }
      }
    }
    .input{
      input{
        background-color: #131313;
        outline: none;
        color: #C6C6C6;
        /* padding-left: 16px; */

      }
      input:focus{
        color: white;
        outline: 1px solid #C6C6C6;
      }
      .message{
        width: 100%;
        padding: 16px 16px 30px;
  border: 0.7px solid #C6C6C6;
  border-radius: 8px;

        
      }
      .error{
        color: red;
      }

      .info-customer{
        display: flex;
        margin-top: 8px;
        gap: 4px;
        
        input{
          padding: 6px 16px;
          border: 0.7px solid #C6C6C6;
          border-radius: 6px;
          width: 40%;
        }
        button{
          flex: 1;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 4px;
          border-radius: 8px;
          border: none;
          font-family: SF-Pro-Display-Bold;
          span{
            margin-left: 6px;
            background-color: #2EEE86;
            background: linear-gradient(90.07deg, #2EEE86 3.12%, #26BEEE 92.02%);
      /* background: -webkit-linear-gradient(to right, #FDFBFB, #EBEDEE 70%); */

      background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
          }
        }
        button:hover{
          opacity: 0.9;
        }
      }

    }
    
}
.info-customer-mobile{
  display: none;
}
@media screen and (max-width: 1524px) {
  padding: 30px 150px;
  
}
@media screen and (max-width: 1396px) {
  padding: 30px 120px;
  
}
@media screen and (max-width: 1024px) {
  padding: 30px 50px;
.container{
  padding: 40px 40px;
  h1{
    font-size: 32px;
    line-height: 36px;
    svg{
      width: 30px;
      height: 30px;
    }
  }
}  
.content{
  .share{
    .message{
          margin-bottom: 12px;
        }
    .info-customer{
      flex-wrap: wrap;
      display: none;
      input{
        /* flex-basis: 50%; */
      }
    }
    .info-customer-mobile{
      width: 100%;
      display: block;
      .box-input{
        display: flex;
        justify-content: space-between;
       
        input{
          padding: 6px 24px 6px;
          border: 0.7px solid #C6C6C6;
          border-radius: 6px;
          width: 49%;
          margin-bottom: 16px;
        }
       
      
      }
      button{
          /* flex: 1; */
          cursor: pointer;
          display: flex;
          width:22%;
          margin-left: auto;
          align-items: center;
          justify-content: space-evenly;
          padding: 8px 4px;
          border-radius: 8px;
          border: none;
          font-family: SF-Pro-Display-Medium;
          background: linear-gradient(90.07deg, #2EEE86 3.12%, #26BEEE 92.02%);
          span{
            margin-left: 6px;
            color: white;
            /* background-color: #2EEE86;
            background: linear-gradient(90.07deg, #2EEE86 3.12%, #26BEEE 92.02%);
     

      background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent; */
          }
        }
    }
  }
}
}
@media screen and (max-width: 796px) {
  
  
}
@media screen and (max-width: 650px) {
  padding: 30px 15px;

  .container{
  border-radius: 12px;

    h1{
      font-size: 24px;
      line-height: 28px;
      svg{
        width: 24px;
        height: 24px;
      }
    }
  }
  .content{
    grid-template-columns: 1fr;
    margin-top: 30px;

    .input{
      margin-top: 30px;
      .message{
        margin-bottom: 8px;
      }
      .info-customer-mobile{
        .box-input{
          flex-direction: column;
          input{
            width: 100%;
            padding: 8px 24px;
            margin-bottom: 8px;
            
          }
        }
        button{
          width: 28%;
        }
      }
    }

  }
  
}
@media screen and (max-width: 424px){
  .container{
    padding: 30px 20px;
  border-radius: 12px;

  }
}
@media screen and (max-width: 324px) {
  padding: 30px 0;
  .container{
    padding: 30px 20px;
  border-radius: 12px;

  }
  
}
`

export default Contact;
